import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import {
    ALWAYS,
    DAILY,
    WEEKLY,
    BIMONTHLY,
    MONTHLY,
    NEVER
} from '../../Helpers/Enums'
import {connect} from 'react-redux'
import {
    notificationFrequencyOptionChanged
} from '../../Actions/Account'

class NotificationSettings extends Component {
    onChangeNotificationFrequency = (e) => {
        this.props.notificationFrequencyOptionChanged(Number(e.target.value));
    }
    render() {
        return (
            <Grid container
                className='py-2 px-1'
                direction='row'
                wrap='wrap'
                justify='center'
                alignItems='center'
                alignContent='center'>
                <Grid item xs={12}>
                    <Typography variant='h6' gutterBottom>Notification Settings</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align='left' variant='body1'>
                        Select the frequency at which you would like to receive email notifications. Below are further descriptions for each option.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth component='fieldset'>
                        <RadioGroup
                            aria-label='notification-settings'
                            name='notification-settings'
                            value={this.props.notificationSetting}
                            onChange={this.onChangeNotificationFrequency.bind(this)}
                        >
                            <FormControlLabel value={ALWAYS.value} control={<Radio color='primary' />} label={ALWAYS.name} />
                            <Typography style={{marginLeft: 10}} align='left' variant='caption' component='span'>{ALWAYS.helperText}</Typography>
                            {/* <FormControlLabel value={DAILY.value} control={<Radio color='primary' />} label={DAILY.name} />
                            <Typography style={{marginLeft: 10}} align='left' variant='caption' component='span'>{DAILY.helperText}</Typography>
                            <FormControlLabel value={WEEKLY.value} control={<Radio color='primary' />} label={WEEKLY.name} />
                            <Typography style={{marginLeft: 10}} align='left' variant='caption' component='span'>{WEEKLY.helperText}</Typography>
                            <FormControlLabel value={BIMONTHLY.value} control={<Radio color='primary' />} label={BIMONTHLY.name} />
                            <Typography style={{marginLeft: 10}} align='left' variant='caption' component='span'>{BIMONTHLY.helperText}</Typography>
                            <FormControlLabel value={MONTHLY.value} control={<Radio color='primary' />} label={MONTHLY.name} />
                            <Typography style={{marginLeft: 10}} align='left' variant='caption' component='span'>{MONTHLY.helperText}</Typography> */}
                            <FormControlLabel value={NEVER.value} control={<Radio color='primary' />} label={NEVER.name} />
                            <Typography style={{marginLeft: 10}} align='left' variant='caption' component='span'>{NEVER.helperText}</Typography>
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
    notificationSetting: state.account.notificationSetting
})
export default connect(mapStateToProps, {
    notificationFrequencyOptionChanged
})(NotificationSettings);