import React, {Component} from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    toggleSelfRequestCheckbox
} from '../../Actions/Request';

class RequestSelfCheckbox extends Component{
    onCheckSelfRequestBox = (e) => {
        if(this.props.selfRequestChecked){
            this.props.toggleSelfRequestCheckbox({
                checked: false,
                employee: '',
                manager: this.props.account.manager
            });
        }else{
            let employeeData = {
                ...this.props.account
            };
            let managerData = this.props.account.manager;
            delete employeeData.manager;
            this.props.toggleSelfRequestCheckbox({
                checked: true,
                employee: employeeData,
                manager: managerData
            });
        }
    }
    render(){
        return(
        <Grid item xs={12}>
            {
                this.props.fetchingAccount ?
                <CircularProgress size={20} />
                : 
                null
            }
            <FormControlLabel
                control={
                    <Checkbox disabled={this.props.fetchingAccount} 
                        checked={this.props.selfRequestChecked} 
                        value={this.props.selfRequestChecked} 
                        color='primary' 
                        onChange={this.onCheckSelfRequestBox.bind(this)} 
                    />
                }
                label='This is a request for myself.'
            />
        </Grid>
        );
    }
}
const mapStateToProps = state => ({
    account: state.account.account,
    fetchingAccount: state.account.fetchingAccount,
    selfRequestChecked: state.request.selfRequestChecked
});
export default connect(mapStateToProps, {
    toggleSelfRequestCheckbox
})(RequestSelfCheckbox);