import React, {Component} from 'react';
import {connect} from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import {getAdminAppRoleName} from '../../Helpers/helperFunctions';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    editUserRole,
    toggleDeleteDialog
} from '../../Actions/Admin';
import UserRoleDeleteDialog from './UserRoleDeleteDialog';

class RoleAssignmentsTable extends Component{
    handleClickUserRoleEdit = (id, e) => {
        const user = this.props.appUsers.find(au => au.id === id);
        this.props.editUserRole(user)
    }
    handleClickUserDelete = (id, e) => {
        const user = this.props.appUsers.find(au => au.id === id);
        this.props.toggleDeleteDialog({
            id: id,
            appUser: user,
            displayDeleteDialog: true
        });
    }
    render(){
        const cols = [
            {
                field: "principalId",
                headerName: "User ID",
                width: 350
            },
            {
                field: "principalDisplayName",
                headerName: "Name",
                width: 300
            },
            {
                field: 'appRoleId',
                headerName: 'App Role',
                width: 300,
                renderCell: (params) => (
                    <React.Fragment>
                        {getAdminAppRoleName(this.props.appRoles, params.value)}
                    </React.Fragment>
                )
            },
            {
                field: 'createdDateTime',
                headerName: 'Date Assigned',
                width: 250,
                renderCell: (params) => (
                    <React.Fragment>
                        {/* {params.value} */}
                        {new Date(params.value).toLocaleDateString()}
                    </React.Fragment>
                )
            },
            {
                field: 'id',
                headerName: 'Actions',
                width: 250,
                renderCell: (params) =>(
                    <React.Fragment>
                        <IconButton color='primary' onClick={this.handleClickUserRoleEdit.bind(this, params.value)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color='secondary' onClick={this.handleClickUserDelete.bind(this, params.value)}>
                            <DeleteIcon />
                        </IconButton>
                    </React.Fragment>
                ) 
            }
        ]
        return(
            <React.Fragment>
                <UserRoleDeleteDialog />
                <DataGrid 
                    columns={cols}
                    rows={this.props.appUsers}
                    disableSelectionOnClick={true}
                    pageSize={10}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    appUsers: state.admin.appUsers,
    appRoles: state.admin.appRoles,
    displayDeleteDialog: state.admin.displayDeleteDialog
})

const mapDispatchToProps = {
    editUserRole,
    toggleDeleteDialog
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleAssignmentsTable)