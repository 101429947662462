import React, {Component} from 'react';
import {connect} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {
    appUserChanged,
    appUserInputChanged
} from '../../../Actions/Admin';
class UserAutocomplete extends Component {
    handleOnChanged = (e, userOption) => {
        this.props.appUserChanged(userOption);   
    }
    handleOnInputChanged = (e, inputValue) => {
        this.props.appUserInputChanged(inputValue);
    }
    render(){
        return(
            <Autocomplete 
                id='user-autocomplete-input'
                fullWidth
                options={this.props.users}
                value={this.props.graphUser === '' ? null : this.props.graphUser}
                getOptionLabel={(option) => `${option.displayName} (${option.userPrincipalName})`}
                inputValue={this.props.graphUserInput}
                onChange={this.handleOnChanged}
                onInputChange={this.handleOnInputChanged}
                renderInput={(params) => <TextField {...params} label="ISD Employees" variant="outlined" />}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    users: state.account.users,
    graphUser: state.admin.graphUser,
    graphUserInput: state.admin.graphUserInput
})

const mapDispatchToProps = {
    appUserChanged,
    appUserInputChanged
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAutocomplete)