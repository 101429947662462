import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {withRouter} from 'react-router-dom'
import {authContext} from '../Helpers/adalAuthentication';
class Authorize extends Component {
    componentDidMount(){
        if(authContext.isCallback(window.location.hash)){
            authContext.saveTokenFromHash(authContext.getRequestInfo(window.location.hash));
            authContext.handleWindowCallback();
            setTimeout(()=>{
                const postRedirectUrl = localStorage.getItem('adal.login.request');
                const url = new URL(postRedirectUrl);
                this.props.history.push(url.pathname);
            }, 3500);
        }else{
            this.props.history.push('/login');
        }
    }
    render() {
        return (
            <Grid item xs={12} sm={11} md={10} lg={8}>
                <Paper className='py-2 px-1' square>
                    <Typography variant='h5' color='textPrimary'>
                        Your login session is now refreshed. You will automatically be redirected back to your last page shortly.
                    </Typography>
                </Paper>
            </Grid>
        )
    }
}
export default withRouter(Authorize);