import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import {
    TABLE_COL_EMPLOYEE,
    TABLE_COL_REQUESTOR,
    TABLE_COL_FULFILLER,
    TABLE_COL_REQUEST_TYPE,
    TABLE_COL_SUBMITTED,
    TABLE_COL_LAST_UPDATED,
    TABLE_COL_SECTION_MANAGER,
    TABLE_COL_DIVISION_MANAGER,
    TABLE_COL_COMPLETED_DATE
} from '../Helpers/Enums';
import {requestTableColumnsChanged} from '../Actions/Request';

const styles = {
    list: {
        height: 210,
        overflowY: 'scroll',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 16,
        paddingRight: 16
    },
    buttonContainer: {
        textAlign: 'right',
        maxHeight: 40
    }
}

class RequestColSelection extends Component {
    onChangeTableColumnSelection = (val, e) => {
        const colOrder = [
            TABLE_COL_EMPLOYEE,
            TABLE_COL_REQUESTOR,
            TABLE_COL_SECTION_MANAGER,
            TABLE_COL_DIVISION_MANAGER,
            TABLE_COL_FULFILLER,
            TABLE_COL_REQUEST_TYPE,
            TABLE_COL_SUBMITTED,
            TABLE_COL_COMPLETED_DATE,
            TABLE_COL_LAST_UPDATED
        ];
        const unorderedCols = this.props.displayRows;
        const colIndex = unorderedCols.indexOf(val);
        const orderedCols = [];
        if(colIndex >= 0){
            unorderedCols.splice(colIndex, 1);
        }else{
            unorderedCols.push(val);
        }
        colOrder.forEach((col, index)=>{
            if(unorderedCols.indexOf(col) >= 0){
                orderedCols.push(col);
            }
        });
        this.props.requestTableColumnsChanged(orderedCols);
    }
    render(){
        const {classes} = this.props;
        const colOptions = [
            TABLE_COL_EMPLOYEE,
            TABLE_COL_REQUESTOR,
            TABLE_COL_SECTION_MANAGER,
            TABLE_COL_DIVISION_MANAGER,
            TABLE_COL_FULFILLER,
            TABLE_COL_REQUEST_TYPE,
            TABLE_COL_SUBMITTED,
            TABLE_COL_COMPLETED_DATE,
            TABLE_COL_LAST_UPDATED
        ];
        return(
            <Grid container direction='row' wrap='wrap'>
                <Grid item xs={12}>
                    <Grid container direction='row' wrap='wrap' className={classes.list}>
                        {
                            colOptions.map((colOption, index) => (
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        key={colOption.value}
                                        control={
                                            <Checkbox 
                                                color='primary'
                                                onChange={this.onChangeTableColumnSelection.bind(this, colOption)}
                                                checked={this.props.displayRows.indexOf(colOption) >= 0}
                                                value={colOption}
                                            />
                                        }
                                        label={colOption.name}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button variant='text' color='secondary' onClick={this.props.handleClose}>
                        Close
                    </Button>
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    displayRows: state.request.displayRows
});
const mapDispatchToProps = {
    requestTableColumnsChanged
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RequestColSelection));