import React, { Component } from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import MomentUtils from'@date-io/moment';
import {
    orderDateChanged
} from '../../Actions/FulfillRecord';

class OrderDate extends Component {
    onChangeOrderDate = (e) => {
        this.props.orderDateChanged(e);
    }
    render() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={10} lg={10}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker 
                            disabled={this.props.account.id !== this.props.request.Fulfiller.id}
                            format='MMMM DD, YYYY' 
                            value={this.props.orderDate} 
                            clearable 
                            variant='dialog'
                            fullWidth 
                            inputVariant='filled'
                            label='Order Date'
                            onChange={this.onChangeOrderDate.bind(this)} />
                    </MuiPickersUtilsProvider>   
                </Grid>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    orderDate: state.fulfillRecord.orderDate,
    request: state.request.request,
    account: state.account.account
});
export default connect(mapStateToProps,{
    orderDateChanged
})(OrderDate);