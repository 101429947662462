import React, {Component} from 'react';
import {connect} from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
    attachmentFileChanged
} from '../../Actions/RequestAttachment'
import { isUndefinedOrNull } from '../../Helpers/helperFunctions';

class Attachment extends Component{
    handleFileChanged = (e) => {
        this.props.attachmentFileChanged(e.target.files[0]);
    } 
    render(){
        return(
            <Grid item xs={11}>
                <Grid container direction='row'
                    justify='center'
                    alignContent='center'
                    alignItems='center'
                >
                    <Grid item xs={12}>
                        <Button
                            variant='outlined'
                            component='label'
                            color='default'
                        >
                            Upload File
                            <input type='file' onChange={this.handleFileChanged.bind(this)} hidden />
                        </Button>
                        &nbsp;
                        {
                            !isUndefinedOrNull(this.props.file) && this.props.file !== '' ?
                            <Typography variant='body1' component='span'>
                                {this.props.file.name}
                            </Typography>
                            :
                            null
                        }
                    </Grid>
                    {
                        this.props.error ?
                        <Grid item xs={12}>
                            <Typography color='secondary'>
                                Invalid file type. Attachment must be a pdf or an image.
                            </Typography>
                        </Grid>
                        :
                        null
                    }
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    file: state.requestAttachment.file,
    error: state.requestAttachment.errorType
})
export default connect(mapStateToProps, {
    attachmentFileChanged
})(Attachment);