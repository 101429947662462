import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    submitRequestAttempt
} from '../../Actions/Request';
import {
    getSasUri,
    uploadAttachment
} from '../../Actions/RequestAttachment';
import { isUndefinedOrNull } from '../../Helpers/helperFunctions';

class UpdateButtonControls extends Component {
    onSubmitRequestForm = (e) => {
        let data = {
            manager: this.props.manager,
            fulfiller: this.props.fulfiller,
            description: this.props.description,
            justification: this.props.justification,
            requestType: this.props.requestType,
            employee: this.props.employee
        };
        if(!isUndefinedOrNull(this.props.file) && this.props.file !== ''){
            this.props.uploadAttachment({
                file: this.props.file,
                request: data
            });
        }else{
            this.props.submitRequestAttempt(data);
        }
    }
    render(){
        return(
            <Grid item xs={12}>
                <Button variant='contained' color='primary' onClick={this.onSubmitRequestForm.bind(this)} disabled={this.props.submittingRequest || this.props.uploading || this.props.fetchingRequestEmployeeManager}>
                { 
                    this.props.submittingRequest || this.props.uploading ?
                    <CircularProgress color='primary' size={36} thickness={7}/>:
                    <React.Fragment>SUBMIT &nbsp; <Icon>send</Icon></React.Fragment>
                }
                </Button>
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    submittingRequest: state.request.submittingRequest,
    manager: state.request.manager,
    employee: state.request.employee,
    fulfiller: state.request.fulfiller,
    description: state.request.description,
    justification: state.request.justification,
    requestType: state.request.requestType,
    file: state.requestAttachment.file,
    filename: state.requestAttachment.filename,
    uploading: state.requestAttachment.uploading,
    fetchingRequestEmployeeManager: state.request.fetchingEmployeeManager
});
export default connect(mapStateToProps, {
    submitRequestAttempt,
    getSasUri,
    uploadAttachment
})(UpdateButtonControls);