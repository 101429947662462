import React, { Component } from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
    justificationInputChanged
} from '../../Actions/Request';
import {
    isUndefinedOrNullOrEmpty
} from '../../Helpers/helperFunctions';
import Tooltip from '@material-ui/core/Tooltip';

class Justification extends Component {
    handleJustificationChange = (e) => {
        this.props.justificationInputChanged(e.target.value);
    }
    render() {
        return (
            <Grid item xs={12}>
                <Tooltip
                    placement='top'
                    title='A business justification for the item being requested.'
                >
                    <TextField 
                        label='Justification'
                        margin='normal'
                        fullWidth
                        multiline={true}
                        variant='filled'
                        rows={5}
                        value={this.props.justification}
                        onChange={this.handleJustificationChange.bind(this)}
                        error={isUndefinedOrNullOrEmpty(this.props.errors.justification) ? false : true}
                        helperText={this.props.errors.justification}
                        disabled={this.props.submittingRequest}
                    />
                </Tooltip>
            </Grid>
        )
    }
}
const mapStateToProps = state => ({
    justification: state.request.justification,
    errors: state.request.errors,
    submittingRequest: state.request.submittingRequest
});
export default connect(mapStateToProps,{
    justificationInputChanged
})(Justification);