import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import UserGuide from '../Documents/MyRequest System User Guide.pdf';
import Hidden from '@material-ui/core/Hidden';
import {
    logout
} from '../Actions/Account';
import withStyles from '@material-ui/styles/withStyles'

const styles = t => ({
    appBar: {
        [t.breakpoints.up('md')]: {
            zIndex: t.zIndex.drawer + 1
        }
    }
});
class TopBar extends Component {
    onClickLogout(e){
        this.props.logout(this.props.history);
    }
    render(){
        const theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: blue,
                secondary: red
            }
        });
        const {classes} = this.props;
        return(
            <ThemeProvider theme={theme}>
                <AppBar color='default' position='fixed' className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" align='left' style={{flexGrow: 1}}>
                            MyRequest System
                        </Typography>
                        <Hidden mdDown>
                            <Tooltip title='Download User Guide' aria-label='Download Guide'>
                                <Button 
                                    color='default'
                                    href={UserGuide}
                                    download
                                    variant='contained'
                                >
                                    <GetAppIcon /> User Guide
                                </Button>
                            </Tooltip>
                        </Hidden>
                        <Hidden mdUp>
                            <Tooltip title='Download User Guide' aria-label='Download Guide'>
                                <IconButton 
                                    color='default'
                                    href={UserGuide}
                                    download
                                >
                                    <GetAppIcon />
                                </IconButton>
                            </Tooltip>
                        </Hidden>
                        &nbsp;
                        {
                            this.props.authenticated ? 
                            <React.Fragment>
                                <Tooltip title='Logout' aria-label='Logout'>
                                    <Button variant='contained' color='secondary' onClick={this.onClickLogout.bind(this)}>Logout</Button>
                                    {/* <IconButton onClick={this.onClickLogout.bind(this)}>
                                        <Icon>exit_to_app</Icon>
                                    </IconButton> */}
                                </Tooltip>
                            </React.Fragment>
                            :
                            null
                        }
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        );
    }
}
const mapStateToProps = state =>({
    authenticated: state.account.authenticated
});
export default withRouter(connect(mapStateToProps, {
    logout,
})(withStyles(styles)(TopBar)));