import {
    REQUEST_ATTACHMENT_GET_SAS_URI,
    REQUEST_ATTACHMENT_GET_SAS_URI_FAILURE,
    REQUEST_ATTACHMENT_GET_SAS_URI_SUCCESS,
    REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT,
    REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_SUCCESS,
    REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_FAILURE,
    REQUEST_ATTACHMENT_FILE_CHANGED,
    REQUEST_ATTACHMENT_DOWNLOAD,
    REQUEST_ATTACHMENT_DOWNLOAD_FAILURE,
    REQUEST_ATTACHMENT_DOWNLOAD_SUCCESS,
    REQUEST_ATTACHMENT_FILE_CHANGED_ERROR
} from './types';
import {
    axiosClient
} from '../Helpers/axiosClient';
import {
    BlobServiceClient
} from '@azure/storage-blob';
import { submitRequestAttempt } from './Request';

export const attachmentFileChanged = (data) => {
    if(data !== '' && data.type !== 'application/pdf' && !data.type.includes('image')){
        return{
            type: REQUEST_ATTACHMENT_FILE_CHANGED_ERROR,
            payload: false
        }
    }
    else{
        return{
            type: REQUEST_ATTACHMENT_FILE_CHANGED,
            payload: data
        }
    }
}
export const getSasUri = (data) => {
    return async(dispatch) => {
        dispatch({
            type: REQUEST_ATTACHMENT_GET_SAS_URI,
            payload: data
        });
        try{
            let res = await axiosClient.post('/RequestAttachment', {
                FileName: data.name
            });
            dispatch({
                type: REQUEST_ATTACHMENT_GET_SAS_URI_SUCCESS,
                payload: res.data
            });
        }catch(e){
            dispatch({
                type: REQUEST_ATTACHMENT_GET_SAS_URI_FAILURE,
                payload: e.response.data
            });
        }
    }
}

export const uploadAttachment = (data) => {
    return async(dispatch) => {
        dispatch({
            type: REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT,
            payload: true
        })
        try{
            let fileData = data.file;
            let extIndex = fileData.name.lastIndexOf('.');
            let originalFileName = fileData.name.substring(0, extIndex);
            let ext = fileData.name.substring(extIndex+1);
            let sas = await axiosClient.post('/api/RequestAttachment', {
                FileName: originalFileName,
                FileExt: ext
            });
            let sasData = sas.data;
            let serviceClient = new BlobServiceClient(sasData.ContainerUri);
            let containerClient = serviceClient.getContainerClient('request-attachments');
            let blockBlobClient = containerClient.getBlockBlobClient(sasData.FileName);
            let renamedFile = new File([fileData], sasData.FileName);
            let res = await blockBlobClient.uploadBrowserData(renamedFile, {
                blobHTTPHeaders: {
                    blobContentType: fileData.type
                }
            });
            dispatch({
                type: REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_SUCCESS,
                payload: {
                    ...res,
                    FileName: sasData.FileName
                }
            });
            dispatch(submitRequestAttempt({
                ...data.request,
                attachmentFileName: sasData.FileName
            }));
            dispatch({
                type: REQUEST_ATTACHMENT_FILE_CHANGED,
                payload: ''
            })
        }catch(e){
            dispatch({
                type: REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_FAILURE,
                payload: e.response.data
            })
        }
    }
}

export const downloadRequestAttachment = (data) => {
    return async(dispatch) => {
        dispatch({
            type: REQUEST_ATTACHMENT_DOWNLOAD,
            payload: true
        });
        try{
            let res = await axiosClient.get(`/api/RequestAttachment/${data.id}`);
            let sasData = res.data;
            window.open(sasData.ContainerUri, '_blank');
            dispatch({
                type: REQUEST_ATTACHMENT_DOWNLOAD_SUCCESS,
                payload: false
            });
        }catch(e){
            dispatch({
                type: REQUEST_ATTACHMENT_DOWNLOAD_FAILURE,
                payload: false
            })
        }
    }
}