import {
    LOGIN_ATTEMPT,
    LOGIN_SUCCESS,
    ACCOUNT_FETCH_ATTEMPT,
    ACCOUNT_FETCH_FAILURE,
    ACCOUNT_FETCH_SUCCESS,
    LOGOUT,
    FETCH_USERS_ATTEMPT,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    FETCH_EMPLOYEES_ATTEMPT,
    FETCH_EMPLOYEES_FAILURE,
    FETCH_EMPLOYEES_SUCCESS,
    FETCH_ACCOUNT_SETTINGS_ATTEMPT,
    FETCH_ACCOUNT_SETTINGS_SUCCESS,
    FETCH_ACCOUNT_SETTINGS_FAILURE,
    NOTIFICATION_FREQUENCY_OPTION_CHANGED,
    UPDATE_ACCOUNT_SETTINGS_ATTEMPT,
    UPDATE_ACCOUNT_SETTINGS_FAILURE,
    UPDATE_ACCOUNT_SETTINGS_SUCCESS,
    FETCH_FULFILLERS_ATTEMPT,
    FETCH_FULFILLERS_SUCCESS,
    FETCH_FULFILLERS_FAILURE,
} from './types';
import {
    isUndefinedOrNullOrEmpty
} from '../Helpers/helperFunctions';
import {authContext} from '../Helpers/adalAuthentication'
import {axiosClient, } from '../Helpers/axiosClient';
import {
    APP_DEVELOPER,
    APP_SECTION_MANAGER,
    APP_DIVISION_MANAGER,
    APP_FULFILLER
} from '../Helpers/Enums'
export const loginAttempt = (data) => {
    return async (dispatch)=>{
        dispatch({
            type: LOGIN_ATTEMPT,
            payload: true
        });
        // if(data.redirected && authContext.isCallback(window.location.hash)){
        //     authContext.handleWindowCallback();
        //     let port='';
        //     if(!isUndefinedOrNullOrEmpty(window.location.port)){
        //         port = `:${window.location.port}`;
        //     }
        //     authContext.config.redirectUri = `${window.location.protocol}//${window.location.hostname}${port}/login`;
        //     dispatch({
        //         type: LOGIN_SUCCESS,
        //         payload: true
        //     })
        // }
        if(data.adalComplete && authContext.getCachedUser()){
            dispatch({
                type: LOGIN_SUCCESS,
                payload: true
            });
        }
        else{
            authContext.login();
        }
    }
}
export const accountFetchAttempt = (data)=>{
    return async(dispatch) => {
        dispatch({
            type: ACCOUNT_FETCH_ATTEMPT,
            payload: true
        });
        try{
            let req = await axiosClient.get(`/api/Account`)
            const userRole = authContext.getCachedUser().profile.roles[0];
            const canViewManagerTab = userRole === APP_DIVISION_MANAGER || userRole === APP_SECTION_MANAGER || userRole === APP_DEVELOPER;
            const canViewFulfillerTab = userRole === APP_FULFILLER || userRole === APP_DEVELOPER;
            dispatch({
                type: ACCOUNT_FETCH_SUCCESS,
                payload: {
                    account: req.data.account,
                    canViewManagerTab,
                    canViewFulfillerTab,
                    roles: authContext.getCachedUser().profile.roles
                }
            });
        }catch(e){
            let failData = {
                account: '',
                accountMessage: 'Unable to retrieve account info. Service may be unavailable.'
            };
            dispatch({
                type: ACCOUNT_FETCH_FAILURE,
                payload: failData
            });
        }
    }
}
export const fetchAccountSettings = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: FETCH_ACCOUNT_SETTINGS_ATTEMPT,
            payload: data
        });
        try{
            let req = await axiosClient.get(`/api/AccountSettings`);
            dispatch({
                type: FETCH_ACCOUNT_SETTINGS_SUCCESS,
                payload: req.data.UserSettings
            })
        }catch(e){
            dispatch({
                type: FETCH_ACCOUNT_SETTINGS_FAILURE,
                payload: e.response
            })
        }

    }
}
export const logout = (data) => {
    let port='';
    if(!isUndefinedOrNullOrEmpty(window.location.port)){
        port = `:${window.location.port}`;
    }
    authContext.config.redirectUri = `${window.location.protocol}//${window.location.hostname}${port}/login`;
    authContext.clearCache();
    authContext.logOut();
    return{
        type: LOGOUT,
        payload: false
    }
}
export const getUsersAttempt = (data)=>{
    return async(dispatch)=>{
        dispatch({
            type: FETCH_USERS_ATTEMPT,
            payload: true
        });
        try{
            let req = await axiosClient.get(`/api/GraphUsers`);
            let users = req.data;
            let usersData = {
                users
            };
            dispatch({
                type: FETCH_USERS_SUCCESS,
                payload: usersData
            })
        }catch(e){
            console.error(e)
            dispatch({
                type: FETCH_USERS_FAILURE,
                payload: e.message
            })
        }
    }
}
export const getEmployeesAttempt = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: FETCH_EMPLOYEES_ATTEMPT,
            payload: true
        });
        try{
            let req = await axiosClient.get(`/api/Employees`);
            let employees = req.data.Employees;
            dispatch({
                type: FETCH_EMPLOYEES_SUCCESS,
                payload: employees
            });
        }catch(e){
            console.error(e);
            dispatch({
                type: FETCH_EMPLOYEES_FAILURE,
                payload: e.message
            });
        }
    }
}
export const notificationFrequencyOptionChanged = (data) => {
    return({
        type: NOTIFICATION_FREQUENCY_OPTION_CHANGED,
        payload: data
    })
}
export const updateAccountSettings = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: UPDATE_ACCOUNT_SETTINGS_ATTEMPT,
            payload: true
        });
        try{
            let req = await axiosClient.put('/api/AccountSettings', data);
            dispatch({
                type: UPDATE_ACCOUNT_SETTINGS_SUCCESS,
                payload: req.data.UserSettings
            })
        }catch(e){
            dispatch({
                type: UPDATE_ACCOUNT_SETTINGS_FAILURE,
                payload: e.response.Message
            });
        }
    }
}
export const getFulfillersAttempt = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: FETCH_FULFILLERS_ATTEMPT,
            payload: true
        });
        try{
            let req = await axiosClient.get('/api/Fulfillers');
            dispatch({
                type: FETCH_FULFILLERS_SUCCESS,
                payload: req.data
            });
        }catch(e){
            dispatch({
                type: FETCH_FULFILLERS_FAILURE,
                payload: e.message
            })
        }
    }
}