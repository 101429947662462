import React, { Component } from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import MomentUtils from'@date-io/moment';
import {
    deliveryDateChanged,
    deliveryStatusChanged
} from '../../Actions/FulfillRecord';

class Delivered extends Component {
    onToggleDeliveryStatus = (e) => {
        this.props.deliveryStatusChanged(!this.props.deliveryStatus);
        if(this.props.deliveryStatus){
            this.props.deliveryDateChanged(null);
        }
    }
    onChangeDeliveryDate = (e) => {
        this.props.deliveryDateChanged(e);
    }
    render() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={10} lg={10}>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={this.props.deliveryStatus}
                                color='primary'
                                value='delivered'
                                onChange={this.onToggleDeliveryStatus.bind(this)}
                                disabled={this.props.account.id !== this.props.request.Fulfiller.id}
                            />
                        }
                        label='Delivered to Requestor?'
                        style={{width: '100%'}}
                    />
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                {
                    this.props.deliveryStatus ? 
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker 
                            disabled={this.props.account.id !== this.props.request.Fulfiller.id}
                            format='MMMM DD, YYYY' 
                            value={this.props.deliveryDate} 
                            clearable 
                            variant='dialog'
                            fullWidth 
                            inputVariant='filled'
                            label='Delivery Date'
                            onChange={this.onChangeDeliveryDate.bind(this)} />
                    </MuiPickersUtilsProvider>   
                    :
                    null
                }
                </Grid>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    deliveryStatus: state.fulfillRecord.deliveryStatus,
    deliveryDate: state.fulfillRecord.deliveryDate,
    account: state.account.account,
    request: state.request.request
});
export default connect(mapStateToProps,{
    deliveryDateChanged,
    deliveryStatusChanged
})(Delivered);