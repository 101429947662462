import {
    SUBMIT_REJECTION_COMMENT_ATTEMPT,
    SUBMIT_REJECTION_COMMENT_FAILURE,
    SUBMIT_REJECTION_COMMENT_SUCCESS,
    FETCH_REQUEST_COMMENTS_ATTEMPT,
    FETCH_REQUEST_COMMENTS_FAILURE,
    FETCH_REQUEST_COMMENTS_SUCCESS,
    TOGGLE_DISPLAY_COMMENT_DIALOG
} from './types';
import {
    axiosClient,
    
} from '../Helpers/axiosClient'
import { REJECTED } from '../Helpers/Enums';
export const toggleDisplayDialog = (data) => {
    return({
        type: TOGGLE_DISPLAY_COMMENT_DIALOG,
        payload: data
    });
}
export const submitRejectionComment = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: SUBMIT_REJECTION_COMMENT_ATTEMPT,
            payload: true
        });
        let sendData = {
            commentBody: data.commentBody,
            requestStatus: REJECTED.value
        };
        try{
            let res = await axiosClient.post(`/api/RequestComment/${data.requestId}`, sendData);
            dispatch({
                type: SUBMIT_REJECTION_COMMENT_SUCCESS,
                payload: res.data.Comment
            });
        }
        catch(e){
            let failData = {
                message: e.response.Message
            }
            dispatch({
                type: SUBMIT_REJECTION_COMMENT_FAILURE,
                payload: failData
            });
        }
    }
}
export const fetchRequestComments = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: FETCH_REQUEST_COMMENTS_ATTEMPT,
            payload: true
        });
        try{
            let res = await axiosClient.get(`/api/RequestComment/${data.requestId}`);
            dispatch({
                type: FETCH_REQUEST_COMMENTS_SUCCESS,
                payload: res.data.Comments
            })
        }catch(e){
            let failData = {
                message: e.message
            }
            dispatch({
                type: FETCH_REQUEST_COMMENTS_FAILURE,
                payload: failData
            });
        }
    }
}