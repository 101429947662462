import React, { Component } from 'react'
import {connect} from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import {
    fulfillRecordMessageChanged
} from '../../Actions/FulfillRecord';
import { isUndefinedOrNullOrEmpty } from '../../Helpers/helperFunctions';

class FulfillRecordSnackbar extends Component {
    handleCloseSnackbar = (e) => {
        this.props.fulfillRecordMessageChanged('');
    }
    render() {
        const snackbarAction = [
            <Button key={1} 
                size='small' 
                variant='text' 
                color='secondary'
                onClick={this.handleCloseSnackbar.bind(this)}
            >
                Close
            </Button>
        ]
        return (
            <Snackbar 
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                open={!isUndefinedOrNullOrEmpty(this.props.message)}
                autoHideDuration={20000}
                onClose={this.handleCloseSnackbar.bind(this)}
                message={this.props.message}
                action={snackbarAction}
            />
        );
    }
}
const mapStateToProps = state => ({
    message: state.fulfillRecord.fulfillRecordMessage
});
export default connect(mapStateToProps,{
    fulfillRecordMessageChanged
})(FulfillRecordSnackbar);