import React, {Component} from 'react';
import {connect} from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import {
    appRoleChanged
} from '../../../Actions/Admin'

class RoleSelectInput extends Component {
    handleChangeSelectChange = (e) => {
        this.props.appRoleChanged(e.target.value)
    }
    render(){
        return(
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="role-selection-label" variant='outlined'>App Role</InputLabel>
                <Select
                    labelId="role-selection-label"
                    id="role-selection-input"
                    value={this.props.appRole}
                    label="App Role"
                    onChange={this.handleChangeSelectChange}
                >
                    <MenuItem
                        value=''
                    >
                        None
                    </MenuItem>
                    {
                        this.props.appRoles.map(role => (
                            <MenuItem
                                key={role.Id}
                                value={role.AppRoleId}
                            >
                                {role.DisplayName}
                            </MenuItem>
                        ))
                    }       
                </Select>
            </FormControl>            
        );
    }
}

const mapStateToProps = state => ({
    appRoles: state.admin.appRoles,
    appRole: state.admin.appRole
})

const mapDispatchToProps = {
    appRoleChanged
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleSelectInput)