import React, { Component } from 'react';
import {connect} from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

class ProgressIndicator extends Component {
    render() {
        return (
            <React.Fragment>
                {
                    this.props.submittingRequest ?
                    <Grid item xs={12}>
                        <Typography color='textSecondary' align='center' gutterBottom>
                            Updating...
                        </Typography>
                        <CircularProgress style={{textAlign: 'center'}} color='primary' size={45} thickness={6}/>
                    </Grid>
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) =>({
    submittingRequest: state.request.submittingRequest
});
export default connect(mapStateToProps,{
})(ProgressIndicator)