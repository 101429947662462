import {
    ORDER_DATE_CHANGED,
    RECIEVE_STATUS_CHANGED,
    RECIEVE_DATE_CHANGED,
    DELIVERY_DATE_CHANGED,
    DELIVERY_STATUS_CHANGED,
    VENDOR_NAME_CHANGED,
    PURCHASE_ORDER_NUMBER_CHANGED,
    FETCH_FULFILL_RECORD_ATTEMPT,
    FETCH_FULFILL_RECORD_FAILURE,
    FETCH_FULFILL_RECORD_SUCCESS,
    UPDATE_FULFILL_RECORD_ATTEMPT,
    UPDATE_FULFILL_RECORD_FAILURE,
    UPDATE_FULFILL_RECORD_SUCCESS,
    FULFILL_RECORD_MESSAGE_CHANGED
} from './types';
import {axiosClient} from '../Helpers/axiosClient';

export const orderDateChanged = (data) => {
    return({
        type: ORDER_DATE_CHANGED,
        payload: data
    });
}
export const recieveStatusChanged = (data) => {
    return({
        type: RECIEVE_STATUS_CHANGED,
        payload: data
    })
}
export const recieveDateChanged = (data) => {
    return({
        type: RECIEVE_DATE_CHANGED,
        payload: data
    })
}
export const deliveryDateChanged = (data) => {
    return({
        type: DELIVERY_DATE_CHANGED,
        payload: data
    })
}
export const deliveryStatusChanged = (data) => {
    return({
        type: DELIVERY_STATUS_CHANGED,
        payload: data
    })
}
export const vendorNameChanged = (data) => {
    return({
        type: VENDOR_NAME_CHANGED,
        payload: data
    })
}
export const purchaseOrderNumberChanged = (data) =>{
    return({
        type: PURCHASE_ORDER_NUMBER_CHANGED,
        payload: data
    })
}
export const fetchFulfillRecord = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: FETCH_FULFILL_RECORD_ATTEMPT,
            payload: true
        });
        try{
            const res = await axiosClient.get(`/api/FulfillRecord/${data}`);
            dispatch({
                type: FETCH_FULFILL_RECORD_SUCCESS,
                payload: res.data.fulfillRecord
            });
        }catch(e){
            dispatch({
                type: FETCH_FULFILL_RECORD_FAILURE,
                paylaod: e.message
            });
        }
    }
}
export const udpateFulfillRecord = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: UPDATE_FULFILL_RECORD_ATTEMPT,
            payload: true
        });
        try{
            let res = await axiosClient.put(`/api/FulfillRecord/${data.RequestId}`, data);
            dispatch({
                type: UPDATE_FULFILL_RECORD_SUCCESS,
                payload: res.data
            });
        }catch(e){
            dispatch({
                type: UPDATE_FULFILL_RECORD_FAILURE,
                payload: e.message
            });
        }
    }
}
export const fulfillRecordMessageChanged = (data) => {
    return({
        type: FULFILL_RECORD_MESSAGE_CHANGED,
        payload: data
    });
}