import React, { Component } from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
    purchaseOrderNumberChanged
} from '../../Actions/FulfillRecord'

class PurchaseOrderNumber extends Component {
    handlePurchaseOrderNumberChanged = (e) => {
        this.props.purchaseOrderNumberChanged(e.target.value)
    }
    render() {
        return (
            <Grid item xs={12} md={10}>
                <TextField 
                    variant='filled'
                    value={this.props.purchaseOrderNumber}
                    onChange={this.handlePurchaseOrderNumberChanged.bind(this)}
                    label='Purchase Order Num.'
                    fullWidth
                    disabled={this.props.account.id !== this.props.request.Fulfiller.id}
                />
            </Grid>
        )
    }
}
const mapStateToProps = state =>({
    purchaseOrderNumber: state.fulfillRecord.purchaseOrderNumber,
    request: state.request.request,
    account: state.account.account
});
export default connect(mapStateToProps, {
    purchaseOrderNumberChanged
})(PurchaseOrderNumber)