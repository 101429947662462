import {
    APP_GET_USERS,
    APP_GET_USERS_FAILURE,
    APP_GET_USERS_SUCCESS,
    APP_GET_ROLES,
    APP_GET_ROLES_FAILURE,
    APP_GET_ROLES_SUCCESS,
    APP_ASSIGN_USER_ROLE,
    APP_ASSIGN_USER_ROLE_FAILURE,
    APP_ASSIGN_USER_ROLE_SUCCESS,
    APP_ADD_USER_ROLE,
    APP_ADD_USER_ROLE_FAILURE,
    APP_ADD_USER_ROLE_SUCCESS,
    APP_EDIT_USER_ROLE,
    APP_CANCEL_EDIT_USER_ROLE,
    APP_APP_USER_CHANGED,
    APP_APP_ROLE_CHANGED,
    APP_APP_USER_INPUT_CHANGED,
    APP_TOGGLE_DELETE_DIALOG,
    APP_DELETE_USER_ROLE,
    APP_DELETE_USER_ROLE_FAILURE,
    APP_DELETE_USER_ROLE_SUCCESS
} from './types';
import {authContext} from '../Helpers/adalAuthentication';
import {axiosClient} from '../Helpers/axiosClient';

export const getAppUsers = (data) => {
    return async(dispatch) => {
        dispatch({
            type: APP_GET_USERS,
            payload: true
        });
        try{
            const res = await axiosClient.get('/api/AppUsers');
            dispatch({
                type: APP_GET_USERS_SUCCESS,
                payload: res.data
            })
        }catch(e){
            dispatch({
                type: APP_GET_USERS_FAILURE,
                payload: e.response.data
            })
        }
    }
}

export const getAppRoles = (data) => {
    return async(dispatch) => {
        dispatch({
            type: APP_GET_ROLES,
            payload: true
        })
        try{
            const res = await axiosClient.get('/api/AppRoles');
            dispatch({
                type: APP_GET_ROLES_SUCCESS,
                payload: res.data
            })
        }catch(e){
            dispatch({
                type: APP_GET_ROLES_FAILURE,
                payload: e.response.data
            })
        }
    }
}

export const appAssignUserRole = (data) => {
    return async(dispatch) => {
        dispatch({
            type: APP_ASSIGN_USER_ROLE,
            payload: true
        });
        try{
            const res = await axiosClient.put('/api/AppUsers', {
                UserId: data.userId,
                AppRoleId: data.appRoleId
            });
            dispatch({
                type: APP_ASSIGN_USER_ROLE_SUCCESS,
                payload: res.data
            })
        }catch(e){
            if(e.response){
                dispatch({
                    type: APP_ASSIGN_USER_ROLE_FAILURE,
                    payload: e.response.data
                })
            }else if(e.request){
                dispatch({
                    type: APP_ASSIGN_USER_ROLE_FAILURE,
                    payload: {
                        Message: e.message
                    }
                })
            }else{
                dispatch({
                    type: APP_ASSIGN_USER_ROLE_FAILURE,
                    payload: {
                        Message: e.message
                    }
                })
            }
        }
    }
}

export const appAddUserRole = (data) => {
    return async(dispatch) => {
        dispatch({
            type: APP_ADD_USER_ROLE,
            payload: true
        })
        try{
            const foundUser = data.appUsers.find(user => user.resourceId === data.userId);
            if(foundUser !== undefined && foundUser !== null){
                dispatch({
                    type: APP_ADD_USER_ROLE_FAILURE,
                    payload: {
                        Message: 'The user you selected is already in the application.'
                    }
                })
            }else{
                const res = await axiosClient.post('/api/AppUsers', {
                    UserId: data.userId,
                    AppRoleId: data.appRoleId
                });
                dispatch({
                    type: APP_ADD_USER_ROLE_SUCCESS,
                    payload: res.data
                })
            }
        }catch(e){
            dispatch({
                type: APP_ADD_USER_ROLE_FAILURE,
                payload: e.response.data
            })
        }
    }
}

export const editUserRole = (data) => {
    return{
        type: APP_EDIT_USER_ROLE,
        payload: data
    }
}

export const cancelEditUserRole = (data) => {
    return{
        type: APP_CANCEL_EDIT_USER_ROLE,
        payload: data
    }
}

export const appUserChanged = (data) => {
    return{
        type: APP_APP_USER_CHANGED,
        payload: data
    }
}

export const appRoleChanged = (data) => {
    return{
        type: APP_APP_ROLE_CHANGED,
        payload: data
    }
}

export const appUserInputChanged = (data) => {
    return{
        type: APP_APP_USER_INPUT_CHANGED,
        payload: data
    }
}

export const toggleDeleteDialog = (data) => {
    return{
        type: APP_TOGGLE_DELETE_DIALOG,
        payload: data
    }
}

export const deleteUserRoleAssignment = (data) => {
    return async(dispatch) => {
        dispatch({
            type: APP_DELETE_USER_ROLE,
            payload: true
        })
        try{
            const res = await axiosClient.delete(`/api/AppUsers/${data.id}`);
            dispatch({
                type: APP_DELETE_USER_ROLE_SUCCESS,
                payload: {
                    id: data.id,
                    ...res.data
                }
            })
        }catch(e){
            dispatch({
                type: APP_DELETE_USER_ROLE_FAILURE,
                payload: e.response.data
            })
        }
    }
}