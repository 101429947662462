import React, {Component} from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import green from '@material-ui/core/colors/green';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import {
    SUBMITTED,
    SM_APPROVED,
    DM_APPROVED,
    IN_PROGRESS,
    COMPLETED,
    REJECTED
} from '../Helpers/Enums';
import {connect} from 'react-redux';
import { isUndefinedOrNullOrEmpty, getRequestStatusName } from '../Helpers/helperFunctions';
import {
    updateRequestStatusAttempt,
    toggleConfirmUpdateDialog
} from '../Actions/Request';

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);
  
class RequestsTableRow extends Component{
    state={
        editingStatus: false,
        requestStatusObject: {
            name: SUBMITTED.name,
            value: SUBMITTED.value
        }
    };
    componentDidMount(){
        let name = getRequestStatusName(this.props.request.RequestStatus);
        let value = this.props.request.RequestStatus;
        this.setState({
            ...this.state,
            requestStatusObject:{
                name: name,
                value: value
            }
        });
    }
    showEditStatusBtn(){
        if(this.props.fetchingAccount || isUndefinedOrNullOrEmpty(this.props.account)){
            return false;
        }
        if(this.state.requestStatusObject.value === REJECTED.value || this.state.requestStatusObject.value === COMPLETED.value){
            return false;
        }
        let accountId = this.props.account.id;
        let adminUsers = [this.props.request.Fulfiller, this.props.request.SectionManager, this.props.request.DivisionManager];
        let editable = false;
        const adminIndex = adminUsers.indexOf(accountId);
        if(adminIndex < 0){
            return false;
        }else{
            editable = 
                (this.props.request.RequestStatus === SUBMITTED.value && this.props.request.SectionManager === accountId)
                || (this.props.request.RequestStatus === SM_APPROVED.value && this.props.request.DivisionManager === accountId)
                || (this.props.request.RequestStatus === DM_APPROVED.value && this.props.request.Fulfiller === accountId);
                // || (this.props.request.RequestStatus === IN_PROGRESS.value && this.props.request.Fulfiller === accountId);
            return editable;
        }
    }
    onClickEditStatus(e){
        this.setState({
            ...this.state,
            editingStatus: true
        });
    }
    onClickUpdateStatus = (e)=>{
        let data = {
            requestData:{
                status: this.state.requestStatusObject.value,
                requestId: this.props.request.Id,
                requestsIndex: this.props.requestsIndex
            },
            displayDialog: true
        };
        this.props.toggleConfirmUpdateDialog(data);
        this.setState({
            ...this.state,
            editingStatus: false
        });
    }
    onClickCancelStatusEdit(){
        this.setState({
            ...this.state,
            editingStatus: false,
            requestStatusObject: {
                value: this.props.request.RequestStatus
            }
        })
    }
    handleStatusChange = (e) => {
        this.setState({
            ...this.state,
            requestStatusObject: {
                value: e.target.value
            }
        })
    }
    render(){
        let dropdownOptions = [];
        if(this.props.request.RequestStatus === SUBMITTED.value){
            dropdownOptions.push(SUBMITTED);
            dropdownOptions.push(SM_APPROVED);
        }
        else if(this.props.request.RequestStatus === SM_APPROVED.value){
            dropdownOptions.push(SM_APPROVED);
            dropdownOptions.push(DM_APPROVED);
        }
        else if(this.props.request.RequestStatus === DM_APPROVED.value){
            dropdownOptions.push(DM_APPROVED);
            dropdownOptions.push(IN_PROGRESS);
        }
        else if(this.props.request.RequestStatus === IN_PROGRESS.value){
            dropdownOptions.push(IN_PROGRESS);
            dropdownOptions.push(COMPLETED);
        }
        dropdownOptions.push(REJECTED);
        return(
            <TableRow>
                <TableCell padding='checkbox'>
                    <Button color='primary' variant='text' to={`requests/${this.props.request.Id}`} component={AdapterLink}>View</Button>
                </TableCell>
                {
                    this.props.displayRows.map((col, index)=>(
                        <TableCell key={`${this.props.request.Id}_${col.value}`}>
                            {
                                col.type === 'date' && this.props.request[col.value] !== null ? 
                                new Date(this.props.request[col.value] + 'Z').toLocaleDateString()
                                :
                                this.props.request[col.value]
                            }
                        </TableCell>
                    ))
                }
                <TableCell>
                    {
                        this.state.editingStatus ? 
                        null
                        :
                        getRequestStatusName(this.props.request.RequestStatus)
                    }
                    {
                        this.state.editingStatus ?
                        <Select value={this.state.requestStatusObject.value} onChange={this.handleStatusChange.bind(this)} disabled={this.props.displayConfirmationDialog}>
                            {
                                dropdownOptions.map((dropdownOption, index) => (
                                    <MenuItem key={dropdownOption.value} value={dropdownOption.value}>
                                        {dropdownOption.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                        :
                        null
                    }
                    {
                        this.showEditStatusBtn() && !this.state.editingStatus ?
                            <Tooltip title='Edit Status'>
                                <IconButton size='small' onClick={this.onClickEditStatus.bind(this)}>
                                    <Icon style={{color: green[500]}}>create</Icon>
                                </IconButton>
                            </Tooltip>
                        :
                        null
                    }
                    {
                        this.state.editingStatus ?
                        <React.Fragment>
                            <Tooltip disableFocusListener title='Save Changes'>
                                <IconButton color='primary' size='small' disabled={this.state.requestStatusObject.value === this.props.request.RequestStatus} onClick={this.onClickUpdateStatus.bind(this)}>
                                    <Icon>save</Icon>
                                </IconButton>
                            </Tooltip>
                            <Tooltip disableFocusListener title='Cancel'>
                                <IconButton color='secondary' size='small' onClick={this.onClickCancelStatusEdit.bind(this)}>
                                    <Icon>cancel</Icon>
                                </IconButton>
                            </Tooltip>
                        </React.Fragment>
                        : 
                        null
                    }
                </TableCell>
            </TableRow>
        );
    }
}
const mapStateToProps = state => ({
    users: state.account.users,
    usersLoading: state.account.usersLoading,
    account: state.account.account,
    fetchingAccount: state.account.fetchingAccount,
    requestTableTab: state.request.requestTableTab,
    displayRows: state.request.displayRows,
    displayConfirmationDialog: state.request.displayConfirmationDialog
});
export default connect(mapStateToProps,{
    updateRequestStatusAttempt,
    toggleConfirmUpdateDialog
})(RequestsTableRow);