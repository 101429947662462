import {
    REQUEST_ATTACHMENT_GET_SAS_URI,
    REQUEST_ATTACHMENT_GET_SAS_URI_FAILURE,
    REQUEST_ATTACHMENT_GET_SAS_URI_SUCCESS,
    REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT,
    REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_SUCCESS,
    REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_FAILURE,
    REQUEST_ATTACHMENT_FILE_CHANGED,
    REQUEST_ATTACHMENT_DOWNLOAD,
    REQUEST_ATTACHMENT_DOWNLOAD_SUCCESS,
    REQUEST_ATTACHMENT_DOWNLOAD_FAILURE,
    REQUEST_ATTACHMENT_FILE_CHANGED_ERROR
} from '../Actions/types';
const initialState = {
    file: '',
    filename: '',
    fetchingSasUri: false,
    sasUri: '',
    uploading: false,
    message: '',
    errorType: false,
    errorFetchingSas: false,
    errorUploading: false,
    downloading: false
}
export default (state=initialState, action) => {
    switch(action.type){
        case REQUEST_ATTACHMENT_FILE_CHANGED:
            return{
                ...state,
                file: action.payload,
                errorType: false
            }
        case REQUEST_ATTACHMENT_FILE_CHANGED_ERROR:
            return{
                ...state,
                file: '',
                errorType: true
            }
        case REQUEST_ATTACHMENT_GET_SAS_URI:
            return{
                ...state,
                fetchingSasUri: true,
                errorFetchingSas: false,
                message: '',
                filename: ''
            }
        case REQUEST_ATTACHMENT_GET_SAS_URI_FAILURE:
            return{
                ...state,
                fetchingSasUri: false,
                errorFetchingSas: true,
                message: action.payload.Message
            }
        case REQUEST_ATTACHMENT_GET_SAS_URI_SUCCESS:
            return{
                ...state,
                fetchingSasUri: false,
                errorFetchingSas: false,
                message: '',
                sasUri: action.payload.ContainerUri,
                filename: action.payload.FileName
            }
        case REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT:
            return{
                ...state,
                uploading: true,
                errorUploading: false,
            }
        case REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_FAILURE:
            return{
                ...state,
                errorUploading: true,
                uploading: false
            }
        case REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_SUCCESS:
            return{
                ...state,
                errorUpload: false,
                uploading: false, 
                filename: action.payload.FileName
            }
        case REQUEST_ATTACHMENT_DOWNLOAD:
            return{
                ...state,
                downloading: true
            }
        case REQUEST_ATTACHMENT_DOWNLOAD_FAILURE:
            return{
                ...state,
                downloading: false
            }
        case REQUEST_ATTACHMENT_DOWNLOAD_SUCCESS:
            return{
                ...state,
                downloading: false
            }
        default: return state
    }
}