import React, { Component } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {connect} from 'react-redux';
import RequestsTableRow from './RequestsTableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    getRequestStatusName
} from '../Helpers/helperFunctions';
import {
    sortRequests
} from '../Actions/Request';
import {
    filterRequestsAttempt
} from '../Actions/RequestFilters';
class RequestsTable extends Component {
    state = {
        rowCount: 5,
        tablePage: 0,
        filteredPage: 0,
    };
    handleChangePage = (e, newPage) => {
        if(this.props.displayFilteredRequests){
            this.setState({
                ...this.state,
                filteredPage: newPage
            });
        }else{
            this.setState({
                ...this.state,
                tablePage: newPage,
                filteredPage: 0
            })
        }
    }
    handleChangeRowsPerPage = (e) => {
        let requestCount = this.props.requests.length;
        if(e.target.value >= requestCount && this.state.tablePage > 0){
            this.setState({
                ...this.state,
                rowCount: e.target.value,
                tablePage: 0
            });
        }else{
            this.setState({
                ...this.state,
                rowCount: e.target.value
            })   
        }
    }
    sortByStatus = (e) =>{
        if(!this.props.fetchingRequests){
            const requests = this.props.displayFilteredRequests ? this.props.filteredRequests : this.props.requests;
            requests.forEach((request)=>{
                request.StatusName = getRequestStatusName(request.RequestStatus);
            });
            this.sortGeneric('StatusName', requests, e);
        }
    }
    sortGeneric = (field, requests, e) => {
        if(!this.props.fetchingRequests){
            let direction = this.props.sortDirection === 'asc' ? -1 : 1;
            requests.sort((a,b)=>{
                if(a[field] > b[field]){
                    return 1 * direction;
                }
                else if(a[field] < b[field]){
                    return -1 * direction;
                }
                else{
                    return 0;
                }
            });
            let data = {
                requests,
                sortBy: field
            }
            this.props.sortRequests(data);
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.requests !== this.props.requests && this.props.displayFilteredRequests){
            this.props.filterRequestsAttempt({
                requests: this.props.requests,
                users: this.props.users,
                filters: this.props.filters
            });
        }
    }
    render() {
        const requests = this.props.displayFilteredRequests ? this.props.filteredRequests : this.props.requests;
        const page = this.props.displayFilteredRequests ? this.state.filteredPage : this.state.tablePage;
        return (
            <React.Fragment>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox"></TableCell>
                            {
                                this.props.displayRows.map((headerCol, index)=>(
                                    <TableCell key={index} sortDirection={this.props.sortBy === headerCol.value ? this.props.sortDirection : null}>
                                        <TableSortLabel onClick={this.sortGeneric.bind(this, headerCol.value, requests)} active={this.props.sortBy === headerCol.value} direction={this.props.sortDirection}>
                                            {headerCol.name}
                                        </TableSortLabel>
                                    </TableCell>
                                ))
                            }
                            <TableCell sortDirection={this.props.sortBy === 'StatusName' ? this.props.sortDirection : null}>
                                <TableSortLabel onClick={this.sortByStatus.bind(this)} active={this.props.sortBy === 'StatusName'} direction={this.props.sortDirection}>
                                    Status
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.fetchingRequests || this.props.usersLoading ? 
                            <TableRow>
                                <TableCell colSpan={this.props.displayRows.length + 2} style={{textAlign: 'center'}}>
                                    <CircularProgress size={48} variant='indeterminate'/>
                                </TableCell>
                            </TableRow>
                            :
                            requests.slice(page * this.state.rowCount, (page * this.state.rowCount) + this.state.rowCount)
                            .map((request, index) => <RequestsTableRow key={request.Id}  request={request} requestsIndex={index}/>)
                        }
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5,10,15,25]}
                    component='div'
                    count={requests.length || 0}
                    page={page}
                    rowsPerPage={this.state.rowCount}
                    backIconButtonProps={{
                        'aria-label': 'previous page'
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'next page'
                    }}
                    SelectProps={{
                        MenuProps: {
                            transformOrigin:{
                                vertical: 'bottom',
                                horizontal:'center'
                            },
                            anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center'
                            }
                        }
                    }}
                    onChangePage={this.handleChangePage.bind(this)}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage.bind(this)}
                />
            </React.Fragment>
        )
    }
}
const mapStateToProps = state =>({
    fetchingRequests: state.request.fetchingRequests,
    requests: state.request.requests,
    displayFilteredRequests: state.request.displayFilteredRequests,
    filteredRequests: state.request.filteredRequests,
    users: state.account.users,
    usersLoading: state.account.usersLoading,
    sortBy: state.request.sortBy,
    sortDirection: state.request.sortDirection,
    displayRows: state.request.displayRows,
    filters: state.request.filters
});
export default connect(mapStateToProps, {
    sortRequests,
    filterRequestsAttempt
})(RequestsTable);