import React, { Component } from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import MomentUtils from'@date-io/moment';
import {
    recieveDateChanged,
    recieveStatusChanged
} from '../../Actions/FulfillRecord';

class Recieved extends Component {
    onToggleRecieveStatus = (e) => {
        this.props.recieveStatusChanged(!this.props.recieveStatus);
        if(this.props.recieveStatus){
            this.props.recieveDateChanged(null);
        }
    }
    onChangeRecieveDate = (e) => {
        this.props.recieveDateChanged(e);
    }
    render() {
        return (
            <React.Fragment>
                <Grid item xs={12} md={10} lg={10}>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={this.props.recieveStatus}
                                color='primary'
                                value='recieved'
                                onChange={this.onToggleRecieveStatus.bind(this)}
                                disabled={this.props.account.id !== this.props.request.Fulfiller.id}
                            />
                        }
                        label='Received by Fulfiller?'
                        style={{width: '100%'}}
                    />
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                {
                    this.props.recieveStatus ?
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker 
                            disabled={this.props.account.id !== this.props.request.Fulfiller.id}
                            format='MMMM DD, YYYY' 
                            value={this.props.recieveDate} 
                            clearable 
                            variant='dialog'
                            fullWidth 
                            inputVariant='filled'
                            label='Recieve Date'
                            onChange={this.onChangeRecieveDate.bind(this)} />
                    </MuiPickersUtilsProvider>
                    :
                    null
                }
                </Grid>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => ({
    recieveStatus: state.fulfillRecord.recieveStatus,
    recieveDate: state.fulfillRecord.recieveDate,
    request: state.request.request,
    account: state.account.account
});
export default connect(mapStateToProps,{
    recieveDateChanged,
    recieveStatusChanged
})(Recieved);