import React, { Component } from 'react'
import {connect} from 'react-redux';
import {Redirect, Route, withRouter} from 'react-router-dom';
import {
    accountFetchAttempt,
    getUsersAttempt,
    getEmployeesAttempt,
    getFulfillersAttempt,
    logout
} from '../Actions/Account';
import { isUndefinedOrNullOrEmpty } from '../Helpers/helperFunctions';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {
    loginAttempt
} from '../Actions/Account'
class PrivateRoute extends Component {
    componentDidMount(){
        const urlPath = this.props.history.location.pathname;
        if(this.props.authenticated && isUndefinedOrNullOrEmpty(this.props.account)){
            //initiate call to retrieve account info
            this.props.accountFetchAttempt(true);
        }
        if(this.props.authenticated && !isUndefinedOrNullOrEmpty(localStorage.getItem('redirectTo')) && !urlPath.includes(localStorage.getItem('redirectTo'))){
            const redirectPath = localStorage.getItem('redirectTo');
            localStorage.removeItem('redirectTo');
            this.props.history.push(redirectPath);
        }
        window.addEventListener('storage', (e)=>{
            if(e.key === 'adal.idtoken' && isUndefinedOrNullOrEmpty(e.newValue)){
                this.props.logout(false);
            }
        }, true);
    }
    componentDidUpdate(){
        if(this.props.authenticated && !this.props.fetchingAccount && this.props.waitingUsersData){
            this.props.getUsersAttempt(true);
            this.props.getEmployeesAttempt(true);
            this.props.getFulfillersAttempt(true);
        }
    }
    render() {
        const{component: Component, ...props} = this.props;
        const redirectQuery = window.location.pathname.includes('requests') ?  `?redirectTo=${window.location.pathname}` : null;
        return (
            <Route 
                exact
                {...props}
                render={
                    props => (
                        this.props.authenticated ? 
                        <React.Fragment>
                            {
                                this.props.fetchingAccount ?
                                <Grid item xs={12}>
                                    <CircularProgress size={60} style={{marginBottom: '1rem', textAlign:'center'}} />
                                    <Typography variant='h5' align='center' color='textPrimary'>Loading Account Info</Typography>
                                </Grid>
                                :
                                <Component {...props} />
                            }
                        </React.Fragment>
                        :
                        // <Redirect to={`/login${window.location.search.includes('redirectTo') ? window.location.search : null }`} />
                        // <Redirect to ={`/login${window.location.search}`} />
                        // <Redirect to='/login' />
                        <Redirect to={`/login${redirectQuery}`} />
                    )
                }
            />
        )
    }
}
const mapStateToProps = (state) => ({
    authenticated: state.account.authenticated,
    account: state.account.account,
    users: state.account.users,
    employees: state.account.employees,
    fulfillers: state.account.fulfillers,
    pendingManagerRequests: state.request.pendingManagerRequests,
    fetchingAccount: state.account.fetchingAccount,
    employeesLoading: state.account.employeesLoading,
    fulfillersLoading: state.account.fulfillersLoading,
    waitingUsersData: state.account.waitingUsersData
});
export default withRouter(connect(mapStateToProps, {
    accountFetchAttempt,
    getUsersAttempt,
    getEmployeesAttempt,
    getFulfillersAttempt,
    logout,
    loginAttempt
})(PrivateRoute));