import {
    REQUEST_FILTER_EMPLOYEE_EMAIL_CHANGED,
    REQUEST_FILTER_EMPLOYEE_NAME_CHANGED,
    REQUEST_FILTER_END_DATE_CHANGED,
    REQUEST_FILTER_REQUEST_STATUS_CHANGED,
    REQUEST_FILTER_REQUEST_TYPE_CHANGED,
    REQUEST_FILTER_START_DATE_CHANGED,
    FILTER_REQUESTS_ATTEMPT,
    FILTER_REQUESTS_COMPLETE,
    CLEAR_REQUESTS_FILTERS
} from './types';
import { isUndefinedOrNullOrEmpty } from '../Helpers/helperFunctions';
export const requestFilterEmployeeEmailChanged = (data)=>{
    return({
        type: REQUEST_FILTER_EMPLOYEE_EMAIL_CHANGED,
        payload: data
    });
}
export const requestFilterEmployeeNameChanged = (data)=>{
    return({
        type: REQUEST_FILTER_EMPLOYEE_NAME_CHANGED,
        payload: data
    });
}
export const requestFilterEndDateChanged = (data)=>{
    return({
        type: REQUEST_FILTER_END_DATE_CHANGED,
        payload: data
    });
}
export const requestFilterStartDateChanged = (data)=>{
    return({
        type: REQUEST_FILTER_START_DATE_CHANGED,
        payload: data
    });
}
export const requestFilterStatusChanged = (data)=>{
    return({
        type: REQUEST_FILTER_REQUEST_STATUS_CHANGED,
        payload: data
    });
}
export const requestFilterTypeChanged = (data)=>{
    return({
        type: REQUEST_FILTER_REQUEST_TYPE_CHANGED,
        payload: data
    });
}
export const filterRequestsAttempt = (data)=>{
    return(dispatch)=>{
        dispatch({
            type: FILTER_REQUESTS_ATTEMPT,
            payload: true
        });
        const users = data.users;
        let employeeFilter = [];
        const status = data.filters.requestStatus;
        const requestType = data.filters.requestType;
        //filter by employee first
        let requests = data.requests;
        if((!isUndefinedOrNullOrEmpty(data.filters.employeeName.trim()) || !isUndefinedOrNullOrEmpty(data.filters.employeeEmail.trim())) && requests.length > 0){
            employeeFilter = users.filter((user)=>{
                let displayName = user.displayName.toLowerCase();
                let upn = user.userPrincipalName.toLowerCase();
                let nameFilter = data.filters.employeeName;
                let emailFilter = data.filters.employeeEmail;
                let nameCheck = true;
                let emailCheck = true;
                if(!isUndefinedOrNullOrEmpty(nameFilter)){
                    if(nameFilter.startsWith('*')){
                        nameCheck = displayName.endsWith(nameFilter.substring(1).toLowerCase());
                    }else if(nameFilter.endsWith('*')){
                        nameCheck = displayName.startsWith(nameFilter.substring(0, nameFilter.length - 1).toLowerCase());
                    }else{
                        nameCheck = displayName.includes(nameFilter.toLowerCase());
                    }
                }
                if(!isUndefinedOrNullOrEmpty(emailFilter)){
                    if(emailFilter.startsWith('*')){
                        emailCheck = upn.startsWith(emailFilter.substring(1).toLowerCase());
                    }else if(emailFilter.endsWith('*')){
                        emailCheck = upn.endsWith(emailFilter.substring(0, emailFilter.length - 1).toLowerCase());
                    }else{
                        emailCheck = upn.includes(emailFilter.toLowerCase());
                    }
                }
                // return user.displayName.toLowerCase().includes(data.filters.employeeName.toLowerCase().trim()) && user.userPrincipalName.toLowerCase().includes(data.filters.employeeEmail.toLowerCase().trim());
                return nameCheck && emailCheck;
            })
            .map((user) => {
                return user.id
            });
            requests = requests.filter((request)=>{
                return employeeFilter.indexOf(request.Employee) >= 0 || employeeFilter.indexOf(request.Fulfiller) >= 0 || employeeFilter.indexOf(request.SectionManager) >= 0 || employeeFilter.indexOf(request.DivisionManager) >= 0 || employeeFilter.indexOf(request.Requestor) >= 0
            })
        }
        if(!isUndefinedOrNullOrEmpty(status) && status.length > 0){
            requests = requests.filter((request)=>{
                return status.indexOf(request.RequestStatus) >= 0
            });
        }
        if(!isUndefinedOrNullOrEmpty(requestType) && requests.length > 0){
            requests = requests.filter((request)=>{
                if(requestType.startsWith("*")){
                    return request.RequestType.toLowerCase().endsWith(requestType.substring(1).toLowerCase().trim());
                }
                else if(requestType.endsWith("*")){
                    return request.RequestType.toLowerCase().startsWith(requestType.substring(0, requestType.length-1).toLowerCase().trim());
                }else{
                    return request.RequestType.toLowerCase().includes(requestType.toLowerCase().trim());
                }
            });
        }
        if(!isUndefinedOrNullOrEmpty(data.filters.startDate)){
            let startDate = new Date(data.filters.startDate);
            startDate.setSeconds(0,0);
            startDate.setHours(0);
            startDate.setMinutes(0);
            requests = requests.filter((request)=>{
                return new Date(request.Submitted) >= startDate;
            });
        }
        if(!isUndefinedOrNullOrEmpty(data.filters.endDate)){
            let endDate = new Date(data.filters.endDate);
            endDate.setHours(23);
            endDate.setSeconds(59);
            endDate.setMinutes(59);
            requests = requests.filter((request)=>{
                return new Date(request.Submitted) <= endDate;
            });
        }
        dispatch({
            type: FILTER_REQUESTS_COMPLETE,
            payload: requests
        });
    }
}
export const clearRequestsFilters = (data)=>{
    return({
        type: CLEAR_REQUESTS_FILTERS,
        payload: ''
    });
}