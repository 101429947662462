/************************* USER & ACCOUNT TYPES ********************************/
export const LOGIN_ATTEMPT='LOGIN_ATTEMPT';
export const LOGIN_SUCCESS='LOGIN_SUCCESS';
export const LOGIN_FAILURE='LOGIN_FAILURE';
export const ACCOUNT_FETCH_ATTEMPT='ACCOUNT_FETCH_ATTEMPT';
export const ACCOUNT_FETCH_SUCCESS='ACCOUNT_FETCH_SUCCESS';
export const ACCOUNT_FETCH_FAILURE='ACCOUNT_FETCH_FAILURE';
export const REQUEST_SUBMIT_ATTEMPT='ACCOUNT_SUBMIT_ATTEMPT';
export const REQUEST_SUBMIT_SUCCESS='REQUEST_SUBMIT_SUCCESS';
export const REQUEST_SUBMIT_FAILURE='REQUEST_SUBMIT_FAILURE';
export const LOGOUT='LOGOUT';
export const FETCH_USERS_ATTEMPT='FETCH_USERS_ATTEMPT';
export const FETCH_USERS_SUCCESS='FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE='FETCH_USERS_FAILURE';
export const GENERATE_USER_OPTIONS_ATTEMPT='GENERATE_USER_OPTIONS_ATTEMPT';
export const GENERATE_USER_OPTIONS_SUCCESS='GENERATE_USER_OPTIONS_SUCCESS';
export const GENERATE_USER_OPTIONS_FAILURE='GENERATE_USER_OPTIONS_FAILURE';
export const FETCH_EMPLOYEES_ATTEMPT='FETCH_EMPLOYEES_ATTEMPT';
export const FETCH_EMPLOYEES_FAILURE='FETCH_EMPLOYEES_FAILURE';
export const FETCH_EMPLOYEES_SUCCESS='FETCH_EMPLOYEES_SUCCESS';
export const GENERATE_EMPLOYEE_OPTIONS_ATTEMPT='GENERATE_EMPLOYEE_OPTIONS_ATTEMPT';
export const GENERATE_EMPLOYEE_OPTIONS_SUCCESS='GENERATE_EMPLOYEE_OPTIONS_SUCCESS';
export const GENERATE_EMPLOYEE_OPTIONS_FAILURE='GENERATE_EMPLOYEE_OPTIONS_FAILURE';
export const FETCH_FULFILLERS_ATTEMPT = 'FETCH_FULFILLERS_ATTEMPT';
export const FETCH_FULFILLERS_SUCCESS='FETCH_FULFILLERS_SUCCESS';
export const FETCH_FULFILLERS_FAILURE='FETCH_FULFILLERS_FAILURE';
export const GENERATE_FULFILLERS_OPTIONS_ATTEMPT='GENERATE_FULFILLERS_OPTIONS_ATTEMPT';
export const GENERATE_FULFILLERS_OPTIONS_SUCCESS='GENERATE_FULFILLERS_OPTIONS_SUCCESS';
export const GENERATE_FULFILLERS_OPTIONS_FAILURE='GENERATE_FULFILLERS_OPTIONS_FAILURE';
/***************************** ACCOUNT SETTING TYPES **********************************/
export const FETCH_ACCOUNT_SETTINGS_ATTEMPT='FETCH_ACCOUNT_SETTINGS_ATTEMPT';
export const FETCH_ACCOUNT_SETTINGS_SUCCESS='FETCH_ACCOUNT_SETTINGS_SUCCESS';
export const FETCH_ACCOUNT_SETTINGS_FAILURE='FETCH_ACCOUNT_SETTINGS_FAILURE';
export const NOTIFICATION_FREQUENCY_OPTION_CHANGED='NOTIFICATION_FREQUENCY_OPTION_CHANGED';
export const UPDATE_ACCOUNT_SETTINGS_ATTEMPT='UPDATE_ACCOUNT_SETTINGS_ATTEMPT';
export const UPDATE_ACCOUNT_SETTINGS_FAILURE='UPDATE_ACCOUNT_SETTINGS_FAILURE';
export const UPDATE_ACCOUNT_SETTINGS_SUCCESS='UPDATE_ACCOUNT_SETTINGS_SUCCESS';
/*********************** REQUEST TYPES *************************/
export const MANAGER_INPUT_CHANGED='MANAGER_INPUT_CHANGED';
export const EMPLOYEE_INPUT_CHANGED='EMPLOYEE_INPUT_CHANGED';
export const REQUEST_TYPE_INPUT_CHANGED='REQUEST_TYPE_INPUT_CHANGED';
export const REQUEST_DESCRIPTION_INPUT_CHANGED='REQUEST_DESCRIPTION_INPUT_CHANGED';
export const REQUEST_FULFILLER_INPUT_CHANGED='REQUEST_FULFILLER_INPUT_CHANGED';
export const REQUEST_JUSTIFICATION_INPUT_CHANGED='REQUEST_JUSTIFICATION_INPUT_CHANGED';
export const SUBMIT_REQUEST_ATTEMPT='SUBMIT_REQUEST_ATTEMPT';
export const SUBMIT_REQUEST_FAILURE='SUBMIT_REQUEST_FAILURE';
export const SUBMIT_REQUEST_SUCCESS='SUBMIT_REQUEST_SUCCESS';
export const FETCH_REQUESTS_ATTEMPT='FETCH_REQUESTS_ATTEMPT';
export const FETCH_REQUESTS_SUCCESS='FETCH_REQUESTS_SUCCESS';
export const FETCH_REQUESTS_FAILURE='FETCH_REQUESTS_FAILURE';
export const FETCH_REQUEST_ATTEMPT='FETCH_REQUEST_ATTEMPT';
export const FETCH_REQUEST_SUCCESS='FETCH_REQUEST_SUCCESS';
export const FETCH_REQUEST_FAILURE='FETCH_REQUEST_FAILURE';
export const UPDATE_REQUEST_STATUS_ATTEMPT='UPDATE_REQUEST_STATUS_ATTEMPT';
export const UPDATE_REQUEST_STATUS_SUCCESS='UPDATE_REQUEST_STATUS_SUCCESS';
export const UPDATE_REQUEST_STATUS_FAILURE='UPDATE_REQUEST_STATUS_FAILURE';
export const REQUEST_STATUS_INPUT_CHANGED='REQUEST_STATUS_INPUT_CHANGED';
export const RESET_REQUEST_MESSAGE='RESET_REQUEST_MESSAGE';
export const EDIT_REQUEST='EDIT_REQUEST';
export const CANCEL_EDIT_REQUEST='CANCEL_EDIT_REQUEST';
export const CHANGE_REQUESTS_TABLE_TAB='CHANGE_REQUESTS_TABLE_TAB';
export const UPDATE_REQUEST_ATTEMPT='UPDATE_REQUEST_ATTEMPT';
export const UPDATE_REQUEST_FAILURE='UPDATE_REQUEST_FAILURE';
export const UPDATE_REQUEST_SUCCESS='UPDATE_REQUEST_SUCCESS';
export const TOGGLE_SELF_REQUEST_CHECKBOX='TOGGLE_SELF_REQUEST_CHECKBOX';
export const SORT_REQUESTS='SORT_REQUESTS';
export const REQUEST_TABLE_COLUMNS_CHANGED='REQUEST_TABLE_COLUMNS_CHANGED';
export const ACKNOWLEDGE_PENDING_MANAGER_REQUESTS='ACKNOWLEDGE_PENDING_MANAGER_REQUESTS';
export const FETCH_REQUEST_MANAGER='FETCH_REQUEST_MANAGER';
export const FETCH_REQUEST_MANAGER_SUCCESS='FETCH_REQUEST_MANAGER_SUCCESS';
export const FETCH_REQUEST_MANAGER_FAILURE='FETCH_REQUEST_MANAGER_FAILURE';
/****************************** REQUEST FILTER TYPES ***********************************/
export const REQUEST_FILTER_EMPLOYEE_NAME_CHANGED='REQUEST_FILTER_EMPLOYEE_NAME_CHANGED';
export const REQUEST_FILTER_EMPLOYEE_EMAIL_CHANGED='REQUEST_FILTER_EMPLOYEE_EMAIL_CHANGED';
export const REQUEST_FILTER_START_DATE_CHANGED='REQUEST_FILTER_START_DATE_CHANGED';
export const REQUEST_FILTER_END_DATE_CHANGED='REQUEST_FILTER_END_DATE_CHANGED';
export const REQUEST_FILTER_REQUEST_TYPE_CHANGED='REQUEST_FILTER_REQUEST_TYPE_CHANGED';
export const REQUEST_FILTER_REQUEST_STATUS_CHANGED='REQUEST_FILTER_REQUEST_STATUS_CHANGED';
export const FILTER_REQUESTS_ATTEMPT='FILTER_REQUESTS_ATTEMPT';
export const FILTER_REQUESTS_COMPLETE='FILTER_REQUESTS_COMPLETE';
export const CLEAR_REQUESTS_FILTERS='CLEAR_REQUESTS_FILTERS';
/*********************** REQUEST COMMENT TYPES *************************/
export const SUBMIT_REJECTION_COMMENT_ATTEMPT='SUBMIT_REJECTION_COMMENT_ATTEMPT';
export const SUBMIT_REJECTION_COMMENT_SUCCESS='SUBMIT_REJECTION_COMMENT_SUCCESS';
export const SUBMIT_REJECTION_COMMENT_FAILURE='SUBMIT_REJECTION_COMMENT_FAILURE';
export const FETCH_REQUEST_COMMENTS_ATTEMPT='FETCH_REQUEST_COMMENTS_ATTEMPT';
export const FETCH_REQUEST_COMMENTS_SUCCESS='FETCH_REQUEST_COMMENTS_SUCCESS';
export const FETCH_REQUEST_COMMENTS_FAILURE='FETCH_REQUEST_COMMENTS_FAILURE';
export const TOGGLE_DISPLAY_COMMENT_DIALOG='TOGGLE_DISPLAY_COMMENT_DIALOG';
/************************* REQUEST TABLE TYPES ******************************/
export const TOGGLE_CONFIRM_UPDATE_DIALOG='TOGGLE_CONFIRM_UPDATE_DIALOG';
/************************** FULFILL RECORD TYPES ***************************/
export const ORDER_DATE_CHANGED='ORDER_DATE_CHANGED';
export const RECIEVE_STATUS_CHANGED='RECIEVE_STATUS_CHANGED';
export const RECIEVE_DATE_CHANGED='RECIEVE_DATE_CHANGED';
export const DELIVERY_STATUS_CHANGED='DELIVERY_STATUS_CHANGED';
export const DELIVERY_DATE_CHANGED='DELIVERY_DATE_CHANGED';
export const VENDOR_NAME_CHANGED='VENDOR_NAME_CHANGED';
export const PURCHASE_ORDER_NUMBER_CHANGED='PURCHASE_ORDER_NUMBER_CHANGED';
export const FETCH_FULFILL_RECORD_ATTEMPT='FETCH_FULFILL_RECORD_ATTEMPT';
export const FETCH_FULFILL_RECORD_SUCCESS='FETCH_FULFILL_RECORD_SUCCESS';
export const FETCH_FULFILL_RECORD_FAILURE='FETCH_FULFILL_RECORD_FAILURE';
export const UPDATE_FULFILL_RECORD_ATTEMPT='UPDATE_FULFILL_RECORD_ATTEMPT';
export const UPDATE_FULFILL_RECORD_SUCCESS='UDPATE_FULFILL_RECORD_SUCCESS';
export const UPDATE_FULFILL_RECORD_FAILURE='UPDATE_FULFILL_RECORD_FAILURE';
export const FULFILL_RECORD_MESSAGE_CHANGED='FULFILL_RECORD_MESSAGE_CHANGED';
/****************************** REQUEST ATTACHMENT TYPES ************************/
export const REQUEST_ATTACHMENT_GET_SAS_URI='REQUEST_ATTACHMENT_GET_SAS_URI';
export const REQUEST_ATTACHMENT_GET_SAS_URI_SUCCESS='REQUEST_ATTACHMENT_GET_SAS_URI_SUCCESS';
export const REQUEST_ATTACHMENT_GET_SAS_URI_FAILURE='REQUEST_ATTACHMENT_GET_SAS_URI_FAILURE';
export const REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT='REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT';
export const REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_SUCCESS='REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_SUCCESS';
export const REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_FAILURE='REQUEST_ATTACHMENT_UPLOAD_ATTACHMENT_FAILURE';
export const REQUEST_ATTACHMENT_FILE_CHANGED='REQUEST_ATTACHMENT_FILE_CHANGED';
export const REQUEST_ATTACHMENT_DOWNLOAD='REQUEST_ATTACHMENT_DOWNLOAD';
export const REQUEST_ATTACHMENT_DOWNLOAD_FAILURE='REQUEST_ATTACHMENT_DOWNLOAD_FAILURE';
export const REQUEST_ATTACHMENT_DOWNLOAD_SUCCESS='REQUEST_ATTACHMENT_DOWNLOAD_SUCCESS';
export const REQUEST_ATTACHMENT_FILE_CHANGED_ERROR='REQUEST_ATTACHMENT_FILE_CHANGED_ERROR';
/*********************** APP ROLE AND USER TYPES *************************/
export const APP_GET_USERS='APP_GET_USERS';
export const APP_GET_USERS_SUCCESS='APP_GET_USERS_SUCCESS';
export const APP_GET_USERS_FAILURE='APP_GET_USERS_FAILURE';
export const APP_GET_ROLES='APP_GET_ROLES';
export const APP_GET_ROLES_SUCCESS='APP_GET_ROLES_SUCCESS';
export const APP_GET_ROLES_FAILURE='APP_GET_ROLES_FAILURE';
export const APP_ASSIGN_USER_ROLE='APP_ASSIGN_USER_ROLE';
export const APP_ASSIGN_USER_ROLE_SUCCESS='APP_ASSIGN_USER_ROLE_SUCCESS';
export const APP_ASSIGN_USER_ROLE_FAILURE='APP_ASSIGN_USER_ROLE_FAILURE';
export const APP_ADD_USER_ROLE='APP_ADD_USER_ROLE';
export const APP_ADD_USER_ROLE_SUCCESS='APP_ADD_USER_ROLE_SUCCESS';
export const APP_ADD_USER_ROLE_FAILURE='APP_ADD_USER_ROLE_FAILURE';
export const APP_EDIT_USER_ROLE='APP_EDIT_USER_ROLE';
export const APP_CANCEL_EDIT_USER_ROLE='APP_CANCEL_EDIT_USER_ROLE';
export const APP_APP_USER_CHANGED='APP_APP_USER_CHANGED';
export const APP_APP_ROLE_CHANGED='APP_APP_ROLE_CHANGED';
export const APP_APP_USER_INPUT_CHANGED='APP_APP_USER_INPUT_CHANGED';
export const APP_TOGGLE_DELETE_DIALOG='APP_TOGGLE_DELETE_DIALOG';
export const APP_DELETE_USER_ROLE='APP_DELETE_USER_ROLE';
export const APP_DELETE_USER_ROLE_SUCCESS='APP_DELETE_USER_ROLE_SUCCESS';
export const APP_DELETE_USER_ROLE_FAILURE='APP_DELETE_USER_ROLE_FAILURE';