import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon'
import Avatar from '@material-ui/core/Avatar';

export default function AccountDisplay(props) {
    return (
        <Grid container direction='row'
            wrap='wrap'
            justify='center'
            alignContent='center'
            alignItems='center'
            spacing={2}
            className='px-1 py-2'
        >
            <Grid item xs={12} sm={12} md={3} lg={10}>
                <Avatar style={{backgroundColor: '#005bea', margin: 'auto', background: 'linear-gradient(to top, #00c6fb 0%, #005bea 100%)', width: '200px', height: '200px'}}>
                    <Typography variant='h4'>{props.account.givenName.charAt(0)}{props.account.surname.charAt(0)}</Typography>
                </Avatar>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={12}>
                <Typography variant='h5' gutterBottom>
                    <Icon className='va-middle'>person</Icon> {props.account.displayName}
                </Typography>
                <Typography variant='h5' gutterBottom>
                    <Icon className='va-middle'>email</Icon> {props.account.userPrincipalName}
                </Typography>
                <Typography variant='body2' gutterBottom>
                    <Icon className='va-middle'>business_center</Icon> {props.account.jobTitle}
                </Typography>
                <Typography variant='body2' gutterBottom>
                    <Icon className='va-middle'>business</Icon> {props.account.officeLocation}
                </Typography>
                <Typography variant='body2' gutterBottom>
                    <Icon className='va-middle'>phone</Icon> {props.account.businessPhones[0]}
                </Typography>
                <Typography variant='body1' gutterBottom>
                    <Icon className='va-middle'>supervisor_account</Icon> Manager: {props.account.manager.displayName} ({props.account.manager.userPrincipalName})
                </Typography>
            </Grid>
        </Grid>
    )
}
