//The list of requests made, pending, etc.
import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RequestsTable from './RequestsTable';
import {connect} from 'react-redux';
import {
    fetchRequestsAttempt,
    changeRequestTableTab
} from '../Actions/Request';
import {
    requestFilterStatusChanged
} from '../Actions/RequestFilters';
import RequestsFilters from './RequestsFilters';
import Typography from '@material-ui/core/Typography';
import StatusConfirmationDialog from './StatusConfirmationDialog';
import { SUBMITTED, DM_APPROVED, SM_APPROVED, APP_DIVISION_MANAGER, APP_DEVELOPER, APP_FULFILLER } from '../Helpers/Enums';
import { authContext } from '../Helpers/adalAuthentication';
import RequestColSelectionBar from './RequestColSelectionBar';
class Requests extends Component{
    componentDidMount(){
        let role=this.getRoleStrValue(this.props.requestTableTab);
        let data = {
            role
        };
        this.props.fetchRequestsAttempt(data);
    }
    handleChangeTableTab = (e, tabValue) => {
        let role=this.getRoleStrValue(tabValue);
        let data = {
            role,
            tab: tabValue
        }
        this.props.changeRequestTableTab(data);
    }
    getRoleStrValue = (tabValue) => {
        switch(tabValue){
            case 0:
                return 'me';
            case 1:
                return 'manager';
            case 2: 
                return 'fulfiller';
            case 3:
                return 'all';
            case 4:
                return 'pending';
            default: 
                return 'me';
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.pendingManagerRequests && prevProps.requestTableTab !== 1 && this.props.requestTableTab !== 1 && !this.props.usersLoading && !this.props.pendingFulfillerRequests){
            this.props.changeRequestTableTab({
                role: 'manager',
                tab: 1
            });
            let filterArr = [SUBMITTED.value];
            let accountRole = authContext.getCachedUser().profile.roles[0];
            if(accountRole === APP_DIVISION_MANAGER){
                filterArr = [SM_APPROVED.value]
            }else if(accountRole === APP_DEVELOPER){
                filterArr = [SUBMITTED.value, SM_APPROVED.value];
            }
            this.props.requestFilterStatusChanged(filterArr);
        }
        else if(this.props.pendingFulfillerRequests && prevProps !== 2 && this.props.requestTableTab !== 2 && !this.props.usersLoading){
            this.props.changeRequestTableTab({
                role: 'fulfiller',
                tab: 2
            });
            this.props.requestFilterStatusChanged([DM_APPROVED.value])
        }
    }
    render(){
        const accountRole = authContext.getCachedUser().profile.roles[0];
        return(
            <Grid
                container
                spacing={2}
                alignContent='flex-start'
                alignItems='flex-start'
                justify='center'
                direction='row'
                wrap='wrap'
            >
                <Grid item xs={12}>
                    <Typography variant='h4' gutterBottom align='center'>MyRequest System</Typography>
                </Grid>
                <Grid item xs={12} sm={11} md={10} lg={8}>
                    <AppBar position='static' color='default' component='div'>
                        <Tabs aria-label='Requests Tab' value={this.props.requestTableTab} onChange={this.handleChangeTableTab.bind(this)} variant='scrollable' textColor='primary' indicatorColor='primary' TabIndicatorProps={{style: {height: '0.25rem'}}}>
                            <Tab label='My Requests' value={0} />
                            {
                                accountRole === APP_DEVELOPER ?
                                <Tab label='All' value={3} />
                                :
                                null
                            }
                            {
                                this.props.canViewManagerTab ?
                                <Tab label='Managed by Me' value={1} />
                                :
                                null
                            }
                            {
                                this.props.canViewFulfillerTab ? 
                                <Tab label='Fulfilled by Me' value={2} />
                                :
                                null
                            }
                            {
                                accountRole === APP_DIVISION_MANAGER || accountRole === APP_FULFILLER ?
                                <Tab label='Pending Requests' value={4} />
                                :
                                null
                            }
                        </Tabs>
                    </AppBar>
                    <Paper className="pt-1 px-1" style={{overflowX: 'auto'}} square elevation={6}>
                        <RequestColSelectionBar />
                        <RequestsTable />
                    </Paper>
                </Grid>
                <Grid item xs={11} sm={11} md={10} lg={3}>
                    <RequestsFilters />
                </Grid>
                <StatusConfirmationDialog />
            </Grid>
        );
    }
}
const mapStateToProps = (state) => ({
    requestTableTab: state.request.requestTableTab,
    canViewManagerTab: state.account.canViewManagerTab,
    canViewFulfillerTab: state.account.canViewFulfillerTab,
    pendingManagerRequests: state.request.pendingManagerRequests,
    pendingFulfillerRequests: state.request.pendingFulfillerRequests,
    usersLoading: state.account.usersLoading,
    account: state.account.account
});
export default connect(mapStateToProps, {
    fetchRequestsAttempt,
    changeRequestTableTab,
    requestFilterStatusChanged
})(Requests);