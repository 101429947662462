import AuthenticationContext from 'adal-angular';
import {isUndefinedOrNullOrEmpty} from './helperFunctions';
const handleAdalRedirect = (errDesc, token, error, tokenType) => {
    if(error){
        console.error(errDesc);
        authContext.login();
    }else{
        localStorage.setItem('authenticationToken', token);
    }
};
let port='';
if(!isUndefinedOrNullOrEmpty(window.location.port)){
    port = `:${window.location.port}`;
}
let redirectUri =  `${window.location.protocol}//${window.location.hostname}${port}/login`; 
const endpoints = {};
endpoints[`${process.env.REACT_APP_API_URL}`] = process.env.REACT_APP_API_CLIENT_ID;
const adalConfig = {
    clientId: process.env.REACT_APP_API_CLIENT_ID,
    popUp: false,
    cacheLocation: 'localStorage',
    redirectUri: redirectUri,
    postLogoutRedirectUri: redirectUri,
    navigateToLoginRequestUrl: false,
    callback: handleAdalRedirect,
    tenant: process.env.REACT_APP_TENANT_ID,
    loadFrameTimeout: 25000,
    endpoints: endpoints
};
export const authContext = new AuthenticationContext(adalConfig);
authContext.isAngular = false;