import React, { Component } from 'react'
import {connect} from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import {
    toggleDisplayDialog,
    submitRejectionComment,
} from '../Actions/RequestComments';
import {
    updateRequestAttempt
} from '../Actions/Request';
import {
    REJECTED
} from '../Helpers/Enums'
import { isUndefinedOrNullOrEmpty } from '../Helpers/helperFunctions';
import LinearProgress from '@material-ui/core/LinearProgress';
class CommentForRequestDialog extends Component {
    state = {
        commentBody: ''
    }
    onClickCancel = (e)=>{
        this.props.toggleDisplayDialog(!this.props.displayCommentDialog)
    }
    onClickSubmitComment = (e) =>{
        if(!isUndefinedOrNullOrEmpty(this.state.commentBody)){
            let requestData = {
                id: this.props.request.Id,
                employee: this.props.request.Employee,
                manager: this.props.request.DivisionManager,
                fulfiller: this.props.request.Fulfiller,
                requestType: this.props.request.RequestType,
                description: this.props.request.Description,
                submitted: this.props.request.Submitted,
                requestStatus: REJECTED.value,
                justification: this.props.request.Justification,
                canUpdate: this.props.request.CanUpdate
            }
            this.props.submitRejectionComment({
                commentBody: this.state.commentBody,
                requestId: this.props.request.Id
            });
            this.props.updateRequestAttempt(requestData);
        }
    }
    commentBodyChanged = (e)=>{
        this.setState({
            ...this.state,
            commentBody: e.target.value
        })
    }
    render() {
        return (
            <Dialog 
                open={this.props.displayCommentDialog}
                aria-labelledby='alert-dialog-title'
                aria-describedby='aria-dialog-description'
                PaperProps={{square: true}} 
            >
                {
                    this.props.submittingComment || this.props.updatingRequest ? 
                    <LinearProgress variant='query' />
                    : null
                }
                <DialogTitle id='alter-dialog-title'>Rejection Comment</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Please provide a comment describing why you are rejecting this request.
                    </DialogContentText>
                    <TextField error={this.props.commentError} 
                        disabled={this.props.submittingComment || this.props.updatingRequest} 
                        variant='outlined' multiline rows={5} fullWidth 
                        onChange={this.commentBodyChanged.bind(this)} />
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' 
                        onClick={this.onClickSubmitComment.bind(this)}
                    >
                        Continue
                    </Button>
                    <Button color='primary' 
                        onClick={this.onClickCancel.bind(this)}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
const mapStateToProps = (state)=>({
    submittingComment: state.requestComments.submittingComment,
    displayCommentDialog: state.requestComments.displayCommentDialog,
    request: state.request.request,
    commentError: state.requestComments.commentError,
    updatingRequest: state.request.updatingRequest
})
export default connect(mapStateToProps,{
    toggleDisplayDialog,
    submitRejectionComment,
    updateRequestAttempt
})(CommentForRequestDialog);