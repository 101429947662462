import React, {Component} from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {
    getAppRoles,
    getAppUsers
} from '../Actions/Admin';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import RoleAssignmentsTable from './Admin/RoleAssignmentsTable';
import {authContext} from '../Helpers/adalAuthentication'
import { APP_DEVELOPER } from '../Helpers/Enums';
import {withRouter} from 'react-router-dom';
import UserRoleEditDialog from './Admin/UserRoleEditDialog';
import UserRoleAddForm from './Admin/UserRoleAddForm';

class Admin extends Component{
    componentDidMount(){
        const userRole = authContext.getCachedUser().profile.roles[0];
        if(userRole !== APP_DEVELOPER){
            this.props.history.push('/');
        }else{
            this.props.getAppRoles();
            this.props.getAppUsers();
        }
    }
    render(){
        return(
            <React.Fragment>
                <UserRoleEditDialog />
                <Grid
                    container
                    direction='row'
                    wrap='wrap'
                    justify='center'
                    alignContent='flex-start'
                    alignItems='flex-start'
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <Typography variant='h3' align='center' gutterBottom>
                            Application Users
                        </Typography>
                    </Grid>
                    {
                        this.props.fetchingRoles || this.props.fetchingUsers ?
                        <Grid item xs={12}>
                            <CircularProgress color='primary' size={36} />
                        </Grid>
                        :
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Paper style={{padding: 16}}>
                                    <UserRoleAddForm />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} >
                                <Paper style={{height: 800, width: '100%'}}>
                                    <RoleAssignmentsTable />
                                </Paper>
                            </Grid>   
                        </React.Fragment>
                    }
                </Grid>

            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    fetchingRoles: state.admin.fetchingRoles,
    fetchingUsers: state.admin.fetchingUsers
});
const mapStateToDispatch = {
    getAppRoles,
    getAppUsers
}

export default connect(mapStateToProps, mapStateToDispatch)(withRouter(Admin));