import React,{Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import {
    requestDescriptionChanged
} from '../../Actions/Request';
import { isUndefinedOrNullOrEmpty } from '../../Helpers/helperFunctions';
import Tooltip from '@material-ui/core/Tooltip';
class RequestDescription extends Component{
    onDescriptionChange(e){
        this.props.requestDescriptionChanged(e.target.value);
    }
    render(){
        return(
            <Grid
                item
                xs={12}
            >
                <Tooltip
                    placement='top'
                    title='A detailed description of the item being request. For example: brand, model, year.'
                >
                    <TextField
                        label='Description of Need'
                        margin='normal'
                        fullWidth
                        multiline={true}
                        variant='filled'
                        rows={5}
                        value={this.props.description}
                        onChange={this.onDescriptionChange.bind(this)}
                        error={isUndefinedOrNullOrEmpty(this.props.errors.description) ? false : true}
                        helperText={this.props.errors.description}
                        disabled={this.props.submittingRequest}
                    />
                </Tooltip>
            </Grid>
        );
    }
}
const mapStateToProps = (state)=>({
    description: state.request.description,
    errors: state.request.errors,
    submittingRequest: state.request.submittingRequest
})
export default connect(mapStateToProps,{
    requestDescriptionChanged
})(RequestDescription);