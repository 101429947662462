import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    fetchRequestComments
} from '../Actions/RequestComments';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider'
import { getRequestStatusName } from '../Helpers/helperFunctions';

class RequestCommentsSection extends Component {
    componentDidMount(){
        this.props.fetchRequestComments({requestId: this.props.request.Id});
    }
    render() {
        return (
            <Grid item xs={11} md={12} lg={8}>
                <Paper className='py-2' square elevation={2}>
                    {
                        this.props.fetchingComments ? 
                        <Grid container direction='row' alignContent='center' alignItems='center' justify='center'>
                            <CircularProgress size={50}  thickness={5} color='primary' />
                        </Grid>
                        :
                        <React.Fragment>
                            <Typography variant='h5' align='center'>Comments</Typography>
                            {
                                this.props.comments.length === 0 
                                ? 
                                <Typography variant='body2' color='textSecondary'>
                                    There are no comments for this request.
                                </Typography>
                                :
                                <List>
                                <Divider component='li' />
                                {
                                    this.props.comments.map((comment)=>(
                                        <ListItem style={{borderBottom: '1px solid #CCC'}} key={comment.Id}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant='body2'>
                                                        {`On ${new Date(comment.DateCreated).toLocaleDateString()} ${comment.CommentatorName} set the request status to ${getRequestStatusName(comment.RequestStatus)} and said:`}
                                                    </Typography>
                                                } 
                                                secondary={
                                                    <Typography variant='body1' color='textPrimary'>
                                                        {comment.CommentBody}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>
                                    ))
                                }
                            </List>
                            }
                        </React.Fragment>
                    }
                </Paper>
            </Grid>
        )
    }
}
const mapStateToProps = (state)=>({
    request: state.request.request,
    comments: state.requestComments.comments,
    fetchingComments: state.requestComments.fetchingComments
});
export default connect(mapStateToProps,{
    fetchRequestComments
})(RequestCommentsSection)