import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotificationSettings from './UserSettingsControls/NotificationSettings';
import AccountDisplay from './UserSettingsControls/AccountDisplay'
import {connect} from 'react-redux';
import {
    fetchAccountSettings,
    updateAccountSettings,
    notificationFrequencyOptionChanged
} from '../Actions/Account'
import { isUndefinedOrNullOrEmpty } from '../Helpers/helperFunctions';
class UserSettings extends Component {
    componentDidMount(){
        if(isUndefinedOrNullOrEmpty(this.props.accountSettings)){
            this.props.fetchAccountSettings(true);
        }else{
            this.props.notificationFrequencyOptionChanged(this.props.accountSettings.FrequencyOption)
        }
    }
    onClickUpdateSettings = (e) => {
        this.props.updateAccountSettings({
            userId: this.props.account.id,
            frequencyOption: Number(this.props.notificationSetting)
        })
    }
    render() {
        return (
            <Grid
                container
                direction='row'
                wrap='wrap'
                justify='center'
                alignContent='flex-start'
                alignItems='flex-start'
                spacing={2}
            >
                <Grid item xs={12} sm={11} md={10} lg={8}>
                        <Grid container 
                            direction='row' 
                            wrap='wrap'
                            justify='center'
                            alignContent='center'
                            alignItems='flex-start'
                            spacing={4}
                        >
                            <Grid item xs={12}>
                                <Typography variant='h4' align='center'>
                                    Account Settings
                                </Typography>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper className='w-100 py-1' square>
                                    {
                                        this.props.fetchingAccount ?
                                        <CircularProgress style={{margin: 'auto'}} size={50} thickness={5} />
                                        :
                                        <AccountDisplay account={this.props.account} />
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper className='w-100 py-1' square>
                                    {
                                        this.props.fetchingAccountSettings ? 
                                        <CircularProgress style={{margin: 'auto'}} size={50} thickness={5} />
                                        :
                                        <NotificationSettings />
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={this.props.updatingAccountSettings} variant='contained' color='primary' onClick={this.onClickUpdateSettings.bind(this)}>
                                    {
                                        this.props.updatingAccountSettings ? 
                                        <CircularProgress color='primary' size={36} thickness={7} />
                                        :
                                        <React.Fragment>SAVE&nbsp;<Icon>save</Icon></React.Fragment>
                                    }
                                </Button>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state) => ({
    account: state.account.account,
    fetchingAccount: state.account.fetchingAccount,
    accountSettings: state.account.accountSettings,
    fetchingAccountSettings: state.account.fetchingAccountSettings,
    accountSettingsErr: state.account.accountSettingsErr,
    updatingAccountSettings: state.account.updatingAccountSettings,
    notificationSetting: state.account.notificationSetting
});
export default connect(mapStateToProps, {
    fetchAccountSettings,
    updateAccountSettings,
    notificationFrequencyOptionChanged
})(UserSettings);