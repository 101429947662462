import {combineReducers} from 'redux';
import Account from './Account';
import Request from './Request';
import RequestComments from './RequestComments';
import FulfillRecord from './FulfillRecord';
import RequestAttachment from './RequestAttachment'
import Admin from './Admin';
export default combineReducers({
    admin: Admin,
    account: Account,
    request: Request,
    requestComments: RequestComments,
    fulfillRecord: FulfillRecord,
    requestAttachment: RequestAttachment
});