import React, { Component } from 'react'
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
    SUBMITTED,
    IN_PROGRESS,
    COMPLETED,
    DM_APPROVED,
    SM_APPROVED
} from '../../Helpers/Enums';
import {
    updateRequestAttempt
} from '../../Actions/Request';
import { getRequestStatusName } from '../../Helpers/helperFunctions';

class PositiveButton extends Component {
    onClickProgressRequest = (e) => {
        let statusVal = SM_APPROVED.value;
        const currentStatus = this.props.request.RequestStatus;
        switch(currentStatus){
            case SUBMITTED.value:
                statusVal = SM_APPROVED.value;
                break;
            case SM_APPROVED.value:
                statusVal = DM_APPROVED.value;
                break;
            case DM_APPROVED.value:
                statusVal = IN_PROGRESS.value;
                break;
            case IN_PROGRESS.value:
                statusVal = COMPLETED.value;
                break;
            default: 
                break;
        }
        let data = {
            id: this.props.request.Id,
            employee: this.props.request.Employee,
            manager: this.props.request.DivisionManager,
            fulfiller: this.props.request.Fulfiller,
            requestType: this.props.request.RequestType,
            description: this.props.request.Description,
            submitted: this.props.request.Submitted,
            justification: this.props.request.Justification,
            requestStatus: statusVal,
            canUpdate: this.props.request.CanUpdate           
        }
        this.props.updateRequestAttempt(data);
    }
    render() {
        let buttonText = 'Approve';
        if(this.props.request.RequestStatus === DM_APPROVED.value){
            buttonText = 'In Progress';
        }
        const permissionCheck = 
            (this.props.request.RequestStatus === SUBMITTED.value && this.props.request.SectionManager.id === this.props.account.id) ||
            (this.props.request.RequestStatus === SM_APPROVED.value && this.props.request.DivisionManager.id === this.props.account.id) ||
            (this.props.request.RequestStatus === DM_APPROVED.value && this.props.request.Fulfiller.id === this.props.account.id);
        return (
            <React.Fragment>
                {
                    permissionCheck ?
                    <Button className='m-btn' disabled={this.props.submittingRequest} 
                        color='primary' variant='contained'
                        onClick={this.onClickProgressRequest.bind(this)}>
                        {buttonText}
                    </Button>
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
const mapStateToProps = state =>({
    request: state.request.request,
    account: state.account.account,
    submittingRequest: state.request.submittingRequest
})
export default connect(mapStateToProps,{
    updateRequestAttempt
})(PositiveButton);