import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
    palette: {
        primary: green
    }
});

export default function HomeLink(props){
    return(
        <MuiThemeProvider theme={theme}>
            <Button
                size='small'
                variant='text'
                color='primary'
                onClick={(e) => props.history.push('/')}
            >
                Home
            </Button>
        </MuiThemeProvider>
    )
}