import React, {Component} from 'react';
import {connect} from 'react-redux'
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import RequestData from './RequestData';
import {
    fetchRequestAttempt
} from '../Actions/Request';
import RequestForm from './RequestForm';
class Request extends Component{
    componentDidMount(){
        this.props.fetchRequestAttempt(this.props.match.params);
        //console.log(this.props.match.params);
    }
    render(){
        return(
            <React.Fragment>
                {
                    this.props.editingRequest ? 
                    <RequestForm />
                    :
                    <React.Fragment>
                        
                            {
                                this.props.fetchingRequest ? 
                                <Paper className='py-3'>
                                    <CircularProgress size={60} thickness={5} color='primary' />
                                </Paper>
                                :
                                <RequestData />
                            }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    request: state.request.request,
    requestError: state.request.requestError,
    requestMessage: state.request.requestMessage,
    fetchingRequest: state.request.fetchingSingleRequest,
    editingRequest: state.request.editingRequest
});
export default connect(mapStateToProps, {
    fetchRequestAttempt
})(Request);