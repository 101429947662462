import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EmployeeField from './RequestFormFields/Employee';
import RequestType from './RequestFormFields/RequestType';
import Manager from './RequestFormFields/Manager';
import Justification from './RequestFormFields/Justification';
import RequestDescription from './RequestFormFields/RequestDescription';
import Snackbar from '@material-ui/core/Snackbar';
import {connect} from 'react-redux';
import RequestFulfiller from './RequestFormFields/RequestFulfiller';
import {
    resetRequestMessageSnackbar,
    cancelEditRequest
} from '../Actions/Request';
import { withRouter } from 'react-router-dom';
import { isUndefinedOrNullOrEmpty } from '../Helpers/helperFunctions';
import UpdateButtonControls from './RequestFormFields/UpdateButtonControls';
import CreateButtonControls from './RequestFormFields/CreateButtonControls';
import RequestSelfCheckbox from './RequestFormFields/RequestSelfCheckbox';
import ReadOnlyManager from './RequestFormFields/ReadOnlyManager';
import HomeLink from './RequestFormSnackbarButtons/HomeLink';
import RequestAttachment from './RequestFormFields/Attachment';

class RequestForm extends Component{
    handleCloseRequestMessageSnackbar = (e) => {
        this.props.resetRequestMessageSnackbar('');
    }
    handleViewNewRequest = (e) => {
        this.props.resetRequestMessageSnackbar('');
        this.props.cancelEditRequest(false);
        this.props.history.push(`/requests/${this.props.requestMessageAction}`);
    }
    render(){
        const snackbarActions = isUndefinedOrNullOrEmpty(this.props.requestMessageAction) ? 
            [
                <Button key={2} size='small' variant='text' color='secondary' onClick={this.handleCloseRequestMessageSnackbar.bind(this)}>
                    Close
                </Button>
            ]
            :
            [
                <Button key={1} size='small' variant='text' color='primary' onClick={this.handleViewNewRequest.bind(this)}>
                    View
                </Button>,
                <Button key={2} size='small' variant='text' color='secondary' onClick={this.handleCloseRequestMessageSnackbar.bind(this)}>
                    Close
                </Button>,
                <HomeLink key={3}  history={this.props.history}/>
            ];
        return(
            <Grid 
                container
                direction='row'
                spacing={2}
                wrap='wrap'
                justify='center'
                alignContent='flex-start'
                alignItems='flex-start'
            >
                <Grid 
                    item
                    xs={11}
                    sm={11}
                    md={10}
                    lg={8}
                >
                    <Grid 
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                        className='py-1'
                    >
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center'
                            }} 
                            open={!isUndefinedOrNullOrEmpty(this.props.requestMessage)}
                            autoHideDuration={30000}
                            onClose={this.handleCloseRequestMessageSnackbar.bind(this)}
                            message={this.props.requestMessage}
                            action={snackbarActions}

                        ></Snackbar>
                        <Typography align='center' 
                            variant='h4' 
                            gutterBottom
                            color='textPrimary'
                        >
                            {
                                this.props.editingRequest ? 
                                'Edit Request'
                                :
                                'Create A New Request'
                            }
                        </Typography>
                    </Grid>
                    <Paper 
                        className='py-1 px-1'
                        square={true}
                        elevation={10}
                    >
                        <Grid 
                            direction='row'
                            spacing={4}
                            component='form' 
                            container 
                            alignItems='center' 
                            justify='center' 
                            method='POST' 
                            noValidate 
                            autoComplete='off'
                            className='py-1'
                        >
                            {
                                this.props.editingRequest ? 
                                null
                                :
                                <RequestSelfCheckbox />
                            }
                            {
                                this.props.roles[0] === 'Fulfiller' && !this.props.editingRequest ?
                                <Grid item xs={12}>
                                    <Typography variant='body2' align='center'>
                                        You are a fulfiller, so your request will go straight to your division manager.
                                    </Typography>
                                </Grid>
                                :
                                null
                            }
                            <RequestType />
                            <EmployeeField />
                            {
                                this.props.editingRequest ?
                                <React.Fragment>
                                    <ReadOnlyManager 
                                        data={this.props.request.SectionManager} 
                                        label='Section Manager' 
                                    />
                                    <ReadOnlyManager 
                                        data={this.props.request.DivisionManager}
                                        label='Division Manager'
                                    />
                                </React.Fragment>
                                :
                                <Manager />
                            }
                            <RequestFulfiller />
                            <Justification />
                            <RequestDescription />
                            <RequestAttachment />
                            {
                                this.props.roles[0] === 'DivisionManager' ?
                                <Typography style={{paddingBottom: '2rem'}} align='center' variant='body2' color='secondary'>
                                    You do not have permission to submit a request. Only section managers and their employees are allowed to make requests.
                                </Typography>
                                :
                                <React.Fragment>
                                    {
                                        this.props.editingRequest ?
                                        <UpdateButtonControls />
                                        :
                                        <CreateButtonControls />
                                    }
                                </React.Fragment>
                            }
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = (state)=>({
    account: state.request.account,
    request: state.request.request,
    requestDate: state.request.date,
    manager: state.request.manager,
    employee: state.request.employee,
    fulfiller: state.request.fulfiller,
    description: state.request.description,
    requestType: state.request.requestType,
    users: state.account.users,
    usersLoading: state.account.usersLoading,
    employees: state.account.employees,
    fulfillers: state.account.fulfillers,
    fulfillersLoading: state.account.fulfillersLoading,
    submittingRequest: state.request.submittingRequest,
    requestMessage: state.request.requestMessage,
    requestMessageAction: state.request.requestMessageAction,
    editingRequest: state.request.editingRequest,
    roles: state.account.roles
});
export default withRouter(connect(mapStateToProps,{
    resetRequestMessageSnackbar,
    cancelEditRequest
})(RequestForm));