import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    appAssignUserRole
} from '../../../Actions/Admin';
import Button from '@material-ui/core/Button'

class SaveButton extends Component {
    handleClick = (e) => {
        this.props.appAssignUserRole({
            userId: this.props.appUser.principalId,
            appRoleId: this.props.appUser.appRoleId
        })
    }
    render(){
        return(
            <Button variant='contained' color='primary' onClick={this.handleClick} disabled={this.props.assigning}>
                Save
            </Button>
        );
    }
}
const mapStateToProps = (state) => ({
    appUser: state.admin.appUser,
    assigning: state.admin.assigning
})

const mapDispatchToProps = {
    appAssignUserRole
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveButton)