/* Request Status Enums */
export const SUBMITTED={
    name: 'Submitted',
    value: 0
};
export const SM_APPROVED = {
    name: 'Section Manager Approved',
    value: 1
};
export const DM_APPROVED = {
    name: 'Division Manager Approved',
    value: 2
};
export const IN_PROGRESS={
    name: 'In Progress',
    value: 3
};
export const COMPLETED = {
    name: 'Completed',
    value: 4
};
export const REJECTED = {
    name: 'Rejected',
    value: 5
};

/* User Settings Enums */
export const ALWAYS = {
    name: 'Always',
    value: 0,
    helperText: 'You will always receive a notification when an item request is updated and the updated state of that request is pertinent to your role.'
}
export const DAILY = {
    name: 'Daily',
    value: 1,
    helperText: 'You will receive your list of item request notifications at the end of each day.'
}
export const WEEKLY = {
    name: 'Weekly',
    value: 2,
    helperText: 'You will receive your list of item request notifications at the end of each week.'
}
export const BIMONTHLY = {
    name: 'Bi-Monthly',
    value: 3,
    helperText: 'You will receive your list of item request notifications each calendar month on the 1st and 16th.'
}
export const MONTHLY = {
    name: 'Monthly',
    value: 4,
    helperText: 'You will receive your list of item request notifications on the first day of each calendar month.'
}
export const NEVER = {
    name: 'Never',
    value: 5,
    helperText: 'You will never receive email notifications for this system.'
}

/* Request Table Columns */
export const TABLE_COL_EMPLOYEE = {
    name: 'Employee',
    value: 'EmployeeName',
    type: 'string'
}
export const TABLE_COL_REQUESTOR={
    name: 'Requestor',
    value: 'RequestorName',
    type: 'string'
}
export const TABLE_COL_FULFILLER={
    name: 'Fulfiller',
    value: 'FulfillerName',
    type: 'string'
}
export const TABLE_COL_SUBMITTED={
    name: 'Date Created',
    value: 'Submitted',
    type: 'date'
}
export const TABLE_COL_LAST_UPDATED={
    name: 'Last Updated',
    value: 'LastUpdated',
    type: 'date'
}
export const TABLE_COL_REQUEST_TYPE={
    name: 'Request Type',
    value: 'RequestType',
    type: 'string'
}
export const TABLE_COL_SECTION_MANAGER={
    name: 'Section Manager',
    value: 'SectionManagerName',
    type: 'string'
}
export const TABLE_COL_DIVISION_MANAGER={
    name: 'Division Manager',
    value: 'DivisionManagerName',
    type: 'string'
}
export const TABLE_COL_COMPLETED_DATE={
    name: 'Completed Date',
    value: 'CompletedDate',
    type: 'date'
}

/* App Role Enums*/
export const APP_SECTION_MANAGER='SectionManager';
export const APP_DIVISION_MANAGER='DivisionManager';
export const APP_DEVELOPER='Developer';
export const APP_GENERAL_USER='GeneralUser';
export const APP_FULFILLER='Fulfiller';