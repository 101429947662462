import React, { Component } from 'react';
import './App.css';
import {Provider} from 'react-redux';
import store from './Store/store';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import RequestForm from './Components/RequestForm';
import PrivateRoute from './Components/PrivateRoute';
import PublicRoute from './Components/PublicRoute';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Login from './Components/Login';
import Requests from './Components/Requests';
import TopBar from './Components/TopBar';
import NavigationBar from './Components/NavigationBar';
import Request from './Components/Request';
import NoMatch from './Components/NoMatch';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import Authorize from './Components/Authorize';
import UserSettings from './Components/UserSettings';
import { isUndefinedOrNullOrEmpty } from './Helpers/helperFunctions';
import SideNav from './Components/SideNav';
import Hidden from '@material-ui/core/Hidden';
import Admin from './Components/Admin';

const drawerWidth = 150;
const styles = t => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    ...t.mixins.toolbar,
    padding: '1.5em 0',
    width: '100%'
  },
  content: {
    [t.breakpoints.up('md')]: {
      flexGrow: 1,
      padding: t.spacing(3),
      marginLeft: drawerWidth
    }
  }
});
const uiTheme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: red,
    background: {
      paper: '#FAFAFA',
      default: '#eceff1'
    }
  }
});

class App extends Component {
  componentDidMount(){
    let params = (new URL(document.location)).searchParams;
    const redirectPath = params.get('redirectTo');
    if(!isUndefinedOrNullOrEmpty(redirectPath)){
      const splitPath = redirectPath.split('/');
      const requestNum = splitPath[splitPath.length - 1];
      const exp = /^[0-9]+$/
      if(exp.test(requestNum) && !window.location.pathname !== redirectPath){
        localStorage.setItem('redirectTo', redirectPath);
      }
    }
  }
  render() {
    const {classes} = this.props;
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={uiTheme}>
          <Router>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='flex-start'
              alignContent='center' 
              className="App"
              wrap='wrap'
            >
              <TopBar />
              <Hidden smDown>
                <SideNav />
              </Hidden>
              <div className={classes.toolbar}></div>
              <Grid item xs={12} className={classes.content}>
                <Switch>
                  <PrivateRoute exact path='/' component={Requests} />
                  <PrivateRoute path='/create-request' component={RequestForm} />
                  <PublicRoute path='/login' component={Login} />
                  <PrivateRoute path='/requests/:requestID' component={Request} />
                  <PrivateRoute path='/settings' component={UserSettings} />
                  <PrivateRoute path='/admin' component={Admin} />
                  <Route component={Authorize} />
                  <Route component={NoMatch} />
                </Switch>
              </Grid>
              <div className={classes.toolbar}></div>
              <Hidden mdUp>
                <NavigationBar />
              </Hidden>
            </Grid>
          </Router>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default withStyles(styles)(App);
