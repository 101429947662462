import React, {Component} from 'react';
import {connect} from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/styles/withStyles';
import {Link as RouterLink} from 'react-router-dom';
import { withRouter } from "react-router";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import { authContext } from '../Helpers/adalAuthentication';
import { APP_DEVELOPER } from '../Helpers/Enums';
import {isUndefinedOrNull} from '../Helpers/helperFunctions'

const drawerWidth = 150;
const styles = t => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth
    },
    drawerContainer: {
        overflow: 'auto'
    },
});

const theme = createMuiTheme({
    palette: {
        type: 'dark'
    }
})

class SideNav extends Component{
    render(){
        const {classes} = this.props;
        const authenticatedRoutes = (
            <React.Fragment>
                <ListItem
                    button
                    to='/'
                    component={RouterLink}
                    selected={this.props.location.pathname.toLowerCase() === '/'}
                >
                    <ListItemText>Requests</ListItemText>
                </ListItem>
                <Divider />
                <ListItem
                    button
                    to='/create-request'
                    component={RouterLink}
                    selected={this.props.location.pathname.toLowerCase() === '/create-request'}
                >
                    <ListItemText>Create Request</ListItemText>
                </ListItem>
                <Divider />
                {
                    !isUndefinedOrNull(authContext.getCachedUser()) && authContext.getCachedUser().profile.roles[0] === APP_DEVELOPER ?
                    <React.Fragment>
                        <ListItem
                            button
                            to='/admin'
                            component={RouterLink}
                            selected={this.props.location.pathname.toLowerCase() === '/admin'}
                        >
                            <ListItemText>Admin</ListItemText>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                    :
                    null
                }
                <ListItem
                    button
                    to='/settings'
                    component={RouterLink}
                    selected={this.props.location.pathname.toLowerCase() === '/settings'}
                >
                    <ListItemText>Settings</ListItemText>
                </ListItem>
                <Divider />
            </React.Fragment>
        )
        const publicRoutes = (
            <React.Fragment>
                <ListItem
                    button
                    to='/login'
                    component={RouterLink}
                    selected={this.props.location.pathname.toLowerCase() === '/login'}
                >
                    <ListItemText>Login</ListItemText>
                </ListItem>
            </React.Fragment>
        )
        return(
            <ThemeProvider theme={theme}>
                <Drawer 
                    variant='permanent' 
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <Toolbar />
                    <div className={classes.drawerContainer}>
                        <List>
                            {
                                this.props.authenticated ? 
                                authenticatedRoutes :
                                publicRoutes
                            }
                        </List>
                    </div>
                </Drawer>
            </ThemeProvider>
        );
    }
}
const mapStateToProps = state => ({
    authenticated: state.account.authenticated
})
export default connect(mapStateToProps, {})(withStyles(styles)(withRouter(SideNav)));