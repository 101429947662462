import {
    LOGIN_ATTEMPT,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    ACCOUNT_FETCH_ATTEMPT,
    ACCOUNT_FETCH_FAILURE,
    ACCOUNT_FETCH_SUCCESS,
    LOGOUT,
    FETCH_USERS_ATTEMPT,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_EMPLOYEES_ATTEMPT,
    FETCH_EMPLOYEES_FAILURE,
    FETCH_EMPLOYEES_SUCCESS,
    FETCH_ACCOUNT_SETTINGS_ATTEMPT,
    FETCH_ACCOUNT_SETTINGS_FAILURE,
    FETCH_ACCOUNT_SETTINGS_SUCCESS,
    NOTIFICATION_FREQUENCY_OPTION_CHANGED,
    UPDATE_ACCOUNT_SETTINGS_ATTEMPT,
    UPDATE_ACCOUNT_SETTINGS_FAILURE,
    UPDATE_ACCOUNT_SETTINGS_SUCCESS,
    FETCH_FULFILLERS_ATTEMPT,
    FETCH_FULFILLERS_FAILURE,
    FETCH_FULFILLERS_SUCCESS,
} from '../Actions/types';
import {isUndefinedOrNullOrEmpty} from '../Helpers/helperFunctions';
const initialState = {
    authenticated: !isUndefinedOrNullOrEmpty(localStorage.getItem('adal.idtoken')),
    signingIn: false,
    account: '',
    accountMessage: '',
    fetchingAccount: true,
    waitingUsersData: true,
    users: [],
    usersLoading: true,
    employees: [],
    employeesLoading: true,
    fulfillers: [],
    fulfillersLoading: true,
    accountSettingsErr: false,
    fetchingAccountSettings: true,
    accountSettings: '',
    notificationSetting: 0,
    updatingAccountSettings: false,
    canViewManagerTab: false,
    canViewFulfillerTab: false,
    roles: []
}
export default (state = initialState, action)=>{
    switch(action.type){
        case LOGIN_ATTEMPT:
            return{
                ...state,
                signingIn: action.payload
            }
        case LOGIN_FAILURE: 
            return{
                ...state,
                signingIn: false,
                authenticated: false,
                account: ''
            }
        case LOGIN_SUCCESS:
            return{
                ...state,
                authenticated: action.payload,
                signingIn: false,
                account: '',
            }
        case ACCOUNT_FETCH_ATTEMPT:
            return{
                ...state,
                fetchingAccount: action.payload
            }
        case ACCOUNT_FETCH_FAILURE:
            return{
                ...state,
                fetchingAccount: false,
                accountMessage: action.payload.accountMessage,
                account: action.payload.account
            }
        case ACCOUNT_FETCH_SUCCESS:
            return{
                ...state,
                account: action.payload.account,
                canViewManagerTab: action.payload.canViewManagerTab,
                canViewFulfillerTab: action.payload.canViewFulfillerTab,
                fetchingAccount: false,
                roles: action.payload.roles,
                waitingUsersData: true
            }
        case LOGOUT:
            return{
                ...state,
                authenticated: false,
                account: ''
            }
        case FETCH_USERS_ATTEMPT:
            return{
                ...state,
                usersLoading: action.payload,
                waitingUsersData: false
            }
        case FETCH_USERS_SUCCESS:
            return{
                ...state,
                usersLoading: false,
                users: action.payload.users
            }
        case FETCH_USERS_FAILURE:
            return{
                ...state,
                usersLoading: false
            }
        case FETCH_EMPLOYEES_ATTEMPT: 
            return{
                ...state,
                employeesLoading: true,
                waitingUsersData: false
            }
        case FETCH_EMPLOYEES_FAILURE:
            return{
                ...state,
                employees: [],
                employeesLoading: false
            }
        case FETCH_EMPLOYEES_SUCCESS:
            return{
                ...state,
                employees: action.payload,
                employeesLoading: false
            }
        case FETCH_FULFILLERS_ATTEMPT:
            return{
                ...state,
                fulfillersLoading: true,
                waitingUsersData: false
            }
        case FETCH_FULFILLERS_FAILURE:
            return{
                ...state,
                fulfillersLoading: false,
                fulfillers: []
            }
        case FETCH_FULFILLERS_SUCCESS:
            return{
                ...state,
                fulfillersLoading: false,
                fulfillers: action.payload
            }
        case FETCH_ACCOUNT_SETTINGS_ATTEMPT:
            return{
                ...state,
                fetchingAccountSettings: true,
                accountSettingsErr: false,
                notificationSetting: -1
            }
        case FETCH_ACCOUNT_SETTINGS_FAILURE:
            return{
                ...state,
                accountSettings: '',
                fetchingAccountSettings: false,
                accountSettingsErr: true
            }
        case FETCH_ACCOUNT_SETTINGS_SUCCESS:
            return{
                ...state,
                accountSettings: action.payload,
                accountSettingsErr: false,
                fetchingAccountSettings: false,
                notificationSetting: action.payload.FrequencyOption
            }
        case NOTIFICATION_FREQUENCY_OPTION_CHANGED:
            return{
                ...state,
                notificationSetting: Number(action.payload)
            }
        case UPDATE_ACCOUNT_SETTINGS_ATTEMPT:
            return{
                ...state,
                updatingAccountSettings: true
            }
        case UPDATE_ACCOUNT_SETTINGS_FAILURE:
            return{
                ...state,
                accountSettingsErr: true,
                updatingAccountSettings: false
            }
        case UPDATE_ACCOUNT_SETTINGS_SUCCESS:
            return{
                ...state,
                accountSettings: action.payload,
                updatingAccountSettings: false
            }
        default: return state;
    }
}