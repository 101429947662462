import {
    SUBMITTED,
    DM_APPROVED,
    SM_APPROVED,
    IN_PROGRESS,
    COMPLETED,
    REJECTED
} from './Enums';
export const isUndefinedOrNullOrEmpty = (input) => {
    return (input === undefined || input === null || input === "");
}
export const isUndefinedOrNull = (input) => {
    return (input === undefined || input === null);
}
export const isNullOrEmpty = (input) => {
    return (input === null || input === '');
}
export const isValidEmployeeUsername = (input) => {
    input = input.toLowerCase();
    if(/^e[0-9]{6}$/.test(input)){
        //matches pattern e######
        return true;
    }
    return false;
}
export const getRequestStatusName = (requestStatus) =>{
    switch(requestStatus){
        case SUBMITTED.value:
            return SUBMITTED.name;
        case SM_APPROVED.value:
            return SM_APPROVED.name;
        case DM_APPROVED.value:
            return DM_APPROVED.name;
        case IN_PROGRESS.value:
            return IN_PROGRESS.name;
        case COMPLETED.value: 
            return COMPLETED.name;
        case REJECTED.value:
            return REJECTED.name;
        default: 
            return requestStatus;
    }   
}

export const getAdminAppRoleName = (appRoles, appRoleId) => {
    const appRole = appRoles.find(appRole => appRole.AppRoleId === appRoleId);
    if(appRole) return appRole.DisplayName;
    else return 'AD Admin';
}