import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default function ReadOnlyManager(props) {
    return (
        <Grid item xs={12} lg={6}>
            <TextField
                fullWidth
                variant='filled' 
                value={`${props.data.displayName} (${props.data.userPrincipalName})`} 
                label={props.label}
                disabled={true}
            />
        </Grid>
    )
}
