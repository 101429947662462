import {
    ORDER_DATE_CHANGED,
    RECIEVE_STATUS_CHANGED,
    RECIEVE_DATE_CHANGED,
    DELIVERY_DATE_CHANGED,
    DELIVERY_STATUS_CHANGED,
    VENDOR_NAME_CHANGED,
    PURCHASE_ORDER_NUMBER_CHANGED,
    FETCH_FULFILL_RECORD_ATTEMPT,
    FETCH_FULFILL_RECORD_FAILURE,
    FETCH_FULFILL_RECORD_SUCCESS,
    UPDATE_FULFILL_RECORD_ATTEMPT,
    UPDATE_FULFILL_RECORD_FAILURE,
    UPDATE_FULFILL_RECORD_SUCCESS,
    FULFILL_RECORD_MESSAGE_CHANGED
} from '../Actions/types';
const initialState = {
    id: '',
    requestId: '',
    orderDate: null,
    recieveStatus: false,
    recieveDate: null,
    deliveryStatus: false,
    deliveryDate: null,
    vendor: '',
    purchaseOrderNumber: '',
    loadingFulfillRecord: true,
    updatingFulfillRecord: false,
    fulfillRecordMessage: ''
}
export default (state=initialState, action)=>{
    switch(action.type){
        case ORDER_DATE_CHANGED:
            return{
                ...state,
                orderDate: action.payload
            };
        case RECIEVE_DATE_CHANGED:
            return{
                ...state,
                recieveDate: action.payload
            };
        case RECIEVE_STATUS_CHANGED:
            return{
                ...state,
                recieveStatus: action.payload
            }
        case DELIVERY_DATE_CHANGED:
            return{
                ...state,
                deliveryDate: action.payload
            }
        case DELIVERY_STATUS_CHANGED:
            return{
                ...state,
                deliveryStatus: action.payload
            }
        case VENDOR_NAME_CHANGED:
            return{
                ...state,
                vendor: action.payload
            }
        case PURCHASE_ORDER_NUMBER_CHANGED:
            return{
                ...state,
                purchaseOrderNumber: action.payload
            }
        case FETCH_FULFILL_RECORD_ATTEMPT:
            return{
                ...state,
                loadingFulfillRecord: true,
                fulfillRecordMessage: ''
            }
        case FETCH_FULFILL_RECORD_SUCCESS:
            return{
                ...state,
                orderDate: action.payload.OrderDate,
                requestId: action.payload.RequestId,
                recieveStatus: action.payload.RecieveStatus,
                recieveDate: action.payload.RecieveDate,
                deliveryStatus: action.payload.DeliveryStatus,
                deliveryDate: action.payload.DeliveryDate,
                vendor: action.payload.Vendor,
                purchaseOrderNumber: action.payload.PurchaseOrderNumber,
                loadingFulfillRecord: false,
                id: action.payload.id
            }
        case FETCH_FULFILL_RECORD_FAILURE:
            return{
                ...state,
                loadingFulfillRecord: false,
                id: '',
                requestId: '',
                orderDate: null,
                recieveStatus: false,
                recieveDate: null,
                deliveryStatus: false,
                deliveryDate: null,
                vendor: '',
                purchaseOrderNumber: ''
            }
        case UPDATE_FULFILL_RECORD_ATTEMPT:
            return{
                ...state,
                updatingFulfillRecord: true
            }
        case UPDATE_FULFILL_RECORD_SUCCESS:
            return{
                ...state,
                updatingFulfillRecord: false,
                fulfillRecordMessage: 'Request fulfill record updated successfully.'
            }
        case UPDATE_FULFILL_RECORD_FAILURE:
            return{
                ...state,
                updatingFulfillRecord: false,
                fulfillRecordMessage: 'Unable to update the request fulfill record at this time.'
            }
        case FULFILL_RECORD_MESSAGE_CHANGED:
            return{
                ...state,
                fulfillRecordMessage: action.payload
            }
        default: return state;
    }
}