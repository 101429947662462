import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {connect} from 'react-redux';
import {
    loginAttempt
} from '../Actions/Account';
import { authContext } from '../Helpers/adalAuthentication';
class Login extends Component{
    onLoginAttempt = (e) => {
        let data = {
            redirected: false,
            adalComplete: false
        }
        this.props.loginAttempt(data);
    }
    componentDidMount(){
        if(authContext.getCachedUser() && !authContext.getCachedToken()){
            let data = {
                adalComplete: true
            };
            this.props.loginAttempt(data)
        }
    }
    render(){
        return(
            <Grid 
                container 
                spacing={2}
                direction='row'
                justify='center'
                alignContent='center'
                alignItems='center'
            >
                <Grid
                    item
                    xs={11}
                    sm={11}
                    md={8}
                    lg={4}
                >
                    <Grid
                        container
                        direction='column'
                        justify='center'
                        alignItems='center'
                        className='py-1'
                    >
                        <Typography variant='h3' color='textPrimary'>Welcome</Typography>
                    </Grid>
                    <Paper
                        className='py-1 px-1'
                        elevation={10}
                        square={true}
                    >
                        <Grid 
                            container
                            direction='row'
                            justify='center'
                            alignItems='center'
                            className='py-1'
                            component='form'
                            autoComplete='off'
                            spacing={8}
                        >   
                            <Grid item xs={12}>
                                <Typography variant='body1' align='left'>
                                    Upon clicking the login button, you will redirected to a Microsoft login page. Once you are there, enter your county email. You will also be prompted to enter your hosted credentials.
                                </Typography>
                            </Grid>
                            <Grid 
                                item
                                xs={12}
                            >
                                <Button
                                    variant='contained'
                                    color='primary'
                                    size='large'
                                    onClick={this.onLoginAttempt.bind(this)}
                                >
                                    Login
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }
}
const mapStateToProps = (state) => ({
    signingIn: state.account.signingIn,
    authenticated: state.account.authenticated
});
export default connect(mapStateToProps,{
    loginAttempt
})(Login);