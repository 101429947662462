import React, {Component} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    updateRequestStatusAttempt,
    toggleConfirmUpdateDialog
} from '../Actions/Request';
import {connect} from 'react-redux'

class StatusConfirmationDialog extends Component {
    onClickUpdateStatus = (e) => {
        let data = {
            status: this.props.updatingRequestStatusData.status,
            requestId: this.props.updatingRequestStatusData.requestId,
            requestsIndex: this.props.updatingRequestStatusData.requestsIndex
        }
        this.props.updateRequestStatusAttempt(data);
    }
    onClickCancelUpdate = (e) => {
        let data = {
            requestData: '',
            displayDialog: false
        }
        this.props.toggleConfirmUpdateDialog(data)
    }
    render() {
        return (
            <Dialog 
                open={this.props.displayConfirmationDialog}
                aria-labelledby='confirm-status-update-title'
                aria-describedby='confirm-status-update-description'
                PaperProps={{square: true}}
            >
                {
                    this.props.updatingRequest ?
                    <LinearProgress variant='indeterminate' />
                    :
                    null
                }
                <DialogTitle id='confirm-status-update-title'>
                    Confirm Update
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='confirm-status-update-description'>
                        Are you sure you want to update the status of this request?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' variant='text' 
                        disabled={this.props.updatingRequest}
                        onClick={this.onClickUpdateStatus.bind(this)}>
                        Yes
                    </Button>
                    <Button color='secondary' variant='text' 
                        disabled={this.props.updatingRequest}
                        onClick={this.onClickCancelUpdate.bind(this)}>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
const mapStateToProps = state => ({
    updatingRequest: state.request.updatingRequest,
    displayConfirmationDialog: state.request.displayConfirmationDialog,
    updatingRequestStatusData: state.request.updatingRequestStatusData
});
export default connect(mapStateToProps,{
    updateRequestStatusAttempt,
    toggleConfirmUpdateDialog
})(StatusConfirmationDialog);