import {
    SUBMIT_REJECTION_COMMENT_ATTEMPT,
    SUBMIT_REJECTION_COMMENT_FAILURE,
    SUBMIT_REJECTION_COMMENT_SUCCESS,
    FETCH_REQUEST_COMMENTS_ATTEMPT,
    FETCH_REQUEST_COMMENTS_FAILURE,
    FETCH_REQUEST_COMMENTS_SUCCESS,
    TOGGLE_DISPLAY_COMMENT_DIALOG
} from '../Actions/types';
const initialState = {
    comments: [],
    fetchingComments: true,
    submittingComment: false,
    commentError: false,
    commentErrorMsg: '',
    displayCommentDialog: false
}
export default (state=initialState, action)=>{
    switch(action.type){
        case TOGGLE_DISPLAY_COMMENT_DIALOG:
            return{
                ...state,
                displayCommentDialog: action.payload
            }
        case SUBMIT_REJECTION_COMMENT_ATTEMPT:
            return{
                ...state,
                submittingComment: true,
                commentError: false
            }
        case SUBMIT_REJECTION_COMMENT_SUCCESS:{
            return{
                ...state,
                submittingComment: false,
                comments: [action.payload, ...state.comments],
                commentError: false,
                displayCommentDialog: false
            }
        }
        case SUBMIT_REJECTION_COMMENT_FAILURE:
            return{
                ...state,
                submittingComment: false,
                commentErrorMsg: action.payload.message,
                commentError: true
            }
        case FETCH_REQUEST_COMMENTS_ATTEMPT:
            return{
                ...state,
                fetchingComments: true,
                comments: []
            }
        case FETCH_REQUEST_COMMENTS_SUCCESS:
            return{
                ...state,
                fetchingComments: false,
                comments: action.payload
            }
        case FETCH_REQUEST_COMMENTS_FAILURE:
            return{
                ...state,
                fetchingComments: false,
                comments: [],
                commentError: true
            }
        default: return state
    }
}