import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    employeeInputChanged
} from '../../Actions/Request';
import {
    isUndefinedOrNullOrEmpty
} from '../../Helpers/helperFunctions';
import Tooltip from '@material-ui/core/Tooltip';
const styles = theme => ({
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      padding: 0,
      alignItems: 'center',
      overflow: 'hidden',
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16
    },
    paper: {
      position: 'absolute',
      zIndex: 5,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
});
  
class Employee extends Component{
    onEmployeeChanged = (e, newVal) => {
        if(isUndefinedOrNullOrEmpty(e)){
            this.props.employeeInputChanged('')
        }else{
            this.props.employeeInputChanged(newVal)
        }
    }
    render(){
      return(
        <Grid
          item
          xs={12}
          lg={6}
        >
          <Tooltip
            placement='top'
            title='The employee this request is for. If you are a section manager, you might make a request on behalf of a direct report, for example.'
          >
            <Autocomplete 
              id='employee-input-autocomplete'
              options={this.props.employees}
              getOptionLabel={(option) => `${option.displayName} (${option.userPrincipalName})`}
              noOptionsText={this.props.employeesLoading ? 'Loading...' : 'No options'}
              fullWidth
              value={this.props.employee || null}
              disableClearable
              disabled={this.props.submittingRequest || this.props.selfRequestChecked || this.props.fetchingRequestEmployeeManager}
              renderInput={
                (params) => 
                <TextField
                  {...params}
                  variant='filled'
                  label='Employee'
                  required
                  helperText={this.props.errors.employee}
                  error={!isUndefinedOrNullOrEmpty(this.props.errors.employee)}
                />
              }
            />
          </Tooltip>
        </Grid>
      );
    }
}
const mapStateToProps = (state)=>({
    employee: state.request.employee,
    employees: state.account.employees,
    employeesLoading: state.account.employeesLoading,
    errors: state.request.errors,
    submittingRequest: state.request.submittingRequest,
    selfRequestChecked: state.request.selfRequestChecked,
    fetchingRequestEmployeeManager: state.request.fetchingEmployeeManager
})
export default connect(mapStateToProps, {
    employeeInputChanged
})(
    withStyles(styles, {withTheme: true})(Employee)
);