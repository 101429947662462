import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    requestTypeChanged
} from '../../Actions/Request';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {isUndefinedOrNullOrEmpty} from '../../Helpers/helperFunctions';
import Tooltip from '@material-ui/core/Tooltip';
class RequestType extends Component{
    onRequestTypeChanged(e){
        this.props.requestTypeChanged(e.target.value);
    }
    render(){
        return(
            <Grid
                item
                xs={12}
                lg={6}
            >
                <Tooltip
                    placement='top'
                    title='A very brief description or name of the item you are requesting. For example, Visual Studio.'
                >
                    <TextField
                        label='Request Type'
                        margin='normal'
                        fullWidth
                        variant='filled'
                        onChange={this.onRequestTypeChanged.bind(this)}
                        error={isUndefinedOrNullOrEmpty(this.props.errors.requestType) ? false : true}
                        helperText={this.props.errors.requestType}
                        disabled={this.props.submittingRequest}
                        value={this.props.requestType}
                    />
                </Tooltip>
            </Grid>
        );
    }
}
const mapStateToProps = (state) => ({
    requestType: state.request.requestType,
    errors: state.request.errors,
    submittingRequest: state.request.submittingRequest
});
export default connect(mapStateToProps, {
    requestTypeChanged
})(RequestType);