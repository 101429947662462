import React, { Component } from 'react'
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import red from '@material-ui/core/colors/red';
import {
    REJECTED
} from '../../Helpers/Enums';
import {
    editRequest
} from '../../Actions/Request';

const editBtnTheme=createMuiTheme({
    palette: {
        primary: {
            main: '#43a047'
        },
        secondary: red
    }
});

class EditButton extends Component {
    onClickEditRequest = (e) => {
        if(this.props.request.CanUpdate){
            this.props.editRequest(this.props.request);
        }
    }
    render() {
        const canViewEdit = (this.props.request.Requestor.id === this.props.account.id && this.props.request.RequestStatus === REJECTED.value);
        return (
            <MuiThemeProvider theme={editBtnTheme}>
                {
                    canViewEdit ?
                    <Button className='m-btn' disabled={this.props.submittingRequest} 
                        color='primary' variant='contained'
                        onClick={this.onClickEditRequest.bind(this)}>
                        Edit
                    </Button>
                    :
                    null
                }
                {
                    this.props.request.RequestStatus === REJECTED.value ?
                    <Typography color='secondary' variant='caption' component='p'>
                        This request has been rejected. Please make the appropriate edits and resubmit it.
                    </Typography>
                    :
                    null
                }
            </MuiThemeProvider>
        )
    }
}
const mapStateToProps = state =>({
    request: state.request.request,
    account: state.account.account,
    submittingRequest: state.request.submittingRequest
})
export default connect(mapStateToProps,{
    editRequest
})(EditButton);