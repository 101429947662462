import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
const NoMatch = (props) => (
    <Grid item xs={12} sm={11} md={10} lg={8}>
        <Paper className='py-1 px-1'>
            <Typography variant='h5' align='center'>
                Page Not Found
            </Typography>
            <Typography variant='body1' align='center'>
                You have navigated to a route which does not exist.
            </Typography>
        </Paper>
    </Grid>
);
export default NoMatch;