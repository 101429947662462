import React, { Component } from 'react'
import {connect} from 'react-redux';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import {
    SUBMITTED,
    IN_PROGRESS,
    COMPLETED,
    REJECTED,
    SM_APPROVED,
    DM_APPROVED
} from '../../Helpers/Enums';
import {
    getRequestStatusName
} from '../../Helpers/helperFunctions';
import {
    requestFilterStatusChanged
} from '../../Actions/RequestFilters';
class RequestStatusFilter extends Component {
    constructor(props){
        super(props);
        this.requestStatusInputLabel = React.createRef();
        this.state = {
            requestStatusLabelWidth: 0
        }
        this.toggleRequestStatusLabel = this.toggleRequestStatusLabel.bind(this);
    }
    componentDidMount(){
        this.toggleRequestStatusLabel('');
    }
    handleRequestStatusChanged = (e)=>{
        this.toggleRequestStatusLabel(e);
        this.props.requestFilterStatusChanged(e.target.value);
    }
    toggleRequestStatusLabel(e){
        this.setState({
            ...this.state,
            requestStatusLabelWidth: this.requestStatusInputLabel.current.offsetWidth
        })
    }
    render() {
        const statusOptions = [SUBMITTED, SM_APPROVED, DM_APPROVED, IN_PROGRESS, COMPLETED, REJECTED];
        return (
            <Paper elevation={2}>
                <FormControl fullWidth variant='outlined' disabled={this.props.fetchingRequests}>
                    <InputLabel ref={this.requestStatusInputLabel} htmlFor='request-status-filter-input'>Request Status</InputLabel>
                    <Select fullWidth 
                    multiple
                    onChange={this.handleRequestStatusChanged.bind(this)} value={this.props.filters.requestStatus} 
                    input={<OutlinedInput labelWidth={this.state.requestStatusLabelWidth} fullWidth id='request-status-filter-input' />} 
                    renderValue={selected => (
                        <Grid container direction='row' wrap='wrap' alignItems='center' justify='center' alignContent='center'>
                            {selected.map(selection => (
                                <Chip key={selection} label={getRequestStatusName(selection)} />
                            ))}
                        </Grid>
                    )}>
                        {
                            statusOptions.map((statusOption)=>(
                                <MenuItem key={statusOption.value} value={statusOption.value}>
                                    <Checkbox color='primary' checked={this.props.filters.requestStatus.indexOf(statusOption.value) > -1} />
                                    <ListItemText primary={statusOption.name} />
                                </MenuItem>   
                            ))
                        }
                    </Select>
                </FormControl>
            </Paper>
        )
    }
}

const mapStateToProps = state =>({
    filters: state.request.filters,
    fetchingRequests: state.request.fetchingRequests
});
export default connect(mapStateToProps,{
    requestFilterStatusChanged
})(RequestStatusFilter);