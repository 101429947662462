import React, {Component} from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import UserAutocomplete from './UserRoleAddFormFields/UserAutocomplete';
import RoleSelectInput from './UserRoleAddFormFields/RoleSelectInput';
import AddButton from './UserRoleAddFormFields/AddButton'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { isUndefinedOrNullOrEmpty } from '../../Helpers/helperFunctions';

class UserRoleAddForm extends Component {
    render(){
        return(
            <Grid container
                direction='row'
                wrap='wrap'
                justify='center'
                alignContent='center'
                alignItems='center'
                spacing={2}
            >
                {
                    this.props.errorExists && !isUndefinedOrNullOrEmpty(this.props.statusMessage) ?
                    <Grid item xs={12}>
                        <Alert severity="error">{this.props.statusMessage}</Alert>
                    </Grid>
                    :
                    null
                }
                <Grid item xs={12}>
                    <Typography variant='h4'>
                        Add A User
                    </Typography>
                    <Typography variant='body1' component='p' align='left'>
                        Add a new user to the application and assign a role to the user.
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <UserAutocomplete />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <RoleSelectInput />
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <AddButton />
                </Grid>
                {
                    this.props.adding ?
                    <Grid item xs={12}>
                        <CircularProgress style={{textAlign: 'center'}} />
                    </Grid>
                    :
                    null
                }
            </Grid>
        );
    }
}
const mapStateToProps = (state) => ({
    adding: state.admin.adding,
    errorExists: state.admin.errorExists,
    statusMessage: state.admin.statusMessage
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleAddForm);