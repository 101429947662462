import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography'
import Delivered from './FulfillRecordFormFields/Delivered';
import OrderDate from './FulfillRecordFormFields/OrderDate';
import PurchaseOrderNumber from './FulfillRecordFormFields/PurchaseOrderNumber';
import Recieved from './FulfillRecordFormFields/Recieved';
import Vendor from './FulfillRecordFormFields/Vendor';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    fetchFulfillRecord,
    udpateFulfillRecord
} from '../Actions/FulfillRecord';
import {
    updateRequestAttempt
} from '../Actions/Request';
import FulfillRecordSnackbar from './FulfillRecordFormFields/FulfillRecordSnackbar';
import { COMPLETED } from '../Helpers/Enums';
import {
    isUndefinedOrNullOrEmpty
} from '../Helpers/helperFunctions'

class FulfillRecordForm extends Component {
    state = {
        shouldComplete: false
    }
    componentDidMount(){
        this.props.fetchFulfillRecord(this.props.request.Id);
    }
    onClickSaveBtn = (e) => {
        let data = {
            Id: this.props.id,
            RequestId: this.props.request.Id,
            OrderDate: this.props.orderDate,
            RecieveStatus: this.props.recieveStatus,
            RecieveDate: this.props.recieveDate,
            DeliveryStatus: this.props.deliveryStatus,
            DeliveryDate: this.props.deliveryDate,
            Vendor: this.props.vendor,
            PurchaseOrderNumber: this.props.purchaseOrderNumber
        }
        this.props.udpateFulfillRecord(data);
        if(this.state.shouldComplete){
            this.props.updateRequestAttempt({
                id: this.props.request.Id,
                employee: this.props.request.Employee,
                manager: this.props.request.DivisionManager,
                fulfiller: this.props.request.Fulfiller,
                requestType: this.props.request.RequestType,
                description: this.props.request.Description,
                submitted: this.props.request.Submitted,
                justification: this.props.request.Justification,
                requestStatus: COMPLETED.value,
                canUpdate: this.props.request.CanUpdate 
            });
        }
    }
    componentDidUpdate(prevProps, prevState){
        const completeRequestCheck = 
            this.props.request.RequestStatus !== COMPLETED.value &&
            !prevState.shouldComplete &&
            !isUndefinedOrNullOrEmpty(this.props.orderDate) &&
            this.props.recieveStatus === true &&
            !isUndefinedOrNullOrEmpty(this.props.recieveDate) &&
            this.props.deliveryStatus === true &&
            !isUndefinedOrNullOrEmpty(this.props.deliveryDate) &&
            !isUndefinedOrNullOrEmpty(this.props.vendor) &&
            !isUndefinedOrNullOrEmpty(this.props.purchaseOrderNumber);
        if(completeRequestCheck){
            this.setState({
                shouldComplete: true
            })
        }
        else if(prevState.shouldComplete && this.props.request.RequestStatus === COMPLETED.value){
            this.setState({
                shouldComplete: false
            })
        }
    }
    render() {
        return (
            <Paper square className='px-1 py-2' elevation={4}>
                {
                    this.props.loadingFulfillRecord || this.props.fetchingRequest || this.props.fetchingAccount ?
                    <Grid container 
                        direction='row'
                        wrap='wrap'
                        justify='center'
                        alignItems='center'
                        alignContent='center'
                    >
                        <Grid item xs={12} className='py-3'>
                            <CircularProgress size={60} thickness={5} color='primary' />
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2} direction='row' wrap='wrap' justify='center' alignItems='center' alignContent='center'>
                        <FulfillRecordSnackbar />
                        <Grid item xs={12}>
                            <Typography variant='h5'>Fulfill Request Record</Typography>
                        </Grid>
                        <OrderDate />
                        <Vendor />
                        <PurchaseOrderNumber />
                        <Recieved />
                        <Delivered />
                        <Grid item xs={12}>
                            {
                                this.props.account.id !== this.props.request.Fulfiller.id ? 
                                <Typography variant='body2' align='center' color='textSecondary'>
                                    Only the fulfiller of this request can edit and update this form.
                                </Typography>
                                :
                                <Button 
                                    color='primary' 
                                    variant='contained' 
                                    onClick={this.onClickSaveBtn.bind(this)}
                                    disabled={this.props.loadingFulfillRecord || this.props.updatingFulfillRecord}
                                >
                                    {
                                        this.props.updatingFulfillRecord ?
                                        <CircularProgress color='primary' size={36} thickness={7}/>
                                        :
                                        <React.Fragment>
                                            {
                                                this.state.shouldComplete ?
                                                'Save & Close'
                                                :
                                                'Save'
                                            }
                                        </React.Fragment>
                                    }
                                </Button>
                            }
                        </Grid>
                    </Grid>
                }
            </Paper>
        )
    }
}

const mapStateToProps = state => ({
    id: state.fulfillRecord.id,
    requestId: state.fulfillRecord.requestId,
    account: state.account.account,
    request: state.request.request,
    orderDate: state.fulfillRecord.orderDate,
    recieveStatus: state.fulfillRecord.recieveStatus,
    recieveDate: state.fulfillRecord.recieveDate,
    deliveryDate: state.fulfillRecord.deliveryDate,
    deliveryStatus: state.fulfillRecord.deliveryStatus,
    vendor: state.fulfillRecord.vendor,
    purchaseOrderNumber: state.fulfillRecord.purchaseOrderNumber,
    loadingFulfillRecord: state.fulfillRecord.loadingFulfillRecord,
    updatingFulfillRecord: state.fulfillRecord.updatingFulfillRecord,
    fetchingRequest: state.request.fetchingSingleRequest,
    fetchingAccount: state.request.fetchingAccount
});
export default connect(mapStateToProps, {
    udpateFulfillRecord,
    fetchFulfillRecord,
    updateRequestAttempt
})(FulfillRecordForm);