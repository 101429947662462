import React, {Component} from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import {
    resetRequestMessageSnackbar
} from '../Actions/Request';
import {
    isUndefinedOrNullOrEmpty
} from '../Helpers/helperFunctions'
import {connect} from 'react-redux'

class UpdateRequestSnackbar extends Component {
    handleCloseRequestMessageSnackbar = (e) => {
        this.props.resetRequestMessageSnackbar('');
    }
    render(){
        const snackbarActions = [
            <Button key={1} size='small' variant='text' color='secondary' onClick={this.handleCloseRequestMessageSnackbar.bind(this)}>
                Close
            </Button>
        ]
        return(
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }} 
                open={!isUndefinedOrNullOrEmpty(this.props.requestMessage)}
                autoHideDuration={30000}
                onClose={this.handleCloseRequestMessageSnackbar.bind(this)}
                message={this.props.requestMessage}
                action={snackbarActions}

            />
        );
    }
}
const mapStateToProps = (state) => ({
    requestMessage: state.request.requestMessage,
})
export default connect(mapStateToProps,{
    resetRequestMessageSnackbar
})(UpdateRequestSnackbar);