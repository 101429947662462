import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    fulfillerInputChanged
} from '../../Actions/Request';
import {
    isUndefinedOrNullOrEmpty
} from '../../Helpers/helperFunctions';
import Tooltip from '@material-ui/core/Tooltip';
const styles = theme => ({
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      padding: 0,
      alignItems: 'center',
      overflow: 'hidden',
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 5,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
});
  
class RequestFulfiller extends Component{
    onRequestFulfillerChanged = (e, newVal) =>{
      if(isUndefinedOrNullOrEmpty(e)){
          this.props.fulfillerInputChanged('')
      }else{
          this.props.fulfillerInputChanged(newVal)
      }
    }
    render(){
      return(
        <Grid
          item
          xs={12}
          lg={6}
        >
          <Tooltip
            placement='top'
            title='The person who will be tasked with procurement of your request'
          >
            <Autocomplete 
              id='fulfiller-input-autocomplete'
              onChange={this.onRequestFulfillerChanged}
              options={this.props.fulfillers}
              getOptionLabel={(option) => `${option.displayName} (${option.userPrincipalName})`}
              noOptionsText={this.props.fulfillersLoading ? "Loading..." : "No options"}
              fullWidth
              value={this.props.fulfiller || null}
              disableClearable
              disabled={this.props.submittingRequest}
              renderInput={
                (params)=>
                <TextField 
                  {...params}
                  variant='filled'
                  label='Request Fulfiller'
                  required
                  helperText={this.props.errors.fulfiller}
                  error={!isUndefinedOrNullOrEmpty(this.props.errors.fulfiller)}
                />
              }
            />
          </Tooltip>
        </Grid>
      )
    }
}
const mapStateToProps = (state)=>({
    fulfiller: state.request.fulfiller,
    fulfillers: state.account.fulfillers,
    fulfillersLoading: state.account.fulfillersLoading,
    errors: state.request.errors,
    submittingRequest: state.request.submittingRequest
});
export default connect(mapStateToProps,{
    fulfillerInputChanged
})(
    withStyles(styles, {withTheme: true})(RequestFulfiller)
);