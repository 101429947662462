import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    managerInputChanged,
    getRequestEmployeeManager
} from '../../Actions/Request';
import {
    isUndefinedOrNullOrEmpty
} from '../../Helpers/helperFunctions';
import { authContext } from '../../Helpers/adalAuthentication';
import { APP_FULFILLER } from '../../Helpers/Enums';
const styles = theme => ({
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      padding: 0,
      alignItems: 'center',
      overflow: 'hidden',
    },
    noOptionsMessage: {
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    singleValue: {
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 5,
      marginTop: theme.spacing.unit,
      left: 0,
      right: 0,
    },
    divider: {
      height: theme.spacing.unit * 2,
    },
});
  
class Manager extends Component{
    onManagerChanged = (e, newVal) => {
        if(isUndefinedOrNullOrEmpty(e)){
            this.props.managerInputChanged('')
        }else{
          this.props.managerInputChanged(newVal)
        }
    }
    //    requestStatus: state.request.requestStatus
    componentDidMount(){
      if(!isUndefinedOrNullOrEmpty(this.props.account) && isUndefinedOrNullOrEmpty(this.props.manager) && !this.props.editingRequest){
        this.props.managerInputChanged(this.props.account.manager);
      }
    }
    componentDidUpdate(prevProps){
      if(prevProps.employee.id !== this.props.employee.id && !isUndefinedOrNullOrEmpty(this.props.employee.id) && authContext.getCachedUser().profile.roles[0] === APP_FULFILLER && this.props.employee.id !== this.props.account.id){
        this.props.getRequestEmployeeManager(this.props.employee);
      } else if(!isUndefinedOrNullOrEmpty(this.props.account) && isUndefinedOrNullOrEmpty(this.props.manager) && !this.props.editingRequest){
        this.props.managerInputChanged(this.props.account.manager)
      }
    }
    render(){
      return(
        <Grid
          item
          xs={12}
          lg={6}
        >
          <Autocomplete 
            id='manager-input-autocomplete'
            options={this.props.users}
            getOptionLabel={(option) => option.displayName}
            noOptionsText={this.props.usersLoading || this.props.fetchingRequestEmployeeManager ? "Loading..." : "No options"}
            fullWidth
            value={this.props.manager || null}
            disableClearable
            disabled
            onChange={this.onManagerChanged}
            renderInput={
              (params) => 
              <TextField
                {...params}
                variant='filled'
                label='Manager'
                required
                InputLabelProps={{shrink: true}}
                helperText={this.props.errors.manager}
                error={!isUndefinedOrNullOrEmpty(this.props.errors.manager)}
              />
            }
          />
        </Grid>
      )
    }
}
const mapStateToProps = (state)=>({
  manager: state.request.manager,
  employee: state.request.employee,
  users: state.account.users,
  usersLoading: state.account.usersLoading,
  errors: state.request.errors,
  submittingRequest: state.request.submittingRequest,
  editingRequest: state.request.editingRequest,
  account: state.account.account,
  fetchingRequestEmployeeManager: state.request.fetchingEmployeeManager
});
export default connect(mapStateToProps,{
    managerInputChanged,
    getRequestEmployeeManager
})(
    withStyles(styles, {withTheme: true})(Manager)
);