import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    cancelEditRequest,
    updateRequestAttempt
} from '../../Actions/Request';
import { REJECTED, SUBMITTED } from '../../Helpers/Enums';

class UpdateButtonControls extends Component {
    onCancelEditRequest = (e) => {
        this.props.cancelEditRequest(false);
    }
    onSubmitUpdateRequestForm = (e) => {
        let data = {
            id: this.props.request.Id,
            manager: this.props.request.DivisionManager,
            employee: this.props.employee,
            fulfiller: this.props.fulfiller,
            description: this.props.description,
            requestor: this.props.request.Requestor,
            requestType: this.props.requestType,
            requestStatus: this.props.request.RequestStatus === REJECTED.value ? SUBMITTED.value : this.props.request.RequestStatus,
            canUpdate: this.props.request.CanUpdate,
            submitted: this.props.request.Submitted,
            justification: this.props.justification
        }
        this.props.updateRequestAttempt(data);
    }
    render(){
        return(
            <React.Fragment>
                <Grid item xs={6}>
                    <Button variant='contained' color='primary' onClick={this.onSubmitUpdateRequestForm.bind(this)} disabled={this.props.submittingRequest}>
                        {
                            this.props.submittingRequest ? 
                            <CircularProgress color='primary' size={36} thickness={7}/> :
                            <React.Fragment>UPDATE&nbsp;<Icon>send</Icon></React.Fragment>
                        }
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant='contained' color='secondary' onClick={this.onCancelEditRequest.bind(this)} disabled={this.props.submittingRequest}>
                        CANCEL &nbsp; <Icon>cancel</Icon>
                    </Button>
                </Grid>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    submittingRequest: state.request.submittingRequest,
    editingRequest: state.request.editingRequest,
    request: state.request.request,
    manager: state.request.manager,
    employee: state.request.employee,
    fulfiller: state.request.fulfiller,
    description: state.request.description,
    requestType: state.request.requestType,
    requestStatus: state.request.requestStatus,
    justification: state.request.justification
});
export default connect(mapStateToProps, {
    updateRequestAttempt,
    cancelEditRequest
})(UpdateButtonControls);