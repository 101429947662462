import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress'
import {connect} from 'react-redux'
import {
    editRequest,
    updateRequestAttempt
} from '../Actions/Request';
import {Link as RouterLink} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import {
    toggleDisplayDialog,
} from '../Actions/RequestComments'
import ProfileDataDisplay from './RequestDataProfileDisplays/ProfileDataDisplay';
import CommentForRequestDialog from './CommentForRequestDialog';
import RequestCommentsSection from './RequestCommentsSection';
import UpdateRequestSnackbar from './UpdateRequestSnackbar';
import EditButton from './RequestDataControls/EditButton';
import RejectButton from './RequestDataControls/RejectButton';
import PositiveButton from './RequestDataControls/PositiveButton';
import FulfillRecordForm from './FulfillRecordForm';
import ProgressIndicator from './RequestDataControls/ProgressIndicator';
import {
    isUndefinedOrNullOrEmpty,
    getRequestStatusName
} from '../Helpers/helperFunctions';
import {
    downloadRequestAttachment
} from '../Actions/RequestAttachment'
import {
    COMPLETED,
    IN_PROGRESS
} from '../Helpers/Enums';
class RequestData extends Component{
    onClickEditBtn = (e) => {
        if(this.props.request.CanUpdate){
            this.props.editRequest(this.props.request);
        }
    }
    onClickDownloadAttachment = (id, filename) => {
        this.props.downloadRequestAttachment({id, filename});
    }
    displayAttachmentName = (f) => {
        let arr = f.split('.');
        arr.splice(arr.length-2, 1);
        return arr.join('.');
    }
    updateCurrentRequest = (newStatus) => {
        let requestData = {
            id: this.props.request.Id,
            employee: this.props.request.Employee,
            manager: this.props.request.DivisionManager,
            fulfiller: this.props.request.Fulfiller,
            requestType: this.props.request.RequestType,
            description: this.props.request.Description,
            submitted: this.props.request.Submitted,
            requestStatus: newStatus,
            canUpdate: this.props.request.CanUpdate
        }
        this.props.updateRequestAttempt(requestData);
    }
    render(){
        return(
            <Grid container direction='row' alignContent='center' alignItems='flex-start' justify='center' wrap='wrap' spacing={2}>
                <UpdateRequestSnackbar />
                <CommentForRequestDialog />
                <Grid item xs={11}>
                    <Typography variant='h4'>View Request</Typography>
                </Grid>
                <Grid item xs={11} className='mb-1'>
                    <Typography variant='h5'>ID# {this.props.request.Id}</Typography>
                </Grid>
                <Grid item xs={11} lg={8}>
                    <Paper className='px-1 py-2' square elevation={6}>
                        <Button color='primary' variant='text' component={RouterLink} to='/'>
                            Back to Requests
                        </Button>
                        <List>
                            <Divider component='li' />
                            {/* <ProfileDataDisplay user={this.props.request.SectionManager} label='Section Manager' />
                            <Divider component='li' />
                            <ProfileDataDisplay user={this.props.request.DivisionManager} label='Division Manager'/>
                            <Divider component='li' />
                            <ProfileDataDisplay user={this.props.request.Fulfiller} label='Fulfiller' />
                            <Divider component='li' /> */}
                            <ListItem>
                                <ListItemText 
                                    primary={<Typography variant='subtitle2' component='p' color='textSecondary'>Request Type</Typography>} 
                                    secondary={<Typography variant='body1' color='textPrimary'>{this.props.request.RequestType}</Typography>}
                                />
                            </ListItem>
                            <Divider component='li' />
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <Typography variant='subtitle2' component='p' color='textSecondary'>Description</Typography>
                                    }
                                    secondary={
                                        <Typography variant='body1' color='textPrimary'>{this.props.request.Description}</Typography>
                                    }
                                />
                            </ListItem>
                            <Divider component='li' />
                            <ListItem>
                                <ListItemText
                                    primary={<Typography variant='subtitle2' component='p' color='textSecondary'>Justification</Typography>} 
                                    secondary={
                                        <Typography variant='body1' color='textPrimary'>{this.props.request.Justification}</Typography>
                                    }
                                />
                            </ListItem>
                            <Divider component='li' />
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <Typography variant='subtitle2' component='p' color='textSecondary'>Request Status</Typography>
                                    }
                                    secondary={
                                        <Typography variant='body1' color='textPrimary'>{getRequestStatusName(this.props.request.RequestStatus)}</Typography>
                                    }
                                />
                            </ListItem>
                            <Divider component='li' />
                            {
                                isUndefinedOrNullOrEmpty(this.props.request.AttachmentFileName) ?
                                null
                                :
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemText 
                                            primary={
                                                <Typography variant='subtitle2' component='p' color='textSecondary'>Request Attachment File</Typography>
                                            }
                                            secondary={
                                                <Typography variant='body1' color='textPrimary'>{this.props.request.AttachmentFileName}</Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component='li'/>
                                </React.Fragment>
                            }
                            <ListItem>
                                <ListItemText 
                                    primary={
                                        <Typography variant='subtitle2' component='p' color='textSecondary'>Created On</Typography>
                                    }
                                    secondary={
                                        <Typography variant='body1' color='textPrimary'>{new Date(this.props.request.Submitted + 'Z').toLocaleString()}</Typography>
                                    }
                                />
                            </ListItem>
                            <Divider component='li' />
                            {
                                isUndefinedOrNullOrEmpty(this.props.request.ApprovalDate) ? 
                                null
                                :
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemText 
                                            primary={
                                                <Typography variant='subtitle2' component='p' color='textSecondary'>Approval Date</Typography>
                                            }
                                            secondary={
                                                <Typography variant='body1' color='textPrimary'>{new Date(this.props.request.ApprovalDate + 'Z').toLocaleString()}</Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component='li' />
                                </React.Fragment>
                            }
                            {
                                isUndefinedOrNullOrEmpty(this.props.request.CompletedDate) ?
                                null
                                :
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Typography variant='subtitle2' color='textSecondary'>Completed Date</Typography>
                                            }
                                            secondary={
                                                <Typography variant='body1' color='textPrimary'>{new Date(this.props.request.CompletedDate + 'Z').toLocaleString()}</Typography>
                                            }
                                        />
                                    </ListItem>
                                    <Divider component='li' />
                                </React.Fragment>
                            }

                            <ProfileDataDisplay user={this.props.request.Requestor} label='Requested By' />
                            <Divider component='li' />
                            <ProfileDataDisplay user={this.props.request.Employee} label='Requested For Employee' />
                            <Divider component='li' />
                            {
                                isUndefinedOrNullOrEmpty(this.props.request.AttachmentFileName) ?
                                null
                                :
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemText
                                            primary={
                                                <Typography variant='subtitle2' color='textSecondary'>Attachment</Typography>
                                            }
                                            secondary={
                                                <Link
                                                    to='#'
                                                    color='primary'
                                                    onClick={this.onClickDownloadAttachment.bind(this, this.props.request.Id, this.props.request.AttachmentFileName)}
                                                >
                                                    {this.displayAttachmentName(this.props.request.AttachmentFileName)}
                                                </Link>
                                            }
                                        />
                                    </ListItem>
                                </React.Fragment>
                            }
                            {
                                this.props.downloading ?
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <CircularProgress style={{textAlign: 'center'}} color='primary' />
                                        }
                                    />
                                </ListItem>
                                :
                                null
                            }
                        </List>
                        <ProgressIndicator />
                        {
                            this.props.request.CanUpdate && this.props.request.RequestStatus !== COMPLETED.value ?
                                <Grid item xs={12}>
                                    <EditButton />
                                    <PositiveButton />
                                    <RejectButton />
                                </Grid>
                            :
                            null
                        }
                        {
                            this.props.request.RequestStatus === IN_PROGRESS.value && this.props.account.Id === this.props.request.Fulfiller.Id ?
                            <Typography variant='body2' color='textPrimary' align='center' style={{marginTop: '1rem'}}>
                                You can complete this request by filling out all information on the Fulfill Request Record form.
                            </Typography>
                            :
                            null
                        }
                    </Paper> 
                </Grid>
                {
                    (this.props.request.RequestStatus === IN_PROGRESS.value || this.props.request.RequestStatus === COMPLETED.value) ?
                    <Grid item xs={11} md={8} lg={4}>
                        <FulfillRecordForm />
                    </Grid>
                    :
                    null
                }
                <RequestCommentsSection />
            </Grid>
        );
    }
}
const mapStateToProps = state => ({
    request: state.request.request,
    editingRequest: state.request.editingRequest,
    account: state.account.account,
    displayCommentDialog: state.requestComments.displayCommentDialog,
    downloading: state.requestAttachment.downloading
});
export default connect(mapStateToProps, {
    editRequest,
    toggleDisplayDialog,
    updateRequestAttempt,
    downloadRequestAttachment
})(RequestData);