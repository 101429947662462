import {authContext} from './adalAuthentication';
import axios from 'axios';
import {isUndefinedOrNullOrEmpty} from './helperFunctions'
export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
axiosClient.interceptors.request.use((req)=>{
    return new Promise((resolve, reject)=>{
        authContext.getCachedUser();
        authContext.acquireToken(process.env.REACT_APP_API_CLIENT_ID, (error, adToken, errDesc)=>{
            if(!!adToken){
                req.headers.Authorization = `Bearer ${adToken}`;
                resolve(req)
            }else{
                console.error(errDesc);
                let port='';
                if(!isUndefinedOrNullOrEmpty(window.location.port)){
                    port = `:${window.location.port}`;
                }
                authContext.config.redirectUri = `${window.location.protocol}//${window.location.hostname}${port}/authorize`;
                authContext.acquireTokenRedirect(process.env.REACT_APP_API_CLIENT_ID);
                reject(req)
            }
        })
    })
}, (err)=>{
    return Promise.reject(err);
    // return err;
});
axiosClient.interceptors.response.use((res)=>{
    return new Promise((resolve, reject) => {
        return resolve(res)        
    })
}, (err)=>{
        if(err.response.status === 401){
            if(!authContext.loginInProgress()){
                let port='';
                if(!isUndefinedOrNullOrEmpty(window.location.port)){
                    port = `:${window.location.port}`;
                }
                localStorage.setItem('postRefreshAdalRoute', window.location.pathname);
                authContext.config.redirectUri = `${window.location.protocol}//${window.location.hostname}${port}/authorize`;
                authContext.acquireTokenRedirect(process.env.REACT_APP_API_CLIENT_ID);
            }
        }else if(err.response.status === 500){
            return axios.request(err.config);
        }
        return Promise.reject(err);
    }
);