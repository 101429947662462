import React, {Component} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import TableChart from '@material-ui/icons/TableChart';
import Popover from '@material-ui/core/Popover';
import {withStyles} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import RequestColSelection from './RequestColSelection';
import Typography from '@material-ui/core/Typography';

const styles = {
    paper: {
        width: 300,
        maxHeight: 250,
        overflowY: 'hidden',
    },
    columnsCaption: {
        maxWidth: '60%'
    }
}

class RequestColSelectionBar extends Component{
    state = {
        open: false,
        anchorEl: null
    }
    handleClose = () => {
        this.setState({
            ...this.state,
            anchorEl: null,
            open: false
        })
    }
    handleClick = e => {
        this.setState({
            ...this.state,
            anchorEl: e.currentTarget,
            open: true
        })
    }
    render(){
        const {classes} = this.props;
        return(
            <React.Fragment>
                <Toolbar variant='dense' disableGutters>
                    <Button onClick={this.handleClick.bind(this)} color='primary' variant='text'>
                        <Icon color='primary'>
                            <TableChart />
                        </Icon>
                        &nbsp;
                        Columns
                    </Button>
                    &nbsp;
                    <Typography className={classes.columnsCaption} variant='caption'>Click to select columns to display in the requests table below.</Typography>
                </Toolbar>
                <Popover
                    id='col-popover'
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose.bind(this)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    PaperProps={{
                        className: classes.paper
                    }}
                >
                    <Grid container direction='row' wrap='wrap'>
                        <RequestColSelection handleClose={this.handleClose} />
                    </Grid>
                </Popover>
            </React.Fragment>
        );
    }
}
export default withStyles(styles)(RequestColSelectionBar);