import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import {connect} from 'react-redux';
import {
    cancelEditUserRole
} from '../../Actions/Admin';
import AppRoleSelect from './UserRoleEditFields/AppRoleSelect';
import SaveButton from './UserRoleEditFields/SaveButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Alert from '@material-ui/lab/Alert';
import {
    isUndefinedOrNullOrEmpty
} from '../../Helpers/helperFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class UserRoleEditDialog extends Component{

    handleClose = (e) => {
        if(!this.props.assigning){
            this.props.cancelEditUserRole(false);
        }
    }

    render(){
        return(
            <Dialog fullScreen open={this.props.editingUserRole} onClose={this.handleClose.bind(this)} TransitionComponent={Transition}>
                <AppBar position='relative' color='inherit'>
                    <Toolbar>
                        <IconButton edge="start" color="secondary" onClick={this.handleClose.bind(this)} aria-label="close" disabled={this.props.assigning}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">
                            Edit User Role
                        </Typography>
                    </Toolbar>
                </AppBar>
                {
                    this.props.assigning ?
                    <LinearProgress color='primary' />
                    :
                    null
                }
                {
                    this.props.errorExists && !isUndefinedOrNullOrEmpty(this.props.statusMessage) ?
                    <Alert variant='filled' severity='error'>{this.props.statusMessage}</Alert>
                    :
                    null
                }
                {
                    !this.props.errorExists & !isUndefinedOrNullOrEmpty(this.props.statusMessage) ?
                    <Alert variant='filled' severity='success'>{this.props.statusMessage}</Alert>
                    :
                    null
                }
                <Toolbar />
                <Container maxWidth='lg'>
                    <Grid
                        container
                        direction='row'
                        wrap='wrap'
                        spacing={4}
                        justify='center'
                        alignContent='center'
                        alignItems='center'
                    >
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' component='span' color='textSecondary'>User ID: </Typography>
                                <Typography variant='body1' component='span'>{this.props.appUser.principalId}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' component='span' color='textSecondary'>User Name: </Typography>
                                <Typography variant='body1' component='span'>{this.props.appUser.principalDisplayName}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <AppRoleSelect />
                            </Grid>
                            <Grid item xs={12}>
                                <SaveButton />
                            </Grid>
                    </Grid>
                </Container>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    editingUserRole: state.admin.editingUserRole,
    appUser: state.admin.appUser,
    assigning: state.admin.assigning,
    errorExists: state.admin.errorExists,
    statusMessage: state.admin.statusMessage,
    errorExists: state.admin.errorExists
})

const mapDispatchToProps = {
    cancelEditUserRole
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleEditDialog);