import {
    APP_GET_USERS,
    APP_GET_USERS_FAILURE,
    APP_GET_USERS_SUCCESS,
    APP_GET_ROLES,
    APP_GET_ROLES_FAILURE,
    APP_GET_ROLES_SUCCESS,
    APP_ASSIGN_USER_ROLE,
    APP_ASSIGN_USER_ROLE_FAILURE,
    APP_ASSIGN_USER_ROLE_SUCCESS,
    APP_ADD_USER_ROLE,
    APP_ADD_USER_ROLE_FAILURE,
    APP_ADD_USER_ROLE_SUCCESS,
    APP_EDIT_USER_ROLE,
    APP_CANCEL_EDIT_USER_ROLE,
    APP_APP_USER_CHANGED,
    APP_APP_ROLE_CHANGED,
    APP_APP_USER_INPUT_CHANGED,
    APP_TOGGLE_DELETE_DIALOG,
    APP_DELETE_USER_ROLE,
    APP_DELETE_USER_ROLE_FAILURE,
    APP_DELETE_USER_ROLE_SUCCESS
} from '../Actions/types'

const initialState = {
    appUsers: [],
    appRoles: [],
    appRole: '',
    appUser: '',
    graphUser: '',
    graphUserInput: '',
    editingUserRole: false,
    errorExists: false,
    statusMessage: '',
    fetchingUsers: false,
    fetchingRoles: false,
    assigning: false,
    adding: false,
    appRoleAssignmentDeleteId: '',
    displayDeleteDialog: false,
    deletingUserRole: false,
    errorExistsDeleting: false
}

export default (state=initialState, action) => {
    switch(action.type){
        case APP_GET_USERS:
            return{
                ...state,
                fetchingUsers: true,
                errorExists: false
            }
        case APP_GET_USERS_SUCCESS:
            return{
                ...state,
                appUsers: action.payload.ApplicationUsers,
                fetchingUsers: false,
                errorExists: false
            }
        case APP_GET_USERS_FAILURE:
            return{
                ...state,
                fetchingUsers: false,
                errorExists: true
            }
        case APP_GET_ROLES:
            return{
                ...state,
                fetchingRoles: true,
                errorExists: false
            }
        case APP_GET_ROLES_SUCCESS:
            return{
                ...state,
                fetchingRoles: false,
                errorExists: false,
                appRoles: action.payload.ApplicationRoles
            }
        case APP_GET_ROLES_FAILURE:
            return{
                ...state,
                fetchingRoles: false,
                errorExists: true
            }
        case APP_ASSIGN_USER_ROLE:
            return{
                ...state,
                assigning: true,
                errorExists: false,
                statusMessage: ''
            }
        case APP_ASSIGN_USER_ROLE_SUCCESS:
            return{
                ...state,
                assigning: false,
                appUsers: state.appUsers.map(user => user.principalId === action.payload.AppRoleAssignment.principalId ? action.payload.AppRoleAssignment : user),
                appUser: action.payload.AppRoleAssignment,
                errorExists: false,
                statusMessage: action.payload.Message
            }
        case APP_ASSIGN_USER_ROLE_FAILURE:
            return{
                ...state,
                assigning: false,
                errorExists: true,
                statusMessage: action.payload.Message || 'Failed to assign user role.'
            }
        case APP_ADD_USER_ROLE:
            return{
                ...state,
                adding:true,
                errorExists: false,
                statusMessage: ''
            }
        case APP_ADD_USER_ROLE_SUCCESS:
            return{
                ...state,
                adding: false,
                appUsers: [...state.appUsers, action.payload.AppRoleAssignment],
                errorExists: false,
                statusMessage: action.payload.Message,
                graphUser: '',
                graphUserInput: '',
                appRole: ''
            }
        case APP_ADD_USER_ROLE_FAILURE:
            return{
                ...state,
                adding: false,
                errorExists: true,
                statusMessage: action.payload.Message || 'Failed to add a new user.'
            }
        case APP_EDIT_USER_ROLE:
            return{
                ...state,
                appUser: action.payload,
                statusMessage: '',
                errorExists: false,
                editingUserRole: true,
            }
        case APP_CANCEL_EDIT_USER_ROLE:
            return{
                ...state,
                appUser: '',
                editingUserRole: false,
                statusMessage: '',
                errorExists: false,
            }
        case APP_APP_USER_CHANGED:
            return{
                ...state,
                graphUser: action.payload
            }
        case APP_APP_ROLE_CHANGED:
            return{
                ...state,
                appRole: action.payload
            }
        case APP_APP_USER_INPUT_CHANGED:
            return{
                ...state,
                graphUserInput: action.payload
            }
        case APP_TOGGLE_DELETE_DIALOG:
            return{
                ...state,
                displayDeleteDialog: action.payload.displayDeleteDialog,
                appRoleAssignmentDeleteId: action.payload.id,
                errorExistsDeleting: false,
                appUser: action.payload.appUser
            }
        case APP_DELETE_USER_ROLE:
            return{
                ...state,
                deletingUserRole: true,
                errorExistsDeleting: false
            }
        case APP_DELETE_USER_ROLE_SUCCESS:
            return{
                ...state,
                deletingUserRole: false,
                displayDeleteDialog: false,
                appRoleAssignmentDeleteId: '',
                errorExistsDeleting: false,
                appUsers: state.appUsers.filter(au => au.id !== action.payload.id)
            }
        case APP_DELETE_USER_ROLE_FAILURE:
            return{
                ...state,
                deletingUserRole: false,
                errorExistsDeleting: true,
                statusMessage: action.payload.Message
            }
        default: return state;
    }
}