import React, {Component} from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {withRouter} from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import {
    connect
} from 'react-redux';

class NavigationBar extends Component{
    onChangeTab = (e, value)=>{
        this.props.history.push(value);
    }
    render(){
        let navActions = [];
        if(this.props.authenticated){
            navActions = [
                {
                    label: 'Requests',
                    value: '/',
                    icon: <Icon>view_list</Icon>
                },
                {
                    label:'Create Request',
                    value: '/create-request',
                    icon: <Icon>note_add</Icon>
                },
                {
                    label: 'Settings',
                    value: '/settings',
                    icon: <Icon>settings_applications</Icon>
                }
            ];
        }else{
            navActions = [
                {
                    label: 'Login',
                    value:'/login',
                    icon: <Icon>account_circle</Icon>
                }
            ];
        }
        const navItems = navActions.map((navRoute)=>(
            <BottomNavigationAction key={navRoute.label} label={navRoute.label} value={navRoute.value} icon={navRoute.icon} />
        ));
        const theme = createMuiTheme({
            palette: {
                type: 'dark',
                primary: blue,
                secondary: red
            }
        });
        return(
            <ThemeProvider theme={theme}>
                <BottomNavigation 
                    value={this.props.location.pathname} 
                    onChange={this.onChangeTab.bind(this)} 
                    showLabels 
                    className='bottom-nav'
                >
                    {navItems}
                </BottomNavigation>
            </ThemeProvider>
        );
    }
}
const mapStateToProps = (state) => ({
    authenticated: state.account.authenticated
});
export default connect(mapStateToProps,{})(withRouter(NavigationBar));