import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
    appAddUserRole
} from '../../../Actions/Admin'

class AddButton extends Component {
    handleOnClick = (e) => {
        this.props.appAddUserRole({
            userId: this.props.graphUser.id,
            appRoleId: this.props.appRole,
            appUsers: this.props.appUsers
        })
    }
    render(){
        const emptyInputs = this.props.graphUser === '' || this.props.appRole === '';
        return(
            <Button
                variant='contained'
                color='primary'
                onClick={this.handleOnClick}
                disabled={emptyInputs || this.props.adding || this.props.usersLoading}
            >                
                Add
            </Button>
        )
    }
}

const mapStateToProps = state => ({
    graphUser: state.admin.graphUser,
    appRole: state.admin.appRole,
    adding: state.admin.adding,
    usersLoading: state.account.usersLoading,
    appUsers: state.admin.appUsers
})

const mapDispatchToProps = {
    appAddUserRole
}

export default connect(mapStateToProps, mapDispatchToProps)(AddButton);