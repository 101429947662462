import React, { Component } from 'react'
import {connect} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
class PublicRoute extends Component {
    render() {
        const{component: Component, ...props} = this.props;
        return (
            <Route 
                exact
                {...props}
                render={
                    props => (
                        this.props.authenticated ? 
                        <Redirect to ='/' /> :
                        <Component {...props} />
                    )
                }
            />
        )
    }
}
const mapStateToProps = (state)=>({
    authenticated: state.account.authenticated
});
export default connect(mapStateToProps,{})(PublicRoute);