import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import MomentUtils from'@date-io/moment';
import {connect} from 'react-redux';
import RequestStatusFilter from './RequestsFilterFields/RequestStatusFilter';
import {
    requestFilterEmployeeEmailChanged,
    requestFilterEmployeeNameChanged,
    requestFilterEndDateChanged,
    requestFilterStartDateChanged,
    requestFilterStatusChanged,
    requestFilterTypeChanged,
    filterRequestsAttempt,
    clearRequestsFilters
} from '../Actions/RequestFilters';
import {
    isUndefinedOrNullOrEmpty
} from '../Helpers/helperFunctions';
class RequestsFilters extends Component {

    componentDidUpdate(prevProps, prevState){
        const oldFilters = prevProps.filters;
        const currentFilters = this.props.filters;
        let changed = false;
        let clear = true;
        Object.entries(currentFilters).forEach(([key, value])=>{
            if(oldFilters[key] !== value && !Array.isArray(value)){
                changed = true;
            }
            else if(Array.isArray(value) && (!value.every(i => oldFilters[key].includes(i)) || value.length !== oldFilters[key].length)){
                changed = true;
            }
            if(!isUndefinedOrNullOrEmpty(value) && oldFilters[key] !== value && !Array.isArray(value)){
                clear = false;
            }else if(Array.isArray(value) && value.length !== 0){
                clear = false;
            }
        });
        if(clear && changed){
            this.onClearFilters(prevProps);
        }
        else if(changed && !clear){
            this.submitFilterRequests(prevProps);
        }
    }
    handleRequestTypeChanged = (e)=>{
        this.props.requestFilterTypeChanged(e.target.value);
    }
    handleEmployeeNameChanged = (e)=>{
        this.props.requestFilterEmployeeNameChanged(e.target.value);
    }
    handleEmployeeEmailChanged = (e)=>{
        this.props.requestFilterEmployeeEmailChanged(e.target.value);
    }
    handleStartDateChanged =(e)=>{
        this.props.requestFilterStartDateChanged(e);
    }
    handleEndDateChanged =(e)=>{
        this.props.requestFilterEndDateChanged(e);
    }
    submitFilterRequests = (e)=>{
        this.props.filterRequestsAttempt({
            filters: this.props.filters,
            users: this.props.users,
            requests: this.props.requests
        })
    }
    onClearFilters = (e)=>{
        this.props.clearRequestsFilters('');
    }
    render() {
        return (
            <Grid container direction='row' justify='center' alignContent='center' alignItems='center' spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h5' gutterBottom>
                        Filters
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} md={6} lg={8}>
                    <Paper elevation={2}>
                        <TextField disabled={this.props.fetchingRequests} fullWidth label='Request Type' variant='outlined' value={this.props.filters.requestType} onChange={this.handleRequestTypeChanged.bind(this)}/>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={10} md={6} lg={8}>
                    <RequestStatusFilter />
                </Grid>
                <Grid item xs={12} sm={10} md={6} lg={8}>
                    <Paper elevation={2}>
                        <TextField disabled={this.props.fetchingRequests} fullWidth label='Name' variant='outlined' value={this.props.filters.employeeName} onChange={this.handleEmployeeNameChanged.bind(this)} />                            
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={10} md={6} lg={8}>
                    <Paper elevation={2}>
                        <TextField disabled={this.props.fetchingRequests} fullWidth label='Email' variant='outlined' value={this.props.filters.employeeEmail} onChange={this.handleEmployeeEmailChanged.bind(this)} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={8}>
                    <Typography variant='body1'>Date Created Range:</Typography>
                    <Typography variant='body2' align='left'>
                        Search for a request submitted in a specified date range (inclusive).
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={10} md={6} lg={8}>
                    <Paper>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker 
                                disabled={this.props.fetchingRequests}
                                format='MMMM DD, YYYY' 
                                value={this.props.filters.startDate} 
                                clearable 
                                variant='dialog' 
                                fullWidth 
                                inputVariant='outlined'
                                label='Start Date' 
                                onChange={this.handleStartDateChanged.bind(this)} />
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={10} md={6} lg={8}>
                    <Paper>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={this.props.fetchingRequests}
                                format='MMMM DD, YYYY' 
                                value={this.props.filters.endDate} 
                                clearable 
                                variant='dialog' 
                                fullWidth 
                                inputVariant='outlined'
                                label='End Date' 
                                onChange={this.handleEndDateChanged.bind(this)} />
                        </MuiPickersUtilsProvider>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    {
                        this.props.displayFilteredRequests ? 
                        <Button variant='contained' color='secondary' onClick={this.onClearFilters.bind(this)}>Clear Filters</Button>
                        :
                        null
                    }
                </Grid>
            </Grid>
        )
    }
}
const mapStateToProps = (state)=>({
    filters: state.request.filters,
    users: state.account.users,
    requests: state.request.requests,
    displayFilteredRequests: state.request.displayFilteredRequests,
    usersLoading: state.request.usersLoading,
    fetchingRequests: state.request.fetchingRequests,
});
export default connect(mapStateToProps, {
    requestFilterEmployeeEmailChanged,
    requestFilterEmployeeNameChanged,
    requestFilterEndDateChanged,
    requestFilterStartDateChanged,
    requestFilterStatusChanged,
    requestFilterTypeChanged,
    filterRequestsAttempt,
    clearRequestsFilters
})(RequestsFilters);