import {
    MANAGER_INPUT_CHANGED,
    EMPLOYEE_INPUT_CHANGED,
    REQUEST_DESCRIPTION_INPUT_CHANGED,
    REQUEST_TYPE_INPUT_CHANGED,
    REQUEST_FULFILLER_INPUT_CHANGED,
    REQUEST_JUSTIFICATION_INPUT_CHANGED,
    SUBMIT_REQUEST_ATTEMPT,
    SUBMIT_REQUEST_FAILURE,
    SUBMIT_REQUEST_SUCCESS,
    FETCH_REQUESTS_ATTEMPT,
    FETCH_REQUESTS_FAILURE,
    FETCH_REQUESTS_SUCCESS,
    FETCH_REQUEST_SUCCESS,
    FETCH_REQUEST_FAILURE,
    FETCH_REQUEST_ATTEMPT,
    UPDATE_REQUEST_STATUS_ATTEMPT,
    UPDATE_REQUEST_STATUS_FAILURE,
    UPDATE_REQUEST_STATUS_SUCCESS,
    REQUEST_STATUS_INPUT_CHANGED,
    RESET_REQUEST_MESSAGE,
    EDIT_REQUEST,
    CANCEL_EDIT_REQUEST,
    CHANGE_REQUESTS_TABLE_TAB,
    UPDATE_REQUEST_ATTEMPT,
    UPDATE_REQUEST_FAILURE,
    UPDATE_REQUEST_SUCCESS,
    TOGGLE_SELF_REQUEST_CHECKBOX,
    SORT_REQUESTS,
    REQUEST_FILTER_EMPLOYEE_EMAIL_CHANGED,
    REQUEST_FILTER_EMPLOYEE_NAME_CHANGED,
    REQUEST_FILTER_END_DATE_CHANGED,
    REQUEST_FILTER_REQUEST_STATUS_CHANGED,
    REQUEST_FILTER_REQUEST_TYPE_CHANGED,
    REQUEST_FILTER_START_DATE_CHANGED,
    FILTER_REQUESTS_ATTEMPT,
    FILTER_REQUESTS_COMPLETE,
    CLEAR_REQUESTS_FILTERS,
    REQUEST_TABLE_COLUMNS_CHANGED,
    TOGGLE_CONFIRM_UPDATE_DIALOG,
    ACKNOWLEDGE_PENDING_MANAGER_REQUESTS,
    FETCH_REQUEST_MANAGER,
    FETCH_REQUEST_MANAGER_FAILURE,
    FETCH_REQUEST_MANAGER_SUCCESS
} from '../Actions/types';
import {
    TABLE_COL_REQUEST_TYPE,
    TABLE_COL_SUBMITTED,
    TABLE_COL_REQUESTOR
} from '../Helpers/Enums'
const initialState={
    requestType: '',
    date: Date.now(),
    description: '',
    manager: '',
    employee: '',
    fulfiller: '',
    justification: '',
    requestStatus: 0,
    errors: {
        description: '',
        manager: '',
        employee: '',
        fulfiller: '',
        requestType: ''
    },
    requestError: false,
    requestMessage: '',
    requestMessageAction: '',
    requestsMessage: '',
    fetchingRequests: false,
    fetchingSingleRequest: true,
    requests: [],
    request: '',
    updatingRequest: false,
    submittingRequest: false,
    editingRequest: false,
    requestTableTab: 0,
    selfRequestChecked: false,
    sortBy: '',
    sortDirection: 'desc',
    pendingManagerRequests: false,
    pendingFulfillerRequests: false,
    filters: {
        employeeEmail: '',
        employeeName: '',
        startDate: null,
        endDate: null,
        requestType: '',
        requestStatus: []
    },
    filteredRequests: [],
    displayFilteredRequests:false,
    displayRows: [ 
        TABLE_COL_REQUESTOR,
        TABLE_COL_REQUEST_TYPE,
        TABLE_COL_SUBMITTED
    ],
    displayConfirmationDialog: false,
    updatingRequestStatusData: '',
    fetchingEmployeeManager: false
}
export default (state=initialState, action)=>{
    switch(action.type){
        case MANAGER_INPUT_CHANGED:
            return{
                ...state,
                manager: action.payload
            }
        case EMPLOYEE_INPUT_CHANGED:
            return{
                ...state,
                employee: action.payload
            }
        case REQUEST_DESCRIPTION_INPUT_CHANGED:
            return{
                ...state,
                description: action.payload
            }
        case REQUEST_TYPE_INPUT_CHANGED:
            return{
                ...state,
                requestType: action.payload
            }
        case REQUEST_FULFILLER_INPUT_CHANGED:
            return{
                ...state,
                fulfiller: action.payload
            }
        case REQUEST_JUSTIFICATION_INPUT_CHANGED:
            return{
                ...state,
                justification: action.payload
            }
        case SUBMIT_REQUEST_ATTEMPT:
            return{
                ...state,
                submittingRequest: true,
                date: Date.now(),
                errors: {
                    description: '',
                    manager: '',
                    employee: '',
                    fulfiller: '',
                    requestType: '',
                    justification: ''
                },
                requestMessage: '',
                requestMessageAction: ''
            }
        case SUBMIT_REQUEST_FAILURE:
            return{
                ...state,
                date: Date.now(),
                submittingRequest: false,
                errors: {
                    description: action.payload.errors.description,
                    manager: action.payload.errors.manager,
                    fulfiller: action.payload.errors.fulfiller,
                    requestType: action.payload.errors.requestType,
                    employee: action.payload.errors.employee,
                    justification: action.payload.errors.justification
                },
                requestMessage: action.payload.message,
                requestMessageAction: ''
            }
        case SUBMIT_REQUEST_SUCCESS:
            return{
                ...state,
                submittingRequest: false,
                errors: {
                    description: '',
                    manager: '',
                    employee: '',
                    fulfiller: '',
                    requestType: ''
                },
                description: '',
                manager: '',
                employee: '',
                fulfiller: '',
                requestType: '',
                justification: '',
                date: Date.now(),
                requestMessage: action.payload.Message,
                requestMessageAction: action.payload.requestId,
                selfRequestChecked: false
            }
        case FETCH_REQUESTS_ATTEMPT:
            return{
                ...state,
                request: '',
                fetchingSingleRequest: true,
                fetchingRequests: true,
                requestsMessage: '',
                pendingManagerRequests: false,
                pendingFulfillerRequests: false
            }
        case FETCH_REQUESTS_SUCCESS:
            return {
                ...state,
                requests: action.payload.requests,
                requestsMessage: 'Requests fetched!',
                fetchingRequests: false,
                pendingManagerRequests: action.payload.pendingManagerRequests,
                pendingFulfillerRequests: action.payload.pendingFulfillerRequests
            }
        case FETCH_REQUESTS_FAILURE:
            return{
                ...state,
                requestsMessage: action.payload.message,
                fetchingRequests: false,
                pendingManagerRequests: false,
                pendingFulfillerRequests: false
            }
        case FETCH_REQUEST_ATTEMPT:
            return{
                ...state,
                fetchingSingleRequest: true,
                requestError: false
            }
        case FETCH_REQUEST_SUCCESS: 
            return{
                ...state,
                fetchingSingleRequest: false,
                request: action.payload.RequestItem,
                requestError: false
            }
        case FETCH_REQUEST_FAILURE:
            return{
                ...state,
                requestMessage: action.payload.message,
                fetchingSingleRequest: false,
                requestError: true
            }
        case REQUEST_STATUS_INPUT_CHANGED:
            return{
                ...state,
                requestStatus: action.payload
            }
        case UPDATE_REQUEST_STATUS_ATTEMPT:
            return{
                ...state,
                updatingRequest: true,
                requestError: false,
                requestMessage: action.payload.message
            }
        case UPDATE_REQUEST_STATUS_FAILURE:
            return{
                ...state,
                requestError: true,
                updatingRequest: false,
                requestMessage: action.payload.message,
            }
        case UPDATE_REQUEST_STATUS_SUCCESS:
            const newRequests = state.requests.map((requestItem, index) => {
                if(index === action.payload.requestsIndex){
                    return {
                        ...requestItem,
                        RequestStatus: action.payload.requestStatus
                    }
                }
                return {
                    ...requestItem
                }
            });
            return{
                ...state,
                requestError: false,
                updatingRequest: false,
                requests: newRequests,
                displayConfirmationDialog: false
            }
        case RESET_REQUEST_MESSAGE:
            return{
                ...state,
                requestMessage: action.payload,
                requestMessageAction: action.payload
            }
        case EDIT_REQUEST:
            return{
                ...state,
                editingRequest: true,
                description: action.payload.Description,
                manager: action.payload.DivisionManager,
                fulfiller: action.payload.Fulfiller,
                requestType: action.payload.RequestType,
                employee: action.payload.Employee,
                requestStatus: action.payload.RequestStatus,
                justification: action.payload.Justification
            }
        case CANCEL_EDIT_REQUEST:
            return{
                ...state,
                editingRequest: action.payload,
                description: '',
                manager: '',
                fulfiller: '',
                requestType: '',
                employee: '',
                requestStatus: '',
                justification: ''
            }
        case CHANGE_REQUESTS_TABLE_TAB:
            return{
                ...state,
                requestTableTab: action.payload,
                requests: []
            }
        case UPDATE_REQUEST_ATTEMPT:
            return{
                ...state,
                submittingRequest: action.payload
            }
        case UPDATE_REQUEST_SUCCESS:
            return{
                ...state,
                request: {
                    ...state.request,
                    Id: action.payload.request.id,
                    Employee: action.payload.request.employee,
                    Manager: action.payload.request.manager,
                    Fulfiller: action.payload.request.fulfiller,
                    RequestType: action.payload.request.requestType,
                    Submitted: action.payload.request.submitted,
                    CanUpdate: action.payload.request.canUpdate,
                    Description: action.payload.request.description,
                    RequestStatus: action.payload.request.requestStatus,
                    LastUpdated: action.payload.lastUpdated
                },
                requestMessage: action.payload.message,
                requestMessageAction: action.payload.request.id,
                submittingRequest: false
            }
        case UPDATE_REQUEST_FAILURE:
            return{
                ...state,
                submittingRequest: false,
                errors: {
                    description: action.payload.errors.description,
                    manager: action.payload.errors.manager,
                    fulfiller: action.payload.errors.fulfiller,
                    requestType: action.payload.errors.requestType,
                    employee: action.payload.errors.employee
                },
                requestMessage: action.payload.message,
                requestMessageAction: ''
            }
        case TOGGLE_SELF_REQUEST_CHECKBOX:
            return{
                ...state,
                selfRequestChecked: action.payload.checked,
                employee: action.payload.employee,
                manager: action.payload.manager
            }
        case SORT_REQUESTS:
            return{
                ...state,
                sortDirection: state.sortDirection === 'desc' ? 'asc' : 'desc',
                sortBy: action.payload.sortBy,
                requests: action.payload.requests
            }
        case REQUEST_FILTER_EMPLOYEE_EMAIL_CHANGED:
            return{
                ...state,
                filters: {
                    ...state.filters,
                    employeeEmail: action.payload
                }
            }
        case REQUEST_FILTER_EMPLOYEE_NAME_CHANGED:
            return{
                ...state,
                filters: {
                    ...state.filters,
                    employeeName: action.payload
                }
            }
        case REQUEST_FILTER_END_DATE_CHANGED:
            return{
                ...state,
                filters: {
                    ...state.filters,
                    endDate: action.payload
                }
            }
        case REQUEST_FILTER_START_DATE_CHANGED:
            return{
                ...state,
                filters: {
                    ...state.filters,
                    startDate: action.payload
                }
            }
        case REQUEST_FILTER_REQUEST_STATUS_CHANGED:
            return{
                ...state,
                filters: {
                    ...state.filters,
                    requestStatus: action.payload
                }
            }
        case REQUEST_FILTER_REQUEST_TYPE_CHANGED:
            return{
                ...state,
                filters: {
                    ...state.filters,
                    requestType: action.payload
                }
            }
        case FILTER_REQUESTS_ATTEMPT:
            return{
                ...state,
                fetchingRequests: true
            }
        case FILTER_REQUESTS_COMPLETE:
            return{
                ...state,
                displayFilteredRequests: true,
                fetchingRequests: false,
                filteredRequests: action.payload
            }
        case CLEAR_REQUESTS_FILTERS:
            return{
                ...state,
                displayFilteredRequests: false,
                fetchingRequests: false,
                filteredRequests: [],
                filters: {
                    employeeEmail: '',
                    employeeName: '',
                    startDate: null,
                    endDate: null,
                    requestType: '',
                    requestStatus: []   
                }
            }
        case REQUEST_TABLE_COLUMNS_CHANGED:
            return{
                ...state,
                displayRows: [...action.payload]
            }
        case TOGGLE_CONFIRM_UPDATE_DIALOG:
            return{
                ...state,
                updatingRequestStatusData: action.payload.requestData,
                displayConfirmationDialog: action.payload.displayDialog
            }
        case ACKNOWLEDGE_PENDING_MANAGER_REQUESTS:
            return{
                ...state,
                pendingManagerRequests: action.payload
            }
        case FETCH_REQUEST_MANAGER:
            return{
                ...state,
                manager: '',
                fetchingEmployeeManager: true
            }
        case FETCH_REQUEST_MANAGER_SUCCESS:
            return{
                ...state,
                manager: action.payload.Manager,
                fetchingEmployeeManager: false
            }
        case FETCH_REQUEST_MANAGER_FAILURE:
            return{
                ...state,
                fetchingEmployeeManager: false
            }
        default: return state;
    }
}