import React, { Component } from 'react'
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {
    SUBMITTED,
    IN_PROGRESS,
    DM_APPROVED,
    SM_APPROVED
} from '../../Helpers/Enums';
import {
    toggleDisplayDialog
} from '../../Actions/RequestComments';

class RejectButton extends Component {
    onClickRejectRequest = (e) => {
        this.props.toggleDisplayDialog(!this.props.displayCommentDialog)
    }
    render() {
        const permissionCheck = 
            (this.props.request.RequestStatus === SUBMITTED.value && this.props.request.SectionManager.id === this.props.account.id) ||
            (this.props.request.RequestStatus === SM_APPROVED.value && this.props.request.DivisionManager.id === this.props.account.id) ||
            (this.props.request.RequestStatus === DM_APPROVED.value && this.props.request.Fulfiller.id === this.props.account.id) ||
            (this.props.request.RequestStatus === IN_PROGRESS.value && this.props.request.Fulfiller.id === this.props.account.id);
        return (
            <React.Fragment>
                {
                    permissionCheck ?
                    <Button className='m-btn' disabled={this.props.submittingRequest} 
                        color='secondary' variant='contained'
                        onClick={this.onClickRejectRequest.bind(this)}>
                        Reject
                    </Button>
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
const mapStateToProps = state =>({
    request: state.request.request,
    account: state.account.account,
    submittingRequest: state.request.submittingRequest,
    displayCommentDialog: state.requestComments.displayCommentDialog
})
export default connect(mapStateToProps,{
    toggleDisplayDialog
})(RejectButton);