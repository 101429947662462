import React from 'react';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
const ProfileDataDisplay = (props)=>(
    <ListItem>
        <ListItemText
            primary={
                <Typography variant='subtitle2' component='p' color='textSecondary'>{props.label}</Typography>
            }
            secondary={
                <React.Fragment>
                    <Typography variant='body1' style={{fontWeight: '500'}} color='textPrimary' component='span'>{props.user.displayName}</Typography>
                    <Typography variant='body2' color='textPrimary' component='span'>&nbsp;({props.user.userPrincipalName})</Typography>
                </React.Fragment>
            }
            secondaryTypographyProps={{
                component:'div'
            }}
        />
    </ListItem>
);
export default ProfileDataDisplay;