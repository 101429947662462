import {
    MANAGER_INPUT_CHANGED,
    EMPLOYEE_INPUT_CHANGED,
    REQUEST_DESCRIPTION_INPUT_CHANGED,
    REQUEST_TYPE_INPUT_CHANGED,
    REQUEST_FULFILLER_INPUT_CHANGED,
    SUBMIT_REQUEST_ATTEMPT,
    SUBMIT_REQUEST_FAILURE,
    SUBMIT_REQUEST_SUCCESS,
    FETCH_REQUESTS_ATTEMPT,
    FETCH_REQUESTS_SUCCESS,
    FETCH_REQUESTS_FAILURE,
    FETCH_REQUEST_ATTEMPT,
    FETCH_REQUEST_FAILURE,
    FETCH_REQUEST_SUCCESS,
    UPDATE_REQUEST_STATUS_ATTEMPT,
    UPDATE_REQUEST_STATUS_FAILURE,
    UPDATE_REQUEST_STATUS_SUCCESS,
    REQUEST_STATUS_INPUT_CHANGED,
    RESET_REQUEST_MESSAGE,
    EDIT_REQUEST,
    CANCEL_EDIT_REQUEST,
    CHANGE_REQUESTS_TABLE_TAB,
    UPDATE_REQUEST_ATTEMPT,
    UPDATE_REQUEST_FAILURE,
    UPDATE_REQUEST_SUCCESS,
    TOGGLE_SELF_REQUEST_CHECKBOX,
    SORT_REQUESTS,
    REQUEST_TABLE_COLUMNS_CHANGED,
    TOGGLE_CONFIRM_UPDATE_DIALOG,
    ACKNOWLEDGE_PENDING_MANAGER_REQUESTS,
    REQUEST_JUSTIFICATION_INPUT_CHANGED,
    FETCH_REQUEST_MANAGER,
    FETCH_REQUEST_MANAGER_SUCCESS,
    FETCH_REQUEST_MANAGER_FAILURE
} from './types';
import { isUndefinedOrNullOrEmpty, isUndefinedOrNull} from '../Helpers/helperFunctions';
import {
    SUBMITTED,
    REJECTED
} from '../Helpers/Enums';
import {
    axiosClient,
    
} from '../Helpers/axiosClient';
import {
    attachmentFileChanged
} from './RequestAttachment'
export const managerInputChanged=(data)=>{
    return{
        type: MANAGER_INPUT_CHANGED,
        payload: data
    }
}
export const employeeInputChanged=(data)=>{
    return{
        type: EMPLOYEE_INPUT_CHANGED,
        payload: data
    }
}
export const requestDescriptionChanged=(data)=>{
    return{
        type: REQUEST_DESCRIPTION_INPUT_CHANGED,
        payload: data
    }
}
export const requestTypeChanged=(data)=>{
    return{
        type: REQUEST_TYPE_INPUT_CHANGED,
        payload: data
    }
}
export const fulfillerInputChanged = (data) =>{
    return{
        type: REQUEST_FULFILLER_INPUT_CHANGED,
        payload: data
    }
}
export const justificationInputChanged = (data) =>{
    return{
        type: REQUEST_JUSTIFICATION_INPUT_CHANGED,
        payload: data
    }
}
export const submitRequestAttempt = (data) =>{
    return async(dispatch)=>{
        dispatch({
            type: SUBMIT_REQUEST_ATTEMPT,
            payload: true
        });
        let requestErr = false;
        let requestErrors = {
            employee: '',
            manager: '',
            fulfiller: '',
            description: '',
            requestType: '',
            justification: ''
        };
        if(isUndefinedOrNullOrEmpty(data.employee)){
            requestErr = true;
            requestErrors.employee = 'You must select an employee for this item request.';
        }
        if(isUndefinedOrNullOrEmpty(data.manager)){
            requestErr = true;
            requestErrors.manager = 'You must select a manager for your item request.';
        }
        if(isUndefinedOrNullOrEmpty(data.fulfiller)){
            requestErr = true;
            requestErrors.fulfiller = 'You must select who will fulfill your request.';
        }
        if(isUndefinedOrNullOrEmpty(data.description)){
            requestErr = true;
            requestErrors.description = 'You must provide a description for your request.'
        }
        if(isUndefinedOrNullOrEmpty(data.requestType)){
            requestErr = true;
            requestErrors.requestType = 'You must specify the request type';
        }
        if(isUndefinedOrNullOrEmpty(data.justification)){
            requestErr = true;
            requestErrors.justification = 'You must provide a justification for this request.'
        }
        if(requestErr){
            let errData = {
                errors: requestErrors,
                message: 'There are errors present in your submission.'
            }
            dispatch({
                type: SUBMIT_REQUEST_FAILURE,
                payload: errData
            });
        }else{
            try{
                const reqData = {
                    employee: data.employee.id,
                    employeeName: data.employee.displayName,
                    requestDate: data.requestDate,
                    manager: data.manager.id,
                    managerName: data.manager.displayName,
                    fulfiller: data.fulfiller.id,
                    fulfillerName: data.fulfiller.displayName,
                    description: data.description,
                    justification: data.justification,
                    requestType: data.requestType,
                    status: SUBMITTED.value,
                    attachmentFileName: data.attachmentFileName || null
                };
                let requestReq = await axiosClient.post(`/api/Requests`, reqData);
                dispatch({
                    type: SUBMIT_REQUEST_SUCCESS,
                    payload: requestReq.data
                });
                dispatch(attachmentFileChanged(''))
            }catch(err){
                let errData = {
                    errors: requestErrors,
                    message: (!isUndefinedOrNull(err.response) ? err.response.Message : 'Service is currently unavailable.')
                };
                dispatch({
                    type: SUBMIT_REQUEST_FAILURE,
                    payload: errData
                });
            }
        }
    }
}

export const fetchRequestsAttempt = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: FETCH_REQUESTS_ATTEMPT,
            payload: true
        });
        try{
            let req = await axiosClient.get(`/api/Requests?role=${data.role}`);
            dispatch({
                type: FETCH_REQUESTS_SUCCESS,
                payload: req.data
            });
        }catch(err){
            let errData = {
                message: (!isUndefinedOrNull(err.response) ? err.response.Message : 'Service is currently unavailable.')
            }
            dispatch({
                type: FETCH_REQUESTS_FAILURE,
                payload: errData
            });
        }
    }
}
export const fetchRequestAttempt = (data) => {
    return async(dispatch) => {
        dispatch({
            type: FETCH_REQUEST_ATTEMPT,
            payload: true
        });
        try{
            let req = await axiosClient.get(`/api/Request/${data.requestID}`);
            dispatch({
                type: FETCH_REQUEST_SUCCESS,
                payload: req.data
            });
        }catch(err){
            let errData = {
                message: (!isUndefinedOrNull(err.response) ? err.response.message : 'Service is currently unavailable')
            }
            dispatch({
                type: FETCH_REQUEST_FAILURE,
                payload: errData
            });
        }
    }
}
export const requestStatusInputChanged = (data) => {
    return({
        type: REQUEST_STATUS_INPUT_CHANGED,
        payload: data
    });
}
export const updateRequestStatusAttempt = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: UPDATE_REQUEST_STATUS_ATTEMPT,
            payload: true
        });
        if(data.status < SUBMITTED.value || data.status > REJECTED.value){
            let errData = {
                message: 'Failed to update request status. Invalid option selected.'
            };
            dispatch({
                type: UPDATE_REQUEST_STATUS_FAILURE,
                payload: errData
            });
        }else{
            try{
                let reqData = {
                    requestId: data.requestId,
                    requestStatus: data.status
                }
                let req = await axiosClient.put(`/api/Request/${data.requestId}?statusOnly=true`, reqData);
                let successData = {
                    ...req.data,
                    requestStatus: data.status,
                    requestsIndex: data.requestsIndex
                }
                dispatch({
                    type: UPDATE_REQUEST_STATUS_SUCCESS,
                    payload: successData
                });
            }catch(err){
                let errData = {
                    message: (!isUndefinedOrNull(err.response) ? err.response.message : 'Service is currently unavailable.')
                }
                dispatch({
                    type: UPDATE_REQUEST_STATUS_FAILURE,
                    payload: errData
                });
            }
        }
    }
}
export const resetRequestMessageSnackbar = (data) => {
    return({
        type: RESET_REQUEST_MESSAGE,
        payload: data
    });
}
export const editRequest = (data) => {
    return({
        type: EDIT_REQUEST,
        payload: data
    })
}
export const cancelEditRequest = (data) => {
    return({
        type: CANCEL_EDIT_REQUEST,
        payload: data
    })
}
export const changeRequestTableTab = (data) => {
    return(dispatch)=>{
        dispatch({
            type: CHANGE_REQUESTS_TABLE_TAB,
            payload: data.tab
        });
        dispatch(fetchRequestsAttempt({role: data.role}));
    }
}
export const updateRequestAttempt = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: UPDATE_REQUEST_ATTEMPT,
            payload: true
        });
        let requestErr = false;
        let requestErrors = {
            employee: '',
            manager: '',
            fulfiller: '',
            description: '',
            requestType: '',
            justification: ''
        };
        if(isUndefinedOrNullOrEmpty(data.employee)){
            requestErr = true;
            requestErrors.employee = 'You must select an employee for this item request.';
        }
        if(isUndefinedOrNullOrEmpty(data.manager)){
            requestErr = true;
            requestErrors.manager = 'You must select a manager for your item request.';
        }
        if(isUndefinedOrNullOrEmpty(data.fulfiller)){
            requestErr = true;
            requestErrors.fulfiller = 'You must select who will fulfill your request.';
        }
        if(isUndefinedOrNullOrEmpty(data.description)){
            requestErr = true;
            requestErrors.description = 'You must provide a description for your request.'
        }
        if(isUndefinedOrNullOrEmpty(data.requestType)){
            requestErr = true;
            requestErrors.requestType = 'You must specify the request type';
        }
        if(isUndefinedOrNullOrEmpty(data.justification)){
            requestErr = true;
            requestErrors.justification = 'You must provide a justification for this request.'
        }
        if(requestErr){
            let errData = {
                errors: requestErrors,
                message: 'There are errors present in your submission.'
            }
            dispatch({
                type: UPDATE_REQUEST_FAILURE,
                payload: errData
            });
        }else{
            try{
                const reqData = {
                    employee: data.employee.id,
                    employeeName: data.employee.displayName,
                    manager: data.manager.id,
                    managerName: data.manager.displayName,
                    fulfiller: data.fulfiller.id,
                    fulfillerName: data.fulfiller.displayName,
                    description: data.description,
                    requestType: data.requestType,
                    justification: data.justification,
                    status: data.requestStatus
                };
                let res = await axiosClient.put(`/api/Request/${data.id}`, reqData);
                let successData = {
                    message: res.data.Message,
                    lastUpdated: res.data.LastUpdated,
                    request: data
                }
                dispatch({
                    type: UPDATE_REQUEST_SUCCESS,
                    payload: successData
                });
            }catch(err){
                let errData = {
                    errors: requestErrors,
                    message: (!isUndefinedOrNull(err.response) ? err.response.Message : 'Service is currently unavailable.')
                }
                dispatch({
                    type: UPDATE_REQUEST_FAILURE,
                    payload: errData
                });
            }
        }
    }
}
export const toggleSelfRequestCheckbox = (data) => {
    return({
        type: TOGGLE_SELF_REQUEST_CHECKBOX,
        payload: data
    });
}
export const sortRequests = (data) => {
    return({
        type: SORT_REQUESTS,
        payload: data
    });
}
export const requestTableColumnsChanged = (data) => {
    return({
        type: REQUEST_TABLE_COLUMNS_CHANGED,
        payload: data
    })
}
export const toggleConfirmUpdateDialog = (data) => {
    return({
        type: TOGGLE_CONFIRM_UPDATE_DIALOG,
        payload: data
    })
}
export const acknowledgePendingManagerRequests = (data) => {
    return({
        type: ACKNOWLEDGE_PENDING_MANAGER_REQUESTS,
        payload: data
    })
}

export const getRequestEmployeeManager = (data) => {
    return async(dispatch)=>{
        dispatch({
            type: FETCH_REQUEST_MANAGER,
            payload: true
        });
        try{
            let res = await axiosClient.get(`/api/Manager/${data.id}`);
            dispatch({
                type: FETCH_REQUEST_MANAGER_SUCCESS,
                payload: res.data
            })
        }catch(e){
            dispatch({
                type: FETCH_REQUEST_MANAGER_FAILURE,
                payload: e.response.message
            })
        }
    }
}