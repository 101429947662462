import React, { Component } from 'react'
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import {
    vendorNameChanged
} from '../../Actions/FulfillRecord'
class Vendor extends Component {
    handleVendorNameChanged = (e) => {
        this.props.vendorNameChanged(e.target.value);
    }
    render() {
        return (
            <Grid item xs={12} md={10}>
                <TextField
                    value={this.props.vendor}
                    variant='filled'
                    onChange={this.handleVendorNameChanged.bind(this)}
                    label='Vendor' 
                    fullWidth
                    disabled={this.props.account.id !== this.props.request.Fulfiller.id}
                />
            </Grid>
        )
    }
}
const mapStateToProps = state =>({
    vendor: state.fulfillRecord.vendor,
    account: state.account.account,
    request: state.request.request
})
export default connect(mapStateToProps, {
    vendorNameChanged
})(Vendor);