import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress'
import Skeleton from '@material-ui/lab/Skeleton';
import {getAdminAppRoleName, isUndefinedOrNullOrEmpty} from '../../Helpers/helperFunctions'
import {
    deleteUserRoleAssignment,
    toggleDeleteDialog
} from '../../Actions/Admin';


class UserRoleDeleteDialog extends Component{
    handleClickCancel = (e) => {
        this.props.toggleDeleteDialog({
            id: '',
            appUser: '',
            displayDeleteDialog: false
        })
    }

    handleClickDelete = (e) => {
        this.props.deleteUserRoleAssignment({id: this.props.appRoleAssignmentDeleteId})
    }

    render(){
        return(
            <Dialog open={this.props.displayDeleteDialog}>
                {
                    this.props.deletingUserRole ?
                    <LinearProgress color='primary' />
                    :
                    null
                }
                <DialogTitle>
                    Confirm Remove User
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confirm you want to remove the user below from the application.
                        <p>
                            <Typography variant='body1' color='textSecondary' component='span'>Name: </Typography>
                            <Typography variant='body1' component='span'>
                                {
                                    isUndefinedOrNullOrEmpty(this.props.appUser.principalDisplayName) ?
                                    <Skeleton />
                                    :
                                    this.props.appUser.principalDisplayName
                                }
                            </Typography>
                        </p>
                        <p>
                            <Typography variant='body1' color='textSecondary' component='span'>Role: </Typography>
                            <Typography variant='body1' component='span'>
                                {
                                    isUndefinedOrNullOrEmpty(this.props.appUser.appRoleId) ?
                                    <Skeleton />
                                    :
                                    getAdminAppRoleName(this.props.appRoles, this.props.appUser.appRoleId)
                                }
                            </Typography>
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='secondary' disabled={this.props.deletingUserRole} onClick={this.handleClickDelete}>
                        Delete
                    </Button>
                    <Button variant='text' onClick={this.handleClickCancel} disabled={this.props.deletingUserRole}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
const mapStateToProps = state => ({
    appUser: state.admin.appUser,
    appRoles: state.admin.appRoles,
    displayDeleteDialog: state.admin.displayDeleteDialog,
    deletingUserRole: state.admin.deletingUserRole,
    errorExists: state.admin.errorExistsDeleting,
    appRoleAssignmentDeleteId: state.admin.appRoleAssignmentDeleteId
})

const mapDispatchToProps = {
    deleteUserRoleAssignment,
    toggleDeleteDialog
}
export default connect(mapStateToProps, mapDispatchToProps)(UserRoleDeleteDialog)